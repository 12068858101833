import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { DataService } from 'src/app/services/data.service';
import { PetitionService } from 'src/app/services/petition.service';

declare var $: any;

@Component({
  selector: 'app-admin-area-comun',
  templateUrl: './admin-area-comun.component.html'
})
export class AdminAreaComunComponent implements OnInit {
  /**
   * VARIABLES
   */
  public areas_list: any[] = [];
  /**
   * DATA DELETE
   */
  public area_name: string = '';
  public area_id: number = 0;
  /**
   * FORM DATA
   */
  public formDataDelete: any;

  constructor(private formBuilder: FormBuilder, 
    private petition_service:PetitionService,
    private alert_service: AlertService,
    private data_service: DataService) {
    this.get_areas();
   }

  ngOnInit(): void {
    this.formDataDelete = this.formBuilder.group({
      nombreConfirm: ['', Validators.required]
    })
  }
  /**
   * GET DATA
   */
  private get_areas(){
    this.areas_list = [];
    this.petition_service.get_all('condominio_area_comun').subscribe(
      (data: any[]) => {
        this.petition_service.get_all('condominio').subscribe(
          (condominios: any[]) => {

            data.some(area => {
          
              condominios.some(condo => {
                if (area.condominio_id == condo.id) {
                  
                  let info = {
                    "id": area.id,
                    "titulo": area.titulo,
                    "descripcion": area.descripcion,
                    "img": area.img,
                    "condominio_id": area.condominio_id,
                    "estado": area.estado,
                    "usuario_id": area.usuario_id,
                    "condominio": condo.nombre,
                    "direccion": condo.direccion,
                  }
                  this.areas_list.push(info);
                }
              })
            }) 
          })
      }
    )
    setTimeout(() => {
      $('#table-area-comun').DataTable();
    }, 2000);
  }
  
  data_to_delete(id: number, titulo: string){
    this.area_id = id;
    this.area_name = titulo;
    this.formDataDelete.reset();
  }
  delete(){
    this.petition_service.delete('condominio_area_comun', this.area_id).subscribe(
      (data) => {
        this.alert_service.show_swall('Área Eliminada', 'El área común se eliminó correctamente', 'success')
        $('#modal-eliminar').modal('hide');
        this.get_areas()
      },
      (err) => {
        this.alert_service.show_error()
      } 
    )
  }
  updateEstado(estado, id){
    let update = {
      "estado": estado
    }
    this.data_service.AreasEstadoUpdate(update, id)
    .subscribe((res)=>{
      this.alert_service.show_swall('Estado actualizado', 'se ha actualizado el estado exitosamente', "success")
      console.log(res)
    }, (error) => {
      this.alert_service.show_swall('Un error ha ocurrido', 'Refresque la pagina y vuelva a intentar', "error")
      console.log(error)
    })
  }
}
