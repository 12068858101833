import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageComponent } from './page/page.component';
import { AuthGuardService } from './services/auth.guard.service';
import { LoginComponent } from './shared/login/login.component';
import { NopagefoundComponent } from './shared/nopagefound/nopagefound.component';
import { SigninVisitaComponent } from './shared/signin-visita/signin.component';
import { SigninComponent } from './shared/signin/signin.component';

export const routes: Routes = [
  {
		path: '',
		component: PageComponent,
    canActivate: [ AuthGuardService ],
		children: [
      { path: '', redirectTo: '/login', pathMatch: 'full' },
      {
				path: 'admin',
        canActivate: [ AuthGuardService ],
				loadChildren: () => import('./page/admin/admin.module').then(m => m.AdminModule)
			},
      {
				path: 'ventas',
        canActivate: [ AuthGuardService ],
				loadChildren: () => import('./page/ventas/ventas.module').then(m => m.VentasModule)
			},
      {
				path: 'usuario',
				loadChildren: () => import('./page/user/user.module').then(m => m.UserModule)
			},
      {
				path: 'seguridad',
        canActivate: [ AuthGuardService ],
				loadChildren: () => import('./page/seguridad/seguridad.module').then(m => m.SeguridadModule)
			},
      
    ]
  },
  //INICIO DE SESION
  { path: 'login', component: LoginComponent},
  { path: 'signin', component: SigninComponent},
  { path: 'signin_Visitante', component: SigninVisitaComponent},
  //NO SE ENCUENTRA LA PAGINA
  /*
  { path: '', component: PageComponent, canActivate: [ AuthGuardService ], loadChildren: './page/page.module#PageModule' },
  */
  { path: '**', component: NopagefoundComponent},
  

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  

  
 }
