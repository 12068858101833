import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/services/alert.service';
import { DataService } from 'src/app/services/data.service';
import { PetitionService } from 'src/app/services/petition.service';
import { UploadFileService } from 'src/app/services/upload-file.service';

declare var $: any;
declare var google:any;

@Component({
  selector: 'app-user-condominio',
  templateUrl: './tips.component.html'
})
export class AdminTipsComponent implements OnInit {
  
  public user_logged: any;
  public tips_data: any;
  public api_error: any;
  public closeResult: string;
  file: any;
  strImage: any;
  Form: FormGroup;
  constructor(
    private data_service: DataService,
    private petition_service: PetitionService,
    private alert_service: AlertService,
    private modalService: NgbModal,
    private upload_service: UploadFileService,
    private fb: FormBuilder) {
      this.user_logged = JSON.parse(localStorage.getItem('currentUser'));
      this.GetTips();
      this.Form = this.fb.group({
        Titulo: [ '', Validators.compose([ Validators.required ])  ],
        Descripcion: [ '', Validators.compose([ Validators.required ])  ],
      })
      
   }
   openModal(content1) {
    this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  seleccionImage(archivo: any) {

    if (!(archivo.target.files[0])) {
      return;
    }
    this.file = archivo.target.files[0]
    let reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onloadend = () => this.strImage = reader.result;
    this.uploadImage();
  }
  Imagen: any;
  uploadImage() {
    this.upload_service.post(this.file, 'test/categoria')
      .then((res: any) => {
        this.Imagen = res.url;
        this.alert_service.show_swall('Imagen subida', 'La imagen se subió con éxito', "success")
      })
      .catch((resp) => {
        this.alert_service.show_error();
      });
  }
  nuevoTip(){
    let titulo = this.Form.controls["Titulo"].value;
    let descripcion = this.Form.controls["Descripcion"].value;
    let data = {
      "titulo": titulo,
      "descripcion": descripcion,
      "img": this.Imagen,
      "estado": 1,
      "usuario": this.user_logged.id,
      "categoria_id": 1
    }
    this.data_service.AddTipss(data)
    .subscribe( (res) => {
      this.alert_service.show_swall('tip generado', 'Ha generado un tip con exito', "success")
      console.log(res)
    }, (error) => {
      this.alert_service.show_swall('Error', 'Por favor intentelo más tarde', "error")
      this.api_error = error
      console.log(this.api_error)
    })
  }
    private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
   GetTips(){
     this.data_service.TipsGetAll()
     .subscribe((res) => {
       console.log(res)
      this.tips_data = res;
     }, (error) => {
       this.api_error = error;
       console.log(this.api_error)
     })
   }
   update_proyecto(estado: number, id_reserva: number){
    this.petition_service.put('condominio_tips', {"estado": estado}, id_reserva).subscribe(
      (data: any) => {
        this.alert_service.show_swall('Tips actualizadaos','Se ha cambiado el estado del tip','success');
        this.GetTips();
      },
      (err: any) => {
        this.alert_service.show_error();  
      }
    )
  }
  
    ngOnInit(){

    }
}