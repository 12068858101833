
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
//import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PAGES_ROUTES } from './page.routes';
import { AdminModule } from './admin/admin.module';
import { SideBarComponent } from '../shared/side-bar/side-bar.component';
import { NavBarComponent } from '../shared/nav-bar/nav-bar.component';
import { AdminComponent } from './admin/admin.component';
import { UserComponent } from './user/user.component';
import { UserModule } from './user/user.module';
import { VentasComponent } from './ventas/ventas.component';
import { VentasModule } from './ventas/ventas.module';
import { SeguridadComponent } from './seguridad/seguridad.component';
import { SeguridadModule } from './seguridad/seguridad.module';
import { NavBar2Component } from './nav-bar/nav-bar.component';
import { SideBar2Component } from './side-bar/side-bar.component';

@NgModule({
    declarations: [
      AdminComponent,
      UserComponent,
      VentasComponent,
      SeguridadComponent,
      NavBar2Component,
      SideBar2Component
    ],
    exports: [
    ],
    imports: [
      CommonModule,
      RouterModule,
      AdminModule,
      UserModule,
      VentasModule,
      SeguridadModule,
      PAGES_ROUTES,
    ]
  })
  export class PageModule { }
  