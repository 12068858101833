import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from './services/alert.service';
import { DataService } from './services/data.service';
import { PetitionService } from './services/petition.service';
import { UploadFileService } from './services/upload-file.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'front-condominios';
  
  form: any;
  public user: any;
  public noticia_list: any[] = []
  public closeResult: string;
  public idCondo: any;
  public idCasa: any;
  file: any;
  strImage: any;
  Form: FormGroup;
  Val: boolean;
  constructor(private petition_service: PetitionService,
    private alert_service: AlertService,
    private data_service: DataService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private upload_service: UploadFileService,
    private datePipe: DatePipe
    ){
      
      
    }
  reportarEmergencia(){
    var date = new Date();
    let datenow = this.datePipe.transform(date,"yyyy-MM-dd hh:mm:ss")
    let data = {
      "condominio_vivienda_id": this.idCasa,
      "fechahora": datenow
    }
    console.log(data)
    this.data_service.NewEmergencia(data)
    .subscribe((res)=>{
      this.alert_service.show_swall('Emergencia reportada', 'La emergencia a llegado a seguridad', "success")
      console.log(res)
    }, (error) => {
      this.alert_service.show_swall('Un error ha ocurrido', 'Refresque la pagina y vuelva a intentar', "error")
      console.log(error)
    })
  }
  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('currentUser'))
    if(this.user == 0){
      this.Val = false
    }else{
      this.Val = true
    }
  }
}
