import { RouterModule, Routes } from "@angular/router";
import { VentasHomeComponent } from "./home/home.component";
import { FormularioMembresiaComponent } from "./ventas-membresias/formulario-membresias/nueva-membresia.component";
import { VentasMembresiasComponent } from "./ventas-membresias/membresias.component";

const pagesRoutes: Routes = [
    
    { path: 'home', component: VentasHomeComponent },
    { path: 'nueva_membresia', component: FormularioMembresiaComponent},
    { path: 'membresias', component: VentasMembresiasComponent },
    { path: '', redirectTo: 'login', pathMatch: 'full' }


];


export const VENTAS_ROUTES = RouterModule.forChild(pagesRoutes);
