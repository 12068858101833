<div>
    <div class="fix-header card-no-border fix-sidebar">
    
        <div id="main-wrapper">
            <app-nav-bar></app-nav-bar>
            <br>
            <div class="page-wrapper2">
                <!-- ============================================================== -->
                <!-- Container fluid  -->
                <!-- ============================================================== -->
                
                <div class="container-fluid">
                    <div *ngIf="user == 3">
                        <button style="margin-top: 8%; margin-left: 90%; margin-right: 0%;" (click)="reportarEmergencia()">Emergencia</button>
                    </div>
                    <div class="container" style="margin-top: 2%;">
                        <router-outlet></router-outlet>
                    </div>
                    
                </div>
               
                <!-- ============================================================== -->
                <!-- footer -->
                <!-- ============================================================== -->
                <footer class="footer"> © 2020 FoxyLabs</footer>
            </div>
            <!-- ============================================================== -->
            <!-- End Page wrapper  -->
            <!-- ============================================================== -->
        </div>
    
    </div>
</div>
