import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/services/alert.service';
import { DataService } from 'src/app/services/data.service';
import { PetitionService } from 'src/app/services/petition.service';
import { UploadFileService } from 'src/app/services/upload-file.service';
import {DatePipe} from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-user-proyectos',
  templateUrl: './proyectos.component.html',
})
export class UserProyectosComponent implements OnInit {
  //VARIABLES
  form: any;
  public noticia_list: any[] = []
  public closeResult: string;
  public idCondo: any;
  file: any;
  strImage: any;
  Form: FormGroup;
  Proyectos: any;
  constructor(private petition_service: PetitionService,
    private alert_service: AlertService,
    private data_service: DataService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private upload_service: UploadFileService,
    private datePipe: DatePipe
    ){
      this.idCondo = (JSON.parse(localStorage.getItem('micondo'))).id;
      this.GetProyectos(this.idCondo);
      this.Form = this.fb.group({
        Titulo: [ '', Validators.compose([ Validators.required ])  ],
        Descripcion: [ '', Validators.compose([ Validators.required ])  ],
        img: [ '', Validators.compose([ Validators.required ])  ],
      })
    }
  GetProyectos(id){
    this.data_service.ProyectosCondominiosGet(id)
    .subscribe((res)=>{
      this.Proyectos = res;
      console.log(res)
    }, (error) => {
      console.log(error)
    })
  }
  AddPropuestaProyecto(){
    let titulo = this.Form.controls["Titulo"].value;
    let descripcion = this.Form.controls["Descripcion"].value;
    let data = {
      "titulo": titulo,
      "descripcion": descripcion,
      "img": this.Imagen,
      "usuario_id": (JSON.parse(localStorage.getItem('micondo'))).usuario_id,
      "condominio_id": this.idCondo,
      "estado": "2"
    }
    this.data_service.ProyectoAdd(data)
    .subscribe((res)=>{
      console.log(res)
    }, (error) => {
      console.log(error)
    })
  }
  openModal(content1) {
    this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
    private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
  seleccionImage(archivo: any) {
    if (!(archivo.target.files[0])) {
      return;
    }
    this.file = archivo.target.files[0]
    let reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onloadend = () => this.strImage = reader.result;
    this.uploadImage();
  }
  Imagen: any;
  uploadImage() {
    this.upload_service.post(this.file, 'test/categoria')
      .then((res: any) => {
        this.Imagen = res.url;
        this.alert_service.show_swall('Imagen subida', 'La imagen se subió con éxito', "success")
      })
      .catch((resp) => {
        this.alert_service.show_error();
      });
  }
  ngOnInit(){

  }  
}