import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/services/alert.service';
import { DataService } from 'src/app/services/data.service';
import { PetitionService } from 'src/app/services/petition.service';
import { UploadFileService } from 'src/app/services/upload-file.service';

@Component({
  selector: 'app-acprecio',
  templateUrl: './acprecio.component.html'
})
export class PrecioAreaComponent implements OnInit {
    form: any;
    public precios_list: any[] = []
    public areas_list: any[] = []
    public closeResult: string;
    public idCondo: any;
    public idCasa: any;
    public usuario_id: any;
    file: any;
    strImage: any;
    Form: FormGroup;
    constructor(private petition_service: PetitionService,
      private alert_service: AlertService,
      private data_service: DataService,
      private formBuilder: FormBuilder,
      private modalService: NgbModal,
      private fb: FormBuilder,
      private upload_service: UploadFileService,
      private datePipe: DatePipe
      ){
        this.Form = this.fb.group({
          nombre: [ '', Validators.compose([ Validators.required ])  ],
          precio: [ '', Validators.compose([ Validators.required ])  ],
          tiempo_mins: [ '', Validators.compose([ Validators.required ])  ],
        })
        this.GetPrecios();
        this.GetAreaComunes();
      }
      GetPrecios(){
        this.precios_list = [];
        this.data_service.getPreciosAreas()
        .subscribe((res) => {
          console.log(res)
         this.precios_list = res;
        }, (error) => {
          console.log(error)
        })
      }
      GetAreaComunes(){
        this.areas_list = [];
        this.data_service.getAreasComunes()
        .subscribe((res) => {
          console.log(res)
         this.areas_list = res;
        }, (error) => {
          console.log(error)
        })
      }
      openModal(content1) {
        this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
        private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
        } else {
          return  `with: ${reason}`;
        }
      }
      nuevoPrecio(){
        let tiempo_mins, precio, nombre, formulario;
        precio = this.Form.controls["precio"].value;
        tiempo_mins = this.Form.controls["tiempo_mins"].value;
        nombre = this.Form.controls["nombre"].value;
        formulario = {
          "precio": precio,
          "tiempo_mins": tiempo_mins,
          "nombre": nombre
        }
        this.data_service.addPrecioArea(formulario)
        .subscribe( (res) => {
          console.log(res)
        }, (error) => {
          console.log(error)
        })
      }

      PrecioID: any;
      AreaID: any;
      SavePrecioID(id){
        this.PrecioID = id;
      }
      SaveAreaID(id){
        this.AreaID = id;
      }
      AreaPrecio(){
        let formulario = {
          "precio_id": this.PrecioID,
          "area_comun_id": this.AreaID,
        }
        this.data_service.PrecioAreaNuevo(formulario)
        .subscribe( (res) => {
          console.log(res)
        }, (error) => {
          console.log(error)
        })
      }
      updateEstado(estado, id){
        let update = {
          "estado": estado
        }
        this.data_service.AreasEstadoUpdate(update, id)
        .subscribe((res)=>{
          this.alert_service.show_swall('Estado actualizado', 'se ha actualizado el estado exitosamente', "success")
          console.log(res)
        }, (error) => {
          this.alert_service.show_swall('Un error ha ocurrido', 'Refresque la pagina y vuelva a intentar', "error")
          console.log(error)
        })
      }
    ngOnInit(): void {

    }
}