<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="d-flex">
                    <div class="align-self-center">
                        <h4 class="card-title m-t-10">Visitas Actuales</h4>
                    </div>
                </div>
                <br>
                <div class="table-responsive m-t-40">
                    <table id="table-area-comun" class="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>Persona que vista</th>
                                <th>Placa</th>
                                <th>Hora</th>
                                <th>Fecha</th>
                                <th>Estado</th>
                                <th>Opciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let c of Visitas">
                                <td>
                                   {{ c.usuario.nombre }} {{ c.usuario.apellido }}
                                </td>
                                <td>{{ c.placa }}</td>
                                <td>{{ c.hora }}</td>
                                
                                <td>{{ c.fecha }}</td>
                                <td class="text-center">
                                    <span *ngIf="c.estado == 2" class="label label-danger">Se ha retirado</span>
                                    <span *ngIf="c.estado == 0" class="label label-warning">Pendiente</span>
                                    <span *ngIf="c.estado == 1" class="label label-primary">Se ha reportado en garita</span></td>
                                <td> 
                                    <div class="btn-group">
                                        <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="ti-settings"></i>
                                        </button>
                                        <div class="dropdown-menu"  >
                                            <button class="dropdown-item" *ngIf="c.estado == 0" (click)="update_proyecto(1, c.id)" >Llego</button>
                                            <button class="dropdown-item" *ngIf="c.estado == 1" (click)="update_proyecto(2, c.id)" >Ya se ha retirado</button>

                                        </div>
                                    </div>
                                </td>
                            </tr>
                           
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>