import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { DataService } from 'src/app/services/data.service';
import { PetitionService } from 'src/app/services/petition.service';

declare var $: any;

@Component({
  selector: 'app-admin-objetosperdidos',
  templateUrl: './objetosperdidos.component.html'
})
export class AdminObjetosPerdidosComponent implements OnInit {
  /**
   * VARIABLES
   */
  public objeto_id: number = 0;
  /**
   * VARIABLES PARA ELIMINAR
   */
  public formDataDelete: any;
  public noticia_titulo: string = '';
  public noticias_list: any[] = []

  constructor(
    private petition_service: PetitionService,
    private formBuilder: FormBuilder,
    private alert_service: AlertService,
    private data_service: DataService
    ) { 
      this.get_noticias();
  }

  ngOnInit(): void {
    this.formDataDelete = this.formBuilder.group({
      nombreConfirm: ['', Validators.required]
    });
  }
  UpdateEstado(id, estado){
    let data = {
      "estado": estado
    }
    this.data_service.ObjetosPerdidosUpdate(data, id)
    .subscribe((res) => {
      console.log(res)
      this.alert_service.show_swall('Cambio de estado', 'El estado se ha cambiado exitosamente', "success")
    }, (error) => {
      this.alert_service.show_swall('Error', 'Por favor intentelo más tarde', "error")
      console.log(error)
    })
  }
  get_noticias(){
    this.noticias_list = []

    //SE TRAEN LAS NOTICIAS
    this.petition_service.get_all('condominio_objetos_perdidos').subscribe(
      (noticias: any[]) => {

        //SE RECCORRE CADA NOTICIA
        for (let i = 0; i < noticias.length; i++) {
          const noticia = noticias[i];
          

          //SE TRAE EL CONDOMINIO ASOCIADO DE CADA NOTICIA
          this.petition_service.get_by_id('condominio', noticia.condominio_id).subscribe(
            (condominio: any) => {
              //SI NO HAY ERROR EN NINGUNO, SE GENERA LA DATA A MOSTRAR
                      // ASI SE CONTROLA QUE NO MUESTRE INFORMACION DE DATOS BORRADOS
                      let data = {
                        id: noticia.id,
                        titulo: noticia.titulo,
                        descripcion: noticia.descripcion,
                        img: noticia.img,
                        estado: noticia.estado,
                        condominio: condominio.nombre,
                        whatsapp: noticia.whatsapp,
                        recompensa: noticia.recompensa,
                      }
                      this.noticias_list.push(data);
              
            },
            (err) => {
            }
          )
        }

        setTimeout(() => {
          $('#tabla-noticias').DataTable();
        }, 3000);
       
      },
      (err) =>{
        this.alert_service.show_no_info();
      }
    )
  }
  data_to_delete(id: number, titulo: string){
    this.objeto_id = id;
    this.noticia_titulo = titulo;
    this.formDataDelete.reset();
  }
  delete(){
    this.petition_service.delete('condominio_noticias', this.objeto_id).subscribe(
      (data: any) => {
        this.get_noticias();
        this.alert_service.show_swall("Noticia Eliminada", "Se ha eliminado la noticia correctamente", "success")
        $('#modal-eliminar').modal('hide');
      }, (err) => {
        this.alert_service.show_error();
      }
    ) 
  }

}
