import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/services/alert.service';
import { DataService } from 'src/app/services/data.service';
import { PetitionService } from 'src/app/services/petition.service';
import { UploadFileService } from 'src/app/services/upload-file.service';
import {DatePipe} from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-user-emergencias',
  templateUrl: './emergencias.component.html',
})
export class UserEmergenciasComponent implements OnInit {
  //VARIABLES
  form: any;
  public noticia_list: any[] = []
  public closeResult: string;
  public idCondo: any;
  public idCasa: any;
  file: any;
  strImage: any;
  Form: FormGroup;
  constructor(private petition_service: PetitionService,
    private alert_service: AlertService,
    private data_service: DataService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private upload_service: UploadFileService,
    private datePipe: DatePipe
    ){
      this.idCasa = (JSON.parse(localStorage.getItem('mivivienda'))).id;
      this.idCondo = (JSON.parse(localStorage.getItem('micondo'))).id
    }
    reportarEmergencia(){
      let data = {
        "condomino_viviendas_id": this.idCondo,
        "usuario_id": (JSON.parse(localStorage.getItem('micondo'))).usuario_id,
        "vivendas_usuario_id": this.idCasa,
      }
      this.data_service.NewEmergencia(data)
      .subscribe((res)=>{
        console.log(res)
      }, (error) => {
        console.log(error)
      })
    }
  ngOnInit(){

  }  
}