<div class="row" *ngIf="idCondo != 0">
    <button (click)="openModal(content1)">Crear noticia</button>
</div>
<div class="row">
    <div class="col-12 m-t-30" >
        <h4 class="m-b-0">Noticias del condominio</h4>
        <br>
        <div class="card-columns" >
            <div class="card" *ngFor="let c of noticia_list">
                <div *ngIf="c.estado == 2">
                    <img class="card-img-top img-fluid" src="{{ c.img }}" alt="Card image cap">
                    <div class="card-body">
                        <h4 class="card-title">{{ c.titulo }}</h4>
                        <p class="card-text"> {{c.descripcion }}</p>
                        <footer>
                            <small class="text-muted">
                            <cite title="Source Title">Fecha {{ c.fechahora | date: 'fullDate' }}</cite>
                            </small>
                        </footer>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<ng-template #content1 let-modal>
    <div class="modal-header">
        Generar noticia
    </div>
    <div class="modal-body">
        <form [formGroup]="Form">
            <div class="form-group">
                <label>Titulo</label>
                <div class="input-group">
                    <input formControlName="Titulo" type="text" class="form-control">
                </div>
                <label>Descripcion</label>
                <div class="input-group">
                    <input formControlName="Descripcion" type="text" class="form-control">
                </div>
                <label>Imagen noticia</label>
                <div class="form-group text-center">
                    <div class="fileupload btn btn-danger btn-rounded waves-effect waves-light"><span><i
                                class="fa fa-camera"></i> Seleccionar Imagen</span>
                        <input type="file" accept="image/*" (change)="seleccionImage($event)" class="upload">
                    </div>
                </div>
                </div>
        </form>
    </div>
    <div class="modal-footer">
        <button (click)="GenerarNoticia()">Generar noticia</button>
    </div>
</ng-template>