<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="d-flex">
                    <div class="align-self-center">
                        <h4 class="card-title m-t-10">Tips Actuales</h4></div>
                    <div class="ml-auto">
                        <div class="input-group">
                            <button (click)="openModal(content1)" class="btn btn-sm btn-warning btn-create">
                                Nuevo Tip
                            </button>
                        </div>
                    </div>
                </div>
                <br>
                <div class="table-responsive m-t-40">
                    <table id="tabla-condominios" class="table table-bordered table-striped">
                        <thead>
                            <tr>

                                <th>Nombre</th>
                                <th>Descripcion</th>
                                <th>Estado</th>
                                <th>Opciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let c of tips_data">
                                <td>
                                    <img src="{{ c.img }}" alt="user" height="50" width="50" class="img-circle"> {{ c.titulo }}
                                </td>
                                <td>{{ c.descripcion }}</td>
                                <td class="text-center">
                                    <span *ngIf="c.estado == 2" class="label label-danger">Rechazado</span>
                                    <span *ngIf="c.estado == 0" class="label label-warning">Pendiente</span>
                                    <span *ngIf="c.estado == 1" class="label label-primary">Aprobado</span>

                                </td>
                                <td> 
                                    <div class="btn-group">
                                        <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="ti-settings"></i>
                                        </button>
                                        <div class="dropdown-menu"  >
                                            <button class="dropdown-item" *ngIf="c.estado == 2" (click)="update_proyecto(1, c.id)" >Aceptar</button>
                                            <button class="dropdown-item" *ngIf="c.estado == 1" (click)="update_proyecto(2, c.id)" >Rechazar</button>
                                            <button class="dropdown-item" *ngIf="c.estado == 0" (click)="update_proyecto(1, c.id)" >Aceptar</button>
                                            <button class="dropdown-item" *ngIf="c.estado == 0" (click)="update_proyecto(2, c.id)" >Rechazar</button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                           
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        
    </div>
</div>
<ng-template #content1 let-modal>
    <div class="modal-header">
        Generar noticia
    </div>
    <div class="modal-body">
        <form [formGroup]="Form">
            <div class="form-group">
                <label>Titulo</label>
                <div class="input-group">
                    <input formControlName="Titulo" type="text" class="form-control">
                </div>
                <label>Descripcion</label>
                <div class="input-group">
                    <input formControlName="Descripcion" type="text" class="form-control">
                </div>
                <label>Imagen noticia</label>
                <div class="form-group text-center">
                    <div class="fileupload btn btn-danger btn-rounded waves-effect waves-light"><span><i
                                class="fa fa-camera"></i> Seleccionar Imagen</span>
                        <input type="file" accept="image/*" (change)="seleccionImage($event)" class="upload">
                    </div>
                </div>
                </div>
        </form>
    </div>
    <div class="modal-footer">
        <button (click)="nuevoTip()">Generar tip</button>
    </div>
</ng-template>