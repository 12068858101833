import { RouterModule, Routes } from "@angular/router";
import { SeguridadHomeComponent } from "./home/home.component";
import { SeguridadEmergenciasComponent } from "./seguridad-emergencias/emergencias.component";
import { SeguridadVisitasComponent } from "./seguridad-visitas/visitas.component";



const pagesRoutes: Routes = [
    { path: 'home', component: SeguridadHomeComponent },
    { path: 'emergencias', component: SeguridadEmergenciasComponent },
    { path: 'visitas', component: SeguridadVisitasComponent },
    { path: '', redirectTo: 'login', pathMatch: 'full' }


];


export const SEGURIDAD_ROUTES = RouterModule.forChild(pagesRoutes);