import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { PetitionService } from 'src/app/services/petition.service';

declare var $: any;
declare var google:any;

@Component({
  selector: 'app-user-condominio',
  templateUrl: './perfil.component.html'
})
export class PerfilComponent implements OnInit {
    
    ngOnInit(){

    }
}