<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body" *ngIf="formData">
                <h4 class="card-title">Formulario de Área común</h4>
                <form [formGroup]="formData" class="form-material m-t-40">

                    <div class="row">
                        <div class="col-lg-3">

                        </div>
                        <div class="col-lg-6">
                            <div class="row el-element-overlay" style="margin-bottom: 0px !important;">
                                <div class="col-md-3">
                                </div>
                                <div class="col-md-6">
                                    <div class="card" style="margin-bottom: 0px !important;">
                                        <div class="el-card-item" style="margin-bottom: 0px !important;">
                                            <div class="el-card-avatar el-overlay-1"
                                                style="margin-bottom: 0px !important;">
                                                <img src="{{strImage}}" alt="area" />
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                </div>
                            </div>
                            <div class="form-group text-center">
                                <label>Foto del área (opcional)</label>
                                <br>
                                <div class="fileupload btn btn-danger btn-rounded waves-effect waves-light"><span><i
                                            class="fa fa-camera"></i> Seleccionar Imagen</span>
                                    <input type="file" accept="image/*" (change)="seleccionImage($event)"
                                        class="upload">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">

                        </div>
                    </div>

                    <div class="form-group">
                        <label>Título </label>
                        <input type="text" formControlName="titulo"  
                                maxlength="25"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                class="form-control form-control-line"> 
                                <div *ngIf="f.titulo.invalid && (f.titulo.dirty || f.titulo.touched)">
                                    <div *ngIf="f.titulo.errors.required" style="color:red">Dato requerido</div>
                                </div>
                    </div>
                    <div class="form-group">
                        <label>Descripción</label>
                        <textarea formControlName="descripcion" 
                        maxlength="150"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        class="form-control" rows="5"></textarea>
                        <div *ngIf="f.descripcion.invalid && (f.descripcion.dirty || f.descripcion.touched)">
                            <div *ngIf="f.descripcion.errors.required" style="color:red">Dato requerido</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-6">
                                <label >Condominio</label>
                                <select class="form-control" formControlName="condominio_id">
                                    <option *ngFor="let item of condominio_list" value="{{item.id}}">{{ item.nombre }} - {{ item.direccion}}</option>
                                </select>
                            </div>
                            <div class="col-6">
                                <label>Estado</label>
                                <select formControlName="estado" class="form-control">
                                    <option value="1">Activada</option>
                                    <option value="0">Desactivada</option>
                                </select>        
                            </div>
                        </div>
                    </div>
                    <div class="form-group text-center">
                        <div class="btn btn-group">
                            <a [routerLink]="['/admin/areas-comunes']" routerLinkActive="router-link-active"  class="btn btn-secondary waves-effect waves-light"
                                type="button"><span class="btn-label"><i class="ti-close"></i></span>Cancelar</a>
                                <button [disabled]="!file" (click)="uploadImage()"
                                class="btn btn-danger waves-effect waves-light" type="button"><span
                                    class="btn-label"><i class="ti-save-alt"></i></span>Guardar Foto</button>

                                <button  (click)="save()"
                                class="btn btn-primary waves-effect waves-light" [disabled]="formData.invalid || subiendo_imagen" type="button"><span
                                    class="btn-label"><i class="ti-check"></i></span>Guardar Área</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>