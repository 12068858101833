<section id="wrapper">
    <div class="login-register" style="background-image:url(assets/images/background/login-wallpaper.jpg);">
        <div class="login-box card">
            <div class="card-body" *ngIf="loginForm">
                <form [formGroup]="loginForm" class="form-horizontal form-material">
                    <h3 class="box-title m-b-20">Sign In</h3>
                    <div class="form-group ">
                        <div class="col-xs-12">
                            <input class="form-control" formControlName="email" type="text" required="" placeholder="Correo"> </div>
                            <div *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)">
                                <div *ngIf="f.email.errors.required" style="color:red">El correo es requerido</div> 
                            </div>
                    </div>
                    <div class="form-group">
                        <div class="col-xs-12">
                            <input class="form-control" formControlName="password" type="password" required="" placeholder="Contraseña"> </div>
                            <div *ngIf="f.password.invalid && (f.password.dirty || f.password.touched)">
                            <div *ngIf="f.password.errors.required" style="color:red">La contraseña es requerida</div> 
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-12">
                            <a href="javascript:void(0)" id="to-recover" class="text-dark pull-right"><i class="fa fa-lock m-r-5"></i> Recuperar contraseña</a> </div>
                    </div>
                    <div class="form-group text-center">
                        <div class="col-xs-12 p-b-20">
                            <button class="btn btn-block btn-lg btn-info btn-rounded" [disabled]="loginForm.invalid" (click)="login_with_email()"  >Log In</button>
                        </div>
                    </div>
                    <div class="form-group m-b-0">
                        <div class="col-sm-12 text-center">
                            ¿No tienes una cuenta? <a [routerLink]="['/signin']" routerLinkActive="router-link-active"  class="text-info m-l-5"><b>Regístrate</b></a><br>
                            <a [routerLink]="['/signin_Visitante']" routerLinkActive="router-link-active"  class="text-info m-l-5"><b>Regístrate como visitante</b></a>

                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>