<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="d-flex">
                    <div class="align-self-center">
                        <h4 class="card-title m-t-10">Directivas Actuales</h4></div>
                    <div class="ml-auto">
                        <div class="input-group">
                            <a type="button" [routerLink]="['/admin/condominios/nuevo']" routerLinkActive="router-link-active"  class="btn btn-sm btn-warning btn-create">Nueva Directiva</a>
                        </div>
                    </div>
                </div>
                <br>
                <div class="table-responsive m-t-40">
                    <table id="tabla-directiva" class="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>Condominio</th>
                                <th>Dirección</th>
                                <th>Miembros</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let c of directivas_list">
                                <td>
                                    <a routerLinkActive="router-link-active" ><img src="{{ c.img }}" alt="user" height="50" width="50" class="img-circle"> {{ c.condominio }}</a>
                                </td>
                                <td>{{ c.direccion }}</td>
                                <td> 
                                    <div class="btn btn-group">
                                        <a type="button" [routerLink]="['/admin/directivas/miembros', c.id ]" routerLinkActive="router-link-active"  class="btn btn-secondary"> <i class="fa fa-users"> </i></a>
                                    </div>
                                </td>
                            </tr>
                           
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        
    </div>
</div>