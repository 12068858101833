import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { PetitionService } from 'src/app/services/petition.service';

declare var $: any;
declare var google:any;

@Component({
  selector: 'app-user-home',
  templateUrl: './user-home.component.html'
})
export class UserHomeComponent implements OnInit {
  /*VARIABLES */
  public casa_encontrada: boolean = false;
  public user_logged: any;
  public mi_casa: any;
  public mi_condo: any;
  public viviendas_list: any[] = [];
  public condominio_list: any[] = [];
  public vehiculos_list: any[] = [];
  public formData: any;
  public formDataCar: any;
  private map: any;

  constructor(
    private petition_service: PetitionService,
    private alert_service: AlertService,
    private formBuilder: FormBuilder) {
      this.get_vivienda();  
      this.get_condominios();
      this.user_logged = JSON.parse(localStorage.getItem('currentUser'));
      
   }

  ngOnInit(): void {
    this.init_form('');
    
  }

  init_form(viv: any) {
    this.formData = this.formBuilder.group({
      usuarios_id: [ +localStorage.getItem('id'), Validators.required],
      viviendas_id: [ viv, Validators.required],
      usuarios_tipos_id: [ this.user_logged. usuarios_tipos_id],
      estado: [ 1 ]
    })
  }

  init_formcar(){
    this.formDataCar = this.formBuilder.group({
      viviendas_id: [ this.mi_casa.id ],
      color: ['', Validators.required],
      marca: ['', Validators.required],
      placas: ['', Validators.required],
      estado: [ 1 ],
      usuarios_id: [ +localStorage.getItem('id') ]
    })
  }

  get_condominios() {
    this.petition_service.get_all('condominio').subscribe(
      (condominios: any) =>{
        this.condominio_list = condominios
      }
    )
  }
  
  get_condominio(condominio: any){
    this.init_form('')
    this.viviendas_list = [];
    this.petition_service.get_all('condominio_viviendas').subscribe(
      (viviendas: any[]) =>{
        viviendas.some(viv => {
          if (+viv.condominio_id == +condominio.target.value) {
            this.viviendas_list.push(viv);
          }
        })
      })
  }


  load_map(lat: any, lon: any){
    let mapEle: HTMLElement = document.getElementById('gmaps');

    var myLatLng = new google.maps.LatLng(parseFloat(lat), parseFloat(lon));
    this.map = new google.maps.Map(mapEle, {
      center: myLatLng,
      zoom: 17
    });
  }
  get_vivienda(){
    this.petition_service.get_all('condominio_viviendas_usuario').subscribe(
      (viviendas: any[]) => {
        for (let i = 0; i < viviendas.length; i++) {
          const viv = viviendas[i];
          if (+viv.usuarios_id == +localStorage.getItem('id')) {
            this.petition_service.get_by_id('condominio_viviendas', viv.viviendas_id).subscribe(
              (vivienda: any) => {
                this.casa_encontrada = true;
                localStorage.setItem('mivivienda', JSON.stringify(vivienda))
                this.mi_casa = vivienda;
                this.get_vehiculos();
                this.petition_service.get_by_id('condominio', this.mi_casa.condominio_id).subscribe(
                  (data: any) => {
                    localStorage.setItem('micondo', JSON.stringify(data))
                    this.mi_condo = data;
                    this.load_map(this.mi_condo.lat, this.mi_condo.long);
                    this.init_formcar();

                  }
                )

              })
            break;
          } 
        }
        setTimeout(() => {
          if (!this.casa_encontrada) {
            this.alert_service.show_swall('No se encuentra su casa', "Ninguna casa esta asociada a usted, asigne su casa en 'Agregar Casa'", 'error')
          }  
        }, 1000);
        
      }
    )
  }

  get_vehiculos(){
    this.vehiculos_list = []
    this.petition_service.get_all('condominio_viviendas_vehiculos').subscribe(
      (vehiculos: any[]) => {
        console.log(vehiculos)
        vehiculos.some(vh => {
          if (+vh.viviendas_id == this.mi_casa.id) {
            this.vehiculos_list.push(vh);
          }
        })
      }
    )
  }

  asignar(){
    this.petition_service.post('condominio_viviendas_usuario', this.formData.value).subscribe(
      (data) => {
        $('#myModal').modal('hide');
        this.alert_service.show_swall('Vivienda Asignada', 'Se ha guardado su registro', 'success');
        this.get_vivienda();
      },
      (err) => {
        console.log(err)
        this.alert_service.show_error();
      }
    )
  }

  guardar_car(){
    this.petition_service.post('condominio_viviendas_vehiculos', this.formDataCar.value).subscribe(
      (data) => {
        this.alert_service.show_swall('Vehículo guardado', 'Los datos de su Vehículo se guardaron con exito', 'success');
        $('#modalagregar').modal('hide');
        this.get_vehiculos();

      },
      (err) =>{
        this.alert_service.show_error();
      }
    )
  }
  eliminar_car(id_car: number) {
    this.petition_service.delete('condominio_viviendas_vehiculos', id_car).subscribe(
      (data) => {
        this.alert_service.show_swall('Vehiculo eliminado', 'Los datos de su vehiculo fueron eliminados', 'success');
        this.get_vehiculos();
      },
      (err) =>{
        this.alert_service.show_error()
      }
    )
  }
}
