<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="d-flex">
                    <div class="align-self-center">
                        <h4 class="card-title m-t-10">Proyectos Actuales</h4>
                    </div>
                    <div class="ml-auto">
                        <div class="input-group">
                            <a type="button" class="btn btn-sm btn-warning btn-create" [routerLink]="['/admin/proyectos/nuevo']" routerLinkActive="router-link-active" >
                                Nuevo Proyecto
                        </a>
                        </div>
                    </div>
                </div>
                <br>
                <div class="table-responsive m-t-40">
                    <table id="tabla-proyecto" class="table table-bordered table-striped">
                        <thead>
                            <tr>

                                <th class="text-center">Título</th>
                                <th class="text-center">Descripción</th>
                                <th class="text-center">Usuario</th>
                                <th class="text-center">Condominio</th>
                                <th class="text-center">Estado</th>
                                <th class="text-center">Opciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let c of proyectos_list" class="text-center">
                                <td>
                                    <a href="javascript:void(0)"><img src="{{ c.img }}" alt="user" height="40" width="40" class="img-circle"> {{ c.titulo }}</a>
                                </td>
                                <td>{{ c.descripcion }}</td>
                                <td>{{ c.usuario_nombre }}</td>
                                <td>{{ c.condominio }}</td>
                                <td class="text-center">
                                    <span *ngIf="c.estado != 1" class="label label-danger">Rechazado</span>
                                    <span *ngIf="c.estado == 1" class="label label-primary">Aprobado</span>
                                </td>
                                <td>
                                    <div class="btn-group">
                                        <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="ti-settings"></i>
                                        </button>
                                        <div class="dropdown-menu"  >
                                            <button class="dropdown-item" *ngIf="c.estado == 2" (click)="update_proyecto(1, c.id)" >Aceptar</button>
                                            <button class="dropdown-item" *ngIf="c.estado == 1" (click)="update_proyecto(2, c.id)" >Rechazar</button>
                                            
                                        </div>
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="modal-eliminar" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="display: none;">
    <form class="modal-dialog" [formGroup]="formDataDelete">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="mySmallModalLabel">Eliminar Proyecto</h4>
                <button type="button"  class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="alert alert-warning" role="alert">
                <a href="#" class="alert-link">¡Cosas inesperadas sucederán si no lees esto!</a>
            </div>
            <div class="modal-body">
                <strong>Esta acción no se puede deshacer.</strong>
                <br>
                <p>Esto eliminará permanentemente el proyecto y todo lo relacionado
                    con el dejará de ser accesible.</p>
                <p>Escriba "<strong *ngIf="proyecto_name">{{proyecto_name}}</strong>" para confirmar.</p>
                <div class="form-group">
                    <input type="text" class="form-control" id="recipient-name" formControlName="nombreConfirm"
                        name="nombreConfirm">
                    <div *ngIf="formDataDelete.controls.nombreConfirm.invalid && (formDataDelete.controls.nombreConfirm.dirty || formDataDelete.controls.nombreConfirm.touched)">
                        <div class="alert alert-danger" role="alert" *ngIf="formDataDelete.controls.nombreConfirm.errors.required">
                            El título es requerido
                        </div>
                    </div>
                    <div *ngIf="!formDataDelete.controls.nombreConfirm.invalid && formDataDelete.controls.nombreConfirm.value != proyecto_name">
                        <div class="alert alert-danger" role="alert">
                            Los nombres no coinciden
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary text-uppercase btn-rounded"
                    data-dismiss="modal">Cerrar</button>
                <button type="submit" (click)="delete()" [disabled]="formDataDelete.controls.nombreConfirm.value != proyecto_name"
                    class="btn btn-danger text-uppercase btn-rounded">Eliminar</button>
            </div>
        </div>
    </form>
</div>