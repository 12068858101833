<style>
    .custom-badge {
    position: relative;
    top: -7px;
    right: -13px;
    font-size: .7em;
  }
  .container-nav {
    max-width: 1140px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  
</style>
<header class="topbar" *ngIf="user_logged">
    
    <nav *ngIf="user_logged.usuarios_tipos_id == 3" class="navbar top-navbar navbar-light text-light py-3   navbar-expand-md">
        <div class="container-nav topheader">
            <div class="d-flex justify-content-center">
                <div >
                    <img src="assets/images/logo.png" width="auto" height="50px" alt="">
                </div>
            </div>
        </div>
    </nav >
    <div *ngIf="user_logged.usuarios_tipos_id == 3" class="bottom sticky-top shadow-sm" style="background-color: #242a33;">
        <nav class="container-nav" >
            <ul  class="nav nav-bottom nav-fill nav-list">
                <li  class="nav-item">
                    <a class="nav-link text-light active" [routerLink]="['/usuario/home']" routerLinkActive="router-link-active" ><i class="ti-home"></i><span class="d-none d-sm-inline-block d-md-inline-block">Home</span></a>
                </li>
                <li  class="nav-item">
                    <a class="nav-link text-light active" [routerLink]="['/usuario/condominios']" routerLinkActive="router-link-active" ><i class="ti-calendar"></i> <span class="d-none d-sm-inline-block d-md-inline-block">Condominios</span> </a>                
                </li>
                <li  class="nav-item">
                    <a class="nav-link text-light active" [routerLink]="['/usuario/noticias']" routerLinkActive="router-link-active" ><i class="ti-calendar"></i> <span class="d-none d-sm-inline-block d-md-inline-block">Noticias</span> </a>                
                </li>
                <li  class="nav-item">
                    <a class="nav-link text-light active" [routerLink]="['/usuario/areacomun']" routerLinkActive="router-link-active" ><i class="ti-calendar"></i> <span class="d-none d-sm-inline-block d-md-inline-block">Areacomún</span> </a>                
                </li>
                <li  class="nav-item">
                    <a class="nav-link text-light active" [routerLink]="['/usuario/objetosperdidos']" routerLinkActive="router-link-active" ><i class="ti-calendar"></i> <span class="d-none d-sm-inline-block d-md-inline-block">Objetos Perdidos</span> </a>                
                </li>
                <li  class="nav-item">
                    <a class="nav-link text-light active" [routerLink]="['/usuario/proyectos']" routerLinkActive="router-link-active" ><i class="ti-calendar"></i> <span class="d-none d-sm-inline-block d-md-inline-block">Proyectos</span> </a>                
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link text-light dropdown-toggle" href="#" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="ti ti-user"></i> <span class="d-none d-sm-inline-block d-md-inline-block"> Visitas </span>
                    </a>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <ul class="dropdown-user">
                            <li>
                                <a [routerLink]="['/usuario/visitas']" routerLinkActive="router-link-active" >Nueva Visita </a>                
                            </li>
                        </ul>
                      </div>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link text-light dropdown-toggle" href="#" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="ti ti-user"></i> <span class="d-none d-sm-inline-block d-md-inline-block"> Ayuda </span>
                    </a>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <ul class="dropdown-user">
                            <li>
                                <a [routerLink]="['/usuario/tips']" routerLinkActive="router-link-active" >Tips </a>                
                            </li>
                            <li>
                                <a [routerLink]="['/usuario/tutoriales']" routerLinkActive="router-link-active" >Tutoriales</a>                
                            </li>
                        </ul>
                      </div>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link text-light dropdown-toggle" href="#" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="ti ti-user"></i> <span class="d-none d-sm-inline-block d-md-inline-block"> Perfil</span>
                    </a>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <ul class="dropdown-user">
                            <li>
                                <div class="dw-user-box" *ngIf="user_logged != null">
                                    <div class="u-img"><img src="{{ user_logged.img ==''||user_logged.img ==null?'assets/images/default-user.jpg':user_logged.img}} " alt="user"></div>
                                    <div class="u-text">
                                        <h4>{{ user_logged.nombre }} {{ user_logged.apellido }}</h4>
                                        <p class="text-muted"> {{ user_logged.email }} </p></div>
                                </div>
                            </li>
                            <li role="separator" class="divider"></li>
                            <li><a [routerLink]="['/usuario/mi-perfil']" routerLinkActive="router-link-active" ><i class="ti-user"></i> Mi Perfil</a></li>
                            <li><a [routerLink]="['/buyapp']" routerLinkActive="router-link-active" ><i class="ti-wallet"></i> Comprar App</a></li>
                            <li role="separator" class="divider"></li>
                            <li><a (click)="logout()" [routerLink]="['/login']" routerLinkActive="router-link-active"  ><i class="fa fa-power-off"></i> Logout</a></li>
                        </ul>
                      </div>
                </li>
            </ul>
        </nav>
    </div>


    <nav *ngIf="user_logged.usuarios_tipos_id == 1" class="navbar top-navbar navbar-light text-light py-3   navbar-expand-md">
        <div class="container-nav topheader">
            <div class="d-flex justify-content-center">
                <a class="nav-link nav-toggler hidden-md-up waves-effect waves-dark" href="javascript:void(0)">
                    <img src="assets/images/logo.png" width="auto" height="50px" alt="">
                </a> 
                <a class="nav-link sidebartoggler hidden-sm-down waves-effect waves-dark" href="javascript:void(0)">
                    <img src="assets/images/logo.png" width="auto" height="50px" alt="">
                </a>        
            </div>
        </div>
    </nav >
    <nav *ngIf="user_logged.usuarios_tipos_id == 4" class="navbar top-navbar navbar-light text-light py-3   navbar-expand-md">
        <div class="container-nav topheader">
            <div class="d-flex justify-content-center">
                <a class="nav-link nav-toggler hidden-md-up waves-effect waves-dark" href="javascript:void(0)">
                    <img src="assets/images/logo.png" width="auto" height="50px" alt="">
                </a> 
                <a class="nav-link sidebartoggler hidden-sm-down waves-effect waves-dark" href="javascript:void(0)">
                    <img src="assets/images/logo.png" width="auto" height="50px" alt="">
                </a>        
            </div>
        </div>
    </nav >
    <nav *ngIf="user_logged.usuarios_tipos_id == 2" class="navbar top-navbar navbar-light text-light py-3   navbar-expand-md">
        <div class="container-nav topheader">
            <div class="d-flex justify-content-center">
                <a class="nav-link nav-toggler hidden-md-up waves-effect waves-dark" href="javascript:void(0)">
                    <img src="assets/images/logo.png" width="auto" height="50px" alt="">
                </a> 
                <a class="nav-link sidebartoggler hidden-sm-down waves-effect waves-dark" href="javascript:void(0)">
                    <img src="assets/images/logo.png" width="auto" height="50px" alt="">
                </a>        
            </div>
        </div>
    </nav >
</header>