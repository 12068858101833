<div class="card" *ngIf="cond_name">
    <div class="card-body">
        <h4 class="card-title">Directiva actual de <strong>{{ cond_name }}</strong></h4>
        <div class="card-body text-center">
            <a type="button" [routerLink]="['/admin/directivas/miembros/agregar', condominio_id ]" routerLinkActive="router-link-active"  class="btn waves-effect waves-light btn-primary">Agregar Personas</a>
        </div>
    </div>
</div>

<div class="row" *ngIf="usuarios_list">
    <div class="col-md-4" *ngFor="let usr of usuarios_list" >
        <div class="card">
            <div class="card-body little-profile text-center">
                <div class="pro-img m-t-20"><img src="{{ usr.imagen_usuario }}" alt="user"></div>
                <h3 class="m-b-0">{{ usr.nombre_usuario }} {{ usr.apellido }}</h3>
            </div>
            <div class="text-center bg-light">
                <div class="row">
                    <div class="col-6  p-20 b-r">
                        <h5 class="m-b-0 font-medium">{{ usr.email }}</h5><small>Email</small></div>
                    <div class="col-6  p-20">
                        <h5 class="m-b-0 font-medium">{{ usr.celular }}</h5><small>Celular</small></div>
                </div>
            </div>
            <div class="card-body text-center">
                <div class="btn-group">
                    <button type="button" class="btn btn-success dropdown-toggle m-t-10 m-b-20 waves-effect waves-dark btn btn-md btn-rounded" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{ usr.nombre_puesto }}
                    </button>
                   
                    <div class="dropdown-menu" x-placement="bottom-start" style="position: absolute; transform: translate3d(0px, 36px, 0px); top: 0px; left: 0px; will-change: transform;">
                        <button type="button" *ngFor="let puesto of puestos_list" class="dropdown-item" (click)="update_directiva(usr.id, puesto.id, usr.nombre_usuario, puesto.nombre)"  > Cambiar a {{ puesto.nombre }}</button>
                        <div class="dropdown-divider"></div>
                        <button type="button" class="dropdown-item" (click)="eliminar(usr.id, usr.nombre_usuario)">Eliminar de la directiva</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>