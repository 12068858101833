
<div class="row">
    <div class="col-lg-4 col-xlg-3 col-md-5" *ngFor="let c of Proyectos">
        <div class="card" >
            <div *ngIf="c.estado == 1">
                <div class="card-body text-center" >
                    <div class="m-t-30"> <img src="{{c.img}}"  width="250" height="250">
                        <h4 class="card-title m-t-10">{{ c.titulo }}</h4>
                    </div>
                </div>
                <div>
                    <hr> 
                </div>
                <div class="card-body"> 
                        <small class="text-muted p-t-30 db">descripcion</small>
                        <h5> {{ c.descripcion }}</h5> 
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #content1 let-modal>
    <div class="modal-body">
        <form [formGroup]="Form">
            <div class="form-group">
                <label>Titulo</label>
                <div class="input-group">
                    <input formControlName="Titulo" type="text" class="form-control">
                </div>
                <label>Descripcion</label>
                <div class="input-group">
                    <textarea formControlName="Descripcion" name="" id="" cols="60" rows="10"></textarea>
                </div>
                <label>Imagen noticia</label>
                <div class="form-group text-center">
                    <div class="fileupload btn btn-danger btn-rounded waves-effect waves-light"><span><i
                                class="fa fa-camera"></i> Seleccionar Imagen</span>
                        
                        <input type="file" accept="image/*" (change)="seleccionImage($event)" class="upload">
                    </div>
                </div>
                </div>
        </form>
    </div>
    <div class="modal-footer">
        <button (click)="AddPropuestaProyecto()">Generar propuesta de proyecto</button>
    </div>
</ng-template>