import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { PetitionService } from 'src/app/services/petition.service';
import { UploadFileService } from 'src/app/services/upload-file.service';

@Component({
  selector: 'app-formulario-area',
  templateUrl: './formulario-area.component.html'
})
export class FormularioAreaComponent implements OnInit {
  public condominio_list: any[] = [];
  /**
   * FORM DATA
   */
  public formData: any;
  /**
   * DATA TO UPDATE
   */
  public area_id: number= 0
  public estoy_edit: boolean = false;
  /**
   *  IMG
   */
  public file: any;
  public strImage: any;
  public subiendo_imagen: boolean = false;

  constructor(
    private activ_route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private petition_service: PetitionService,
    private upload_service: UploadFileService,
    private alert_service: AlertService) {
      this.get_data();
   }

  ngOnInit(): void {
    this.petition_service.get_all('condominio').subscribe(
      (data: any[]) => {
        this.condominio_list = data;
      })
  }

  get_data(){
    this.activ_route.params.subscribe(
      (params) => {
        if (params['id'] == 'nuevo') {
          this.reset_form('','','https://res.cloudinary.com/dksstmpfu/image/upload/v1610211147/test/categoria/sshhivfnkjoqjo13cenf.jpg','', 1, +localStorage.getItem('id'))
        } else {
          this.area_id = params['id']
          this.estoy_edit = true;
          this.petition_service.get_by_id('condominio_area_comun', this.area_id).subscribe(
            (data: any) => {
              this.reset_form(data.titulo, data.descripcion, data.img, data.condominio_id, data.estado, data.usuario_id)
            })
        } 
      })
  }
  reset_form(t: any, d: any, i: any, c: any, e: any, u: any) {
    this.strImage = i;
    this.formData = this.formBuilder.group({
      titulo: [t, Validators.required],
      descripcion: [d, Validators.required],
      img: [i],
      condominio_id: [c, Validators.required],
      estado: [e, Validators.required],
      usuario_id: [u, Validators.required]
    })
  }
  get f(){
    return this.formData.controls;
  }
  
  //CARGAR IMAGEN
  seleccionImage(archivo: any) {

    if (!(archivo.target.files[0])) {
      return;
    }
    this.file = archivo.target.files[0]
    let reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onloadend = () => this.strImage = reader.result;
  }
  uploadImage() {
    this.alert_service.show_save()
    this.subiendo_imagen = true;
    this.upload_service.post(this.file, 'test/categoria')
      .then((res: any) => {
        this.alert_service.show_swall('Imagen subida', 'La imagen se subió con éxito', "success")
        this.formData.get('img').setValue(res.secure_url);
        this.subiendo_imagen = false;
        this.file = null;
      })
      .catch((resp) => {
        console.log(resp)
        this.alert_service.show_error()
        this.subiendo_imagen = false;
      });
  }

  save(){
    this.alert_service.show_save();
    if (!this.estoy_edit) {
      this.petition_service.post('condominio_area_comun', this.formData.value)
      .subscribe(
        (data) => {
          this.alert_service.show_swall('Área común Guardada', 'Los datos del área común se guardaron correctamente', 'success')
          this.get_data()
        }, 
        (err) =>{
          this.alert_service.show_error()
        })
    } else {
      this.petition_service.put('condominio_area_comun', this.formData.value, this.area_id)
      .subscribe(
        (data) => {
          this.alert_service.show_swall('Área común Guardada', 'Los datos del área común se editaron correctamente', 'success')
          this.get_data()
        }, 
        (err) => {
          this.alert_service.show_error()
        })
    }
  }
}
