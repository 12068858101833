import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

@Injectable()
export class UploadFileService {

  //VARIABLES
  URI_FILE = 'https://api.cloudinary.com/v1_1/dksstmpfu/image/upload?upload_preset=fxfc1clo';


  //HTTP OPTIONS
  private httpOptions = {

    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };


  constructor(private http: HttpClient) {

  }

  //HANDLE ERROR
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Un error ha ocurrido:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: `, error.error);
    }
    return throwError(
      error);
  };

  post(file: File, folder: string) {

    var form_data = new FormData();
    form_data.append("upload_preset", 'fxfc1clo');
    form_data.append("file", file);
    form_data.append("folder", folder)
    const config = {
      headers: { "X-Requested-With": "XMLHttpRequest" },
    };
    return this.http.post(this.URI_FILE, form_data, config).toPromise()
      .then(function (res) {
        return res
      })
      .catch(function (err) {
        return err
      });

  }


  seleccionImage(file: any): any{
    let url: any = "hola";
    let reader = new FileReader();
    let urlImagenTemp = reader.readAsDataURL( file );
    
    reader.onloadend = () => url = reader.result;

    setTimeout(() => {
      return url
    }, 2000)
  }




  path_exist(path: string) : string {
    if (!path.includes('fakepath')) {
      return path
    } else {
      return 'assets/images/default-user.jpg'
    }
  }

  subirArchivo( archivo: File, carpeta: string ) {

    return new Promise( (resolve, reject ) => {

      let formData = new FormData();
      let xhr = new XMLHttpRequest();

      formData.append( 'file', archivo, archivo.name );
      formData.append( 'folder', carpeta );

      xhr.onreadystatechange = function() {

        if ( xhr.readyState === 4 ) {

          if ( xhr.status === 200 ) {
            console.log( 'Imagen subida' );
            resolve( JSON.parse( xhr.response ) );
          } else {
            console.log( 'Fallo la subida' );
            reject( xhr.response );
          }

        }
      };

      let url = 'http://api.cloudinary.com/v1_1/dksstmpfu/image/upload?upload_preset=fxfc1clo';

      xhr.open('POST', url, true );
      xhr.send( formData );

    });




  }
}