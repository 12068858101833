<aside class="left-sidebar">
    <!-- Sidebar scroll-->
    <div class="scroll-sidebar ps ps--theme_default ps--active-y" data-ps-id="d5dcf551-50ea-a157-e54e-d5a98d0e9605">
        <!-- Sidebar navigation-->
        <nav class="sidebar-nav active">
            <ul id="sidebarnav" class="in">
                <li class="user-profile" *ngIf="user"> 
                    <a class="has-arrow waves-effect waves-dark" href="#" data-target="#Profile" aria-controls="Profile" data-toggle="collapse" aria-expanded="false"><img src="{{ user.img }}" alt="user"><span class="hide-menu">{{ user.nombre }}  </span></a>
                    <ul id="Profile" aria-expanded="false" class="collapse">
                        <li><a href="javascript:void()">My Profile </a></li>
                        <!--
                        <li><a href="javascript:void()">My Balance</a></li>
                        <li><a href="javascript:void()">Inbox</a></li>
                        -->

                        <li><a href="javascript:void()">Account Setting</a></li>
                        <li><a [routerLink]="['/login']" routerLinkActive="router-link-active"  (click)="logout()">Logout</a></li>
                    </ul>
                </li>
                <li class="nav-devider"></li>
                <li class="nav-small-cap">PERSONAL</li>
                
                <li *ngIf="user.usuarios_tipos_id == 1"> <a class="has-arrow waves-effect waves-dark" href="#" data-target="#Personas" aria-controls="Personas" data-toggle="collapse" aria-expanded="false"><i class="fa fa-user-o"></i><span class="hide-menu">Personas</span></a>
                    <ul id="Personas" aria-expanded="false" class="collapse">
                        <li><a [routerLink]="['/admin/administradores']" routerLinkActive="router-link-active">Administradores </a></li>
                        <li><a [routerLink]="['/admin/usuarios']" routerLinkActive="router-link-active">Clientes </a></li>
                        <li><a [routerLink]="['/admin/segurida']" routerLinkActive="router-link-active">Seguridad </a></li>
                    </ul>
                </li>
                <li *ngIf="user.usuarios_tipos_id == 2"> <a class="has-arrow waves-effect waves-dark" href="#" data-target="#Personas2" aria-controls="Personas2" data-toggle="collapse"  aria-expanded="false"><i class="fa fa-user-o"></i><span class="hide-menu">Personas</span></a>
                    <ul id="Personas2" aria-expanded="false" class="collapse">
                        <li><a [routerLink]="['/admin/usuarios']" routerLinkActive="router-link-active">Clientes </a></li>
                        <li><a [routerLink]="['/admin/segurida']" routerLinkActive="router-link-active">Seguridad </a></li>
                    </ul>
                </li>
                <li> <a *ngIf="user.usuarios_tipos_id !== 4"class="has-arrow waves-effect waves-dark" href="#" data-target="#Membresias" aria-controls="Membresias" data-toggle="collapse" aria-expanded="false"><i class="fa fa-user-o"></i><span class="hide-menu">Membresias</span></a>
                    <ul id="Membresias" aria-expanded="false" class="collapse">
                        <li *ngIf="user.usuarios_tipos_id == 2"><a [routerLink]="['/admin/membresias']" routerLinkActive="router-link-active">Ver membresias </a></li>
                        <li *ngIf="user.usuarios_tipos_id == 1"><a [routerLink]="['/ventas/membresias']" routerLinkActive="router-link-active">Membresias </a></li>
                    </ul>
                </li>
                <li *ngIf="user.usuarios_tipos_id == 1"> <a class="has-arrow waves-effect waves-dark" href="#" data-target="#Condominios" aria-controls="Condominios" data-toggle="collapse" aria-expanded="false"><i class="fa fa-building-o"></i><span class="hide-menu">Condominios</span></a>
                    <ul id="Condominios" aria-expanded="false" class="collapse">
                        <li><a [routerLink]="['/admin/condominios']" routerLinkActive="router-link-active">Ver Condominios </a></li>
                        <li><a [routerLink]="['/admin/condominios/nuevo']" routerLinkActive="router-link-active">Nuevo Condominio </a></li>
                    
                    </ul>
                </li>
                <li *ngIf="user.usuarios_tipos_id == 1"> <a class="has-arrow waves-effect waves-dark" href="#" data-target="#Viviendas" aria-controls="Viviendas" data-toggle="collapse" aria-expanded="false"><i class="fa fa-home"></i><span class="hide-menu">Viviendas</span></a>
                    <ul id="Viviendas" aria-expanded="false" class="collapse">
                        <li><a [routerLink]="['/admin/casas']" routerLinkActive="router-link-active">Ver Viviendas </a></li>
                    </ul>
                </li>

               
                <li *ngIf="user.usuarios_tipos_id == 1"> <a class="has-arrow waves-effect waves-dark" href="#" data-target="#Comunes" aria-controls="Comunes" data-toggle="collapse" aria-expanded="false"><i class="fa fa-tree"></i><span class="hide-menu">Áreas Comunes</span></a>
                    <ul id="Comunes" aria-expanded="false" class="collapse">
                        <li><a [routerLink]="['/admin/areas-comunes']" routerLinkActive="router-link-active" >Ver Áreas</a></li>
                        <li><a [routerLink]="['/admin/areas-comunes/nuevo']" routerLinkActive="router-link-active" >Nueva Área</a></li>
                        <li><a [routerLink]="['/admin/areas-comunes-precios']" routerLinkActive="router-link-active" >Nueva Área Precio</a></li>
                        
                    </ul>
                </li>
                <li *ngIf="user.usuarios_tipos_id == 2"> <a class="has-arrow waves-effect waves-dark" href="#" data-target="#Reservas" aria-controls="Reservas" data-toggle="collapse" aria-expanded="false"><i class="fa fa fa-address-book-o"></i><span class="hide-menu">Reservas</span></a>
                    <ul id="Reservas" aria-expanded="false" class="collapse">
                        <li><a [routerLink]="['/admin/reservaciones']" routerLinkActive="router-link-active" >Ver Reservas</a></li>
                    </ul>
                </li>
                
                <li *ngIf="user.usuarios_tipos_id == 2"> <a class="has-arrow waves-effect waves-dark" href="#" data-target="#Poyectos" aria-controls="Poyectos" data-toggle="collapse" aria-expanded="false"><i class="fa fa-clipboard"></i><span class="hide-menu">Poyectos</span></a>
                    <ul id="Poyectos" aria-expanded="false" class="collapse">
                        <li><a [routerLink]="['/admin/proyectos']" routerLinkActive="router-link-active" >Ver Proyectos</a></li>
                        <li><a [routerLink]="['/admin/proyectos/nuevo']" routerLinkActive="router-link-active" >Nuevo Proyecto</a></li>
                        
                    </ul>
                </li>
                
                <!--
                <li> <a class="has-arrow waves-effect waves-dark" href="#" aria-expanded="false"><i class="fa fa-users"></i><span class="hide-menu">Directivas</span></a>
                    <ul aria-expanded="false" class="collapse">
                        <li><a [routerLink]="['/admin/directivas']" routerLinkActive="router-link-active" >Ver Directivas</a></li>                        
                    </ul>
                </li>
                -->
                
                <li *ngIf="user.usuarios_tipos_id == 2"> <a class="has-arrow waves-effect waves-dark" href="#" data-target="#Noticias" aria-controls="Noticias" data-toggle="collapse" aria-expanded="false"><i class="fa fa-bullhorn"></i><span class="hide-menu">Noticias</span></a>
                    <ul id="Noticias" aria-expanded="false" class="collapse">
                        <li><a [routerLink]="['/admin/noticias']" routerLinkActive="router-link-active" >validar Noticias</a></li>
                    </ul>
                </li>
                <li *ngIf="user.usuarios_tipos_id == 2"> <a class="has-arrow waves-effect waves-dark" href="#" data-target="#Objetos" aria-controls="Objetos" data-toggle="collapse" aria-expanded="false"><i class="fa fa-bullhorn"></i><span class="hide-menu">Objetos Perdidos</span></a>
                    <ul id="Objetos" aria-expanded="false" class="collapse">
                        <li><a [routerLink]="['/admin/Objetos-Perdidos']" routerLinkActive="router-link-active" >validar Objetos Perdidos</a></li>
                    </ul>
                </li>
                
                <li *ngIf="user.usuarios_tipos_id == 2"> <a class="has-arrow waves-effect waves-dark" href="#" data-target="#Ayuda" aria-controls="Ayuda" data-toggle="collapse" aria-expanded="false"><i class="fa fa-bullhorn"></i><span class="hide-menu">Ayuda</span></a>
                    <ul id="Ayuda" aria-expanded="false" class="collapse">
                        <li><a [routerLink]="['/admin/tips']" routerLinkActive="router-link-active" >Ver Tips</a></li>
                        <li><a [routerLink]="['/admin/tutoriales']" routerLinkActive="router-link-active" >ver Tutoriales</a></li>
                    </ul>
                </li>
                <li *ngIf="user.usuarios_tipos_id == 4"> <a class="has-arrow waves-effect waves-dark" href="#" data-target="#Ayuda2" aria-controls="Ayuda2" data-toggle="collapse" aria-expanded="false"><i class="fa fa-bullhorn"></i><span class="hide-menu">Ayuda</span></a>
                    <ul id="Ayuda2" aria-expanded="false" class="collapse">
                        <li><a [routerLink]="['/seguridad/emergencias']" routerLinkActive="router-link-active" >Emergencias</a></li>
                        <li><a [routerLink]="['/seguridad/visitas']" routerLinkActive="router-link-active" >Visitas</a></li>
                    </ul>
                </li>
                <!--
                <li> <a class="has-arrow waves-effect waves-dark" href="#" aria-expanded="false"><i class="fa fa-clipboard"></i><span class="hide-menu">Inbox</span></a>
                    <ul aria-expanded="false" class="collapse">
                        <li><a href="app-email.html">Mailbox</a></li>
                        <li><a href="app-email-detail.html">Mailbox Detail</a></li>
                        <li><a href="app-compose.html">Compose Mail</a></li>
                    </ul>
                </li>
                -->
            </ul>
        </nav>
        <!-- End Sidebar navigation -->
    <div class="ps__scrollbar-x-rail" style="left: 0px; bottom: 0px;"><div class="ps__scrollbar-x" tabindex="0" style="left: 0px; width: 0px;"></div></div><div class="ps__scrollbar-y-rail" style="top: 0px; height: 614px; right: 0px;"><div class="ps__scrollbar-y" tabindex="0" style="top: 0px; height: 388px;"></div></div></div>
    <!-- End Sidebar scroll-->
</aside>