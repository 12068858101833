<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="d-flex">
                    <div class="align-self-center">
                        <h4 class="card-title m-t-10">Áreas comunes Precios</h4>
                    </div>
                    <div class="ml-auto">
                        <div class="input-group">
                            <button class="btn btn-sm btn-warning btn-create" (click)="openModal(content1)">
                                Nuevo Precio
                            </button>
                            <button class="btn btn-sm btn-warning btn-create" (click)="openModal(content2)">
                                Precios-Areas
                            </button>
                            
                        </div>
                    </div>
                </div>
                <br>
                <div class="table-responsive m-t-40">
                    <table id="table-area-comun" class="table table-bordered table-striped">
                        <thead>
                            <tr>

                                <th>Nombre</th>
                                <th>Precio</th>
                                <th>Tiempo</th>
                                <!--<th>Opciones</th>-->
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let c of precios_list">
                                <td>{{ c.nombre }}</td>
                                <td>{{ c.precio }}</td>
                                <td>{{ c.tiempo_mins }}</td>
                                <!--<td> 
                                    <button (click)="updateEstado(2, c.id)" class="btn btn-danger" *ngIf="c.estado == 1">
                                        Desactivar
                                    </button>
                                    <button (click)="updateEstado(1, c.id)" class="btn btn-secondary" *ngIf="c.estado != 1">
                                        Activar
                                    </button>
                                </td>-->
                            </tr>
                           
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #content1 let-modal>
    <div class="modal-header">
        nuevo precio
    </div>
    <div class="modal-body">
        <form [formGroup]="Form">
            <div class="form-group">
                <label>Nombre</label>
                <div class="input-group">
                    <input formControlName="nombre" type="text" class="form-control">
                </div>
                <label>Precio</label>
                <div class="input-group">
                    <input formControlName="precio" type="number" class="form-control">
                </div>
                <label>Tiempo(minutos)</label>
                <div class="input-group">
                    <input formControlName="tiempo_mins" type="number" class="form-control">
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button (click)="nuevoPrecio()">Generar precio</button>
    </div>
</ng-template>

<ng-template #content2 let-modal>
    <div class="modal-header">
        Areas comunes - precios
    </div>
    <div class="modal-body">
        <label>Precio</label>
        <select class="custom-select" id="inputGroupSelect01" (change)="SavePrecioID($event.target.value)">
            <option selected>Choose...</option>
            <option *ngFor="let Pr of precios_list" [value]="Pr.id">{{Pr.nombre}},Q{{Pr.precio}} por {{Pr.tiempo_mins}} min.</option>
        </select>
        <label>Area Comun</label>
        <select class="custom-select" id="inputGroupSelect01" (change)="SaveAreaID($event.target.value)">
            <option selected>Choose...</option>
            <option *ngFor="let Pr of areas_list" [value]="Pr.id">{{Pr.titulo}}</option>
        </select>
    </div>
    <div class="modal-footer">
        <button (click)="AreaPrecio()">Vincular precio y area</button>
    </div>
</ng-template>