import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/services/alert.service';
import { DataService } from 'src/app/services/data.service';
import { PetitionService } from 'src/app/services/petition.service';
import { UploadFileService } from 'src/app/services/upload-file.service';
import {DatePipe} from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-user-areacomun',
  templateUrl: './areacomun.component.html',
})
export class UserAreaComunComponent implements OnInit {
  //VARIABLES
  form: any;
  public noticia_list: any[] = []
  public closeResult: string;
  public idCondo: any;
  file: any;
  strImage: any;
  Form: FormGroup;
  areaComun: any;
  constructor(private petition_service: PetitionService,
    private alert_service: AlertService,
    private data_service: DataService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private upload_service: UploadFileService,
    private datePipe: DatePipe
    ){
      this.idCondo = (JSON.parse(localStorage.getItem('micondo'))).id
      this.getAreasComun(this.idCondo)
      this.Form = this.fb.group({
        Titulo: [ '', Validators.compose([ Validators.required ])  ],
        Descripcion: [ '', Validators.compose([ Validators.required ])  ],
        img: [ '', Validators.compose([ Validators.required ])  ],
        fechaIni: [ '', Validators.compose([ Validators.required ])  ],
        fechaFin: [ '', Validators.compose([ Validators.required ])  ],
        horaIni: [ '', Validators.compose([ Validators.required ])  ],
        horaFin: [ '', Validators.compose([ Validators.required ])  ],
      })
    }

  getAreasComun(id){
    
    this.data_service.AreaComunByCondominio(id)
    .subscribe((res) => {
      console.log(res)
      this.areaComun = res;
    }, (error) => {
      console.log(error)
    })
  }
  AreacomunID: any;
  saveIdAreaC(id){
    this.AreacomunID = id
  }
  openModal(content1) {
    this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  DataAreaComun: any;
  AreaComunPrecio: any;
  AreaComunTiempo: any;
  getAreaComunPrecio(id){
    this.AreacomunID = id.id;
    console.log(this.AreacomunID)
    this.data_service.getAreasComunesPrecios(id.id)
    .subscribe((res) => {
      console.log(res)
      this.DataAreaComun = res;
      this.AreaComunPrecio = res[0].precio;
      this.AreaComunTiempo = res[0].tiempo_mins;
    }, (error) => {
      console.log(error)
    })
  }
    private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
  seleccionImage(archivo: any) {

    if (!(archivo.target.files[0])) {
      return;
    }
    this.file = archivo.target.files[0]
    let reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onloadend = () => this.strImage = reader.result;
    this.uploadImage();
  }
  Imagen: any;
  uploadImage() {
    this.upload_service.post(this.file, 'test/categoria')
      .then((res: any) => {
        this.Imagen = res.url;
        this.alert_service.show_swall('Imagen subida', 'La imagen se subió con éxito', "success")
      })
      .catch((resp) => {
        this.alert_service.show_error();
      });
  }
  reservarAreaComun(){
    let fechaIni = this.Form.controls["fechaIni"].value;
    let fechaFin = this.Form.controls["fechaFin"].value;
    let horaIni = this.Form.controls["horaIni"].value;
    let horaFin = this.Form.controls["horaFin"].value;
    let data = {
      "area_comun_id": this.AreacomunID,
      "condominio_id": this.idCondo,
      "vivendas_usuario_id": (JSON.parse(localStorage.getItem('mivivienda'))).id,
      "fecha_ini": fechaIni,
      "fecha_fin": fechaFin,
      "hora_ini": horaIni,
      "hora_fin": horaFin,
      "costo": this.AreaComunPrecio,
      "usuario_id": (JSON.parse(localStorage.getItem('micondo'))).usuario_id,
      "estado": "1",
    }
    this.data_service.ReservarArea(data)
    .subscribe((res) => {
      console.log(res)
      console.log(this.AreaComunPrecio, this.AreaComunTiempo)
      this.alert_service.show_swall('Reservación lograda', 'Ha reservado con exito', "success")
    }, (error) => {
      this.alert_service.show_swall('Error', 'Por favor intentelo más tarde', "error")
      console.log(error)
    })
  }
  ngOnInit(){

  }  
}