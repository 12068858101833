import { RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "../services/auth.guard.service";
import { AdminComponent } from "./admin/admin.component";
import { SeguridadComponent } from "./seguridad/seguridad.component";
import { UserComponent } from "./user/user.component";
import { VentasComponent } from "./ventas/ventas.component";



const pagesRoutes: Routes = [

    /**admin.module */
    { path: 'admin', component: AdminComponent, canActivate: [ AuthGuardService ], loadChildren: './admin/admin.module#AdminModule' },
    /**ventas.module */
    { path: 'ventas', component: VentasComponent, canActivate: [ AuthGuardService ], loadChildren: './ventas/ventas.module#VentasModule' },
    /**seguridad.module */
    { path: 'seguridad', component: SeguridadComponent, canActivate: [ AuthGuardService ], loadChildren: './seguridad/seguridad.module#SeguridadModule' },
    /**user.module */
    { path: 'usuario', component: UserComponent, /*canActivate: [ AuthGuardService ],*/ loadChildren: './user/user.module#UserModule' },

    { path: '', redirectTo: 'login', pathMatch: 'full' }


];


export const PAGES_ROUTES = RouterModule.forChild(pagesRoutes);
