import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/services/alert.service';
import { DataService } from 'src/app/services/data.service';
import { PetitionService } from 'src/app/services/petition.service';
import { UploadFileService } from 'src/app/services/upload-file.service';
import {DatePipe} from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-user-noticia',
  templateUrl: './user-noticia.component.html',
})
export class UserNoticiaComponent implements OnInit {
  //VARIABLES
  form: any;
  public noticia_list: any[] = []
  public closeResult: string;
  public idCondo: any;
  file: any;
  strImage: any;
  Form: FormGroup;
  constructor(private petition_service: PetitionService,
    private alert_service: AlertService,
    private data_service: DataService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private upload_service: UploadFileService,
    private datePipe: DatePipe
    ) {
      this.Form = this.fb.group({
      Titulo: [ '', Validators.compose([ Validators.required ])  ],
      Descripcion: [ '', Validators.compose([ Validators.required ])  ],
      img: [ '', Validators.compose([ Validators.required ])  ],
    })
      this.petition_service.get_all('condominio_noticias').subscribe(
        (noticias: any[]) => {
          noticias.some(not => {
            
            if (+not.condominio_id == +(JSON.parse(localStorage.getItem('micondo'))).id) {
                this.noticia_list.push(not);
            }
          })
          if (this.noticia_list.length == 0) {
            this.alert_service.show_no_info();
          }
        }
      )
      this.GetCondominioPorUsuario()
   }
   get f(){
    return this.form.controls;
  }
   GetCondominioPorUsuario(){
    return this.idCondo = (JSON.parse(localStorage.getItem('micondo'))).id
   }
   NoticiasForm(){
     this.form = true;
   }
   openModal(content1) {
    this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
    private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
  seleccionImage(archivo: any) {

    if (!(archivo.target.files[0])) {
      return;
    }
    this.file = archivo.target.files[0]
    let reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onloadend = () => this.strImage = reader.result;
    this.uploadImage();
  }
  Imagen: any;
  uploadImage() {
    this.upload_service.post(this.file, 'test/categoria')
      .then((res: any) => {
        this.Imagen = res.url;
        this.alert_service.show_swall('Imagen subida', 'La imagen se subió con éxito', "success")
      })
      .catch((resp) => {
        this.alert_service.show_error();
      });
  }
  public now: Date = new Date();
  GenerarNoticia(){
    var date = new Date();
    let datenow = this.datePipe.transform(date,"yyyy-MM-dd hh:mm:ss")
    let idCondo = (JSON.parse(localStorage.getItem('micondo'))).id;
    let usuario_id = (JSON.parse(localStorage.getItem('micondo'))).usuario_id;
    let viviendas_usuario_id = (JSON.parse(localStorage.getItem('mivivienda'))).id;
    let titulo = this.Form.controls["Titulo"].value;
    let descripcion = this.Form.controls["Descripcion"].value;
    let formulario = {
      "titulo": titulo,
        "descripcion": descripcion,
        "img": this.Imagen,
        "viviendas_usuario_id": viviendas_usuario_id,
        "condominio_id": idCondo,
        "estado": "1",
        "fechahora": datenow,
        "usuarios_id": usuario_id
    }
    this.data_service.CreateNoticias(formulario)
    .subscribe((res)=>{
      console.log(res)
      this.alert_service.show_swall('Noticia generada', 'Noticia por validar', "success")
      this.GetCondominioPorUsuario()
    }, (error) => {
      this.alert_service.show_swall('Error', 'Por favor intentelo más tarde', "error")
      console.log(error)
    })
  }
  ngOnInit(): void {
  }

}
