import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/services/alert.service';
import { DataService } from 'src/app/services/data.service';
import { PetitionService } from 'src/app/services/petition.service';
import { UploadFileService } from 'src/app/services/upload-file.service';
import {DatePipe} from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-user-visitas',
  templateUrl: './visitas.component.html',
})
export class UserVisitasComponent implements OnInit {
  //VARIABLES
  form: any;
  public noticia_list: any[] = []
  public closeResult: string;
  public idCondo: any;
  public idCasa: any;
  public usuario_id: any;
  file: any;
  strImage: any;
  Form: FormGroup;
  constructor(private petition_service: PetitionService,
    private alert_service: AlertService,
    private data_service: DataService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private upload_service: UploadFileService,
    private datePipe: DatePipe
    ){
      this.Form = this.fb.group({
        FechaV: [ '', Validators.compose([ Validators.required ])  ],
        HoraV: [ '', Validators.compose([ Validators.required ])  ],
        NoPlaca: [ '', Validators.compose([ Validators.required ])  ],
      })
      this.usuario_id = (JSON.parse(localStorage.getItem('micondo'))).usuario_id;
      this.idCasa = (JSON.parse(localStorage.getItem('mivivienda'))).id;
      this.idCondo = (JSON.parse(localStorage.getItem('micondo'))).id;
      this.getUsuariosVisitantes();
    }
  AgregarVisita(){
    let FechaV = this.Form.controls["FechaV"].value;
    let HoraV = this.Form.controls["HoraV"].value;
    let NoPlaca = this.Form.controls["NoPlaca"].value;
    let data = {
      "condomino_viviendas_id": this.idCondo,
      "usuario_id": this.vistanteid,
      "vivendas_usuario_id": this.idCasa,
      "estado": "1",
    }
    let datacontrol = {
      "condominio_viviendas_usuario_id": this.idCasa,
      "condominio_viviendas_id": this.idCondo,
      "fecha": FechaV,
      "hora": HoraV,
      "estado": 1,
      "usuario_id": this.vistanteid,
      "placa": NoPlaca
    }
    this.data_service.Visita(data)
    .subscribe((res)=>{
      console.log(res)
      this.data_service.ControlVisita(datacontrol)
      .subscribe((res)=>{
        console.log(res)
        this.alert_service.show_swall('Visita registrada', 'La visita se ha registrado con exito', "success")
      }, (error) => {
        this.alert_service.show_swall('Error', 'Por favor intentelo más tarde', "error")
        console.log(error)
      })
    }, (error) => {
      this.alert_service.show_swall('Error', 'Por favor intentelo más tarde', "error")
      console.log(error)
    })
  }
  visitantes:any;
  getUsuariosVisitantes(){
    this.data_service.getUsuarios(5)
    .subscribe((res)=>{
      console.log(res)
      this.visitantes = res;
    }, (error) => {
      console.log(error)
    })
  }
  vistanteid: any;
  saveVisitantesID(id){
     this.vistanteid = id;
     console.log(id)
  }
  ngOnInit(){

  }  
}