import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/services/alert.service';
import { DataService } from 'src/app/services/data.service';
import { PetitionService } from 'src/app/services/petition.service';
import { UploadFileService } from 'src/app/services/upload-file.service';

declare var $: any;
@Component({
  selector: 'app-ventas-Membresias',
  templateUrl: './membresias.component.html'
})
export class VentasMembresiasComponent implements OnInit {
  //VARIABLES
  condominio_list: any[] = [];
  usuarios_list: any[] = [];
  membresias_list: any[] = [];
  /**
   * VARIABLE PARA ELIMINAR
   */
   public closeResult: string;
  formDataDelete: any;
  condominio_id: number = 0;
  condominio_name: string = '';
  file: any;
  strImage: any;
  Form: FormGroup;
  Form2: FormGroup;
  Form3: FormGroup;
  constructor(
    private petition_service: PetitionService, 
    private fb: FormBuilder,
    private alert_service: AlertService,
    private modalService: NgbModal,
    private upload_service: UploadFileService,
    private data_service: DataService,
    private datePipe: DatePipe
    ) {
      var date = new Date();
      this.Form = this.fb.group({
        titulo: [ '', Validators.compose([ Validators.required ])  ],
        descripcion: [ '', Validators.compose([ Validators.required ])  ],
        cantidad_viviendas: [ '', Validators.compose([ Validators.required ])  ],
        precio: [ '', Validators.compose([ Validators.required ])  ],
        tiempo: [ '', Validators.compose([ Validators.required ])  ],
        estado: [ 1, Validators.compose([ Validators.required ])  ]
      })
      this.Form2 = this.fb.group({
        titulo: [ '', Validators.compose([ Validators.required ])  ],
        descripcion: [ '', Validators.compose([ Validators.required ])  ],
        fecha: [ this.datePipe.transform(date,"yyyy-MM-dd"), Validators.compose([ Validators.required ])  ],
        hora: [ this.datePipe.transform(date,"hh:mm:ss"), Validators.compose([ Validators.required ])  ],
        link: [ '', Validators.compose([ Validators.required ])  ],
        condominio_id: [ '', Validators.compose([ Validators.required ])  ],
        condominio_viviendas_usuario_id: [ '', Validators.compose([ Validators.required ])  ],
        estado: [ 1, Validators.compose([ Validators.required ])  ]
      })
      this.Form3 = this.fb.group({
        id_condominio_membresia: [ '', Validators.compose([ Validators.required ])  ],
        id_usuario: [ '', Validators.compose([ Validators.required ])  ],
        fecha_fin: [ '', Validators.compose([ Validators.required ])  ],
        estado: [ 1, Validators.compose([ Validators.required ])  ],
      })
    this.get_condominios();
    this.get_usuarios();
    this.get_membresias();
   }

  ngOnInit(): void {
    this.get_condominios();  
    this.formDataDelete = this.fb.group({
      nombreConfirm: ['', Validators.required]
    });
  }
  openModal(content1) {
    this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
  seleccionImage(archivo: any) {

    if (!(archivo.target.files[0])) {
      return;
    }
    this.file = archivo.target.files[0]
    let reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onloadend = () => this.strImage = reader.result;
    this.uploadImage();
  }
  Imagen: any;
  uploadImage() {
    this.upload_service.post(this.file, 'test/categoria')
      .then((res: any) => {
        this.Imagen = res.url;
        this.alert_service.show_swall('Imagen subida', 'La imagen se subió con éxito', "success")
      })
      .catch((resp) => {
        this.alert_service.show_error();
      });
  }
  get_condominios(){

    
    this.petition_service.get_all('condominio_membresia').subscribe(
      (data: any[]) => {
        this.condominio_list = data
        console.log(data)
        setTimeout(() => {
          $('#tabla-condominios').DataTable();
        }, 500);
      },
      (err) =>{
        this.alert_service.show_no_info();
      }
    )
  }
  get_membresias(){
    this.data_service.GetAllcondominio_membresia()
    .subscribe( (res) => {
      this.membresias_list = res;
      console.log(res)
    }, (error) => {
      this.api_error = error
      console.log(this.api_error)
    })
  }
  get_usuarios(){
    this.data_service.GetAllusuarios(2)
    .subscribe( (res) => {
      this.usuarios_list = res;
      console.log(res)
    }, (error) => {
      this.api_error = error
      console.log(this.api_error)
    })
  }
  api_error: any;
  NuevaMembresia(){
    console.log(this.Form.value)
    let titulo = this.Form.controls["Titulo"].value;
    let descripcion = this.Form.controls["Descripcion"].value;
    let cantidad_viviendas = this.Form.controls["Descripcion"].value;
    let precio = this.Form.controls["Descripcion"].value;
    let tiempo = this.Form.controls["Descripcion"].value;
    let data = {
      "titulo": titulo,
      "descripcion": descripcion,
      "cantidad_viviendas": cantidad_viviendas,
      "precio": precio,
      "img": this.Imagen,
      "tiempo": tiempo,
      "estado": 1,
    }
    this.data_service.AddMembresia(data)
    .subscribe( (res) => {
      console.log(res)
      this.alert_service.show_swall('Membresia creada', 'La membresia se creo con exito', "success")
    }, (error) => {
      this.api_error = error
      console.log(this.api_error)
    })
  }
  MembresiaCondominio(){
    console.log(this.Form2.value)
  }
  AsignarMembresia(){
    console.log(this.Form3.value)
    this.data_service.AddAsignaciónMembresia(this.Form3.value)
    .subscribe( (res) => {
      console.log(res)
      this.alert_service.show_swall('Membresia Asignada', 'La membresia se asigno con exito', "success")
    }, (error) => {
      this.api_error = error
      console.log(this.api_error)
    })
  }
}
