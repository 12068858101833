import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  URI = 'https://alquimistapp-condominios.herokuapp.com/api/usuarios/login';
  URI_RECOVERY = ''
  
  constructor(private http: HttpClient) {
  }

  //HTTP OPTIONS
  private httpOptions = {

      headers: new HttpHeaders({
          'Content-Type': 'application/json'
      })
  };

  //HANDLE ERROR
  private handleError(error: HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
          console.error('Un error ha ocurrido:', error.error.message);
      } else {
          console.error(
              `Backend returned code ${error.status}, ` +
              `body was: `, error.error);
      }
      return throwError(
          error);
  };
  
  login(post_email: string, post_pass: string) {
      
      return this.http.put( this.URI, { email: post_email, pass: post_pass }, this.httpOptions)
        .pipe(map(
            (user: any) => {
                this.save_data(user[0], user[0].id, user[0].usuarios_tipos_id)
                return user;
            }, 
            (err: any)=> {
                catchError(this.handleError)
        }));
    


  }

  recovery_password(email: string){
      
      return this.http.put(`${this.URI_RECOVERY}`, email, this.httpOptions)
      .pipe(map(
          (data) => {
              return data;
          }, 
          (err: any)=> {
              catchError(this.handleError)
      }));
  }

  logout() {
      // limpia la sesion
      localStorage.clear();
      localStorage.removeItem('admin');
      localStorage.removeItem('currentUser');
      localStorage.removeItem('id');
      localStorage.removeItem('mivivienda');
      localStorage.removeItem('micondo');
  }



  save_data(user: any, id: any, type: any){
      console.log(user)
      console.log(id)
      console.log(type)
      localStorage.clear();
      localStorage.setItem('currentUser', JSON.stringify(user));
      localStorage.setItem('id', id);
      if (type == 1) {
          localStorage.setItem('admin', '1')
      } else {
          localStorage.setItem('admin', '2')    
      }
  }

}
