import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { PetitionService } from 'src/app/services/petition.service';
import { UploadFileService } from 'src/app/services/upload-file.service';

@Component({
  selector: 'app-formulario-proyectos',
  templateUrl: './formulario-proyectos.component.html'
})
export class FormularioProyectosComponent implements OnInit {
  /**
   * LISTADO DE CONDOMINIOS
   */
  private condominios_list: any[] = [];
  /**
   * EDIICION DE PROYECTO
   * 
   */
  private estoy_edit: boolean = false;
  private proyecto_id: number = 0;
 /**
   * FORM DATA
   */
  public formData:any;

  /**
   * FILE IMG
   * 
   */
  private strImage: any;
  private file: any;
  private subiendo_imagen: boolean = false


  constructor(private formBuilder: FormBuilder,
    private activroute: ActivatedRoute,
    private petition_service: PetitionService,
    private alert_service: AlertService,
    private upload_service: UploadFileService) { 
      this.initialize_form();
    }

  ngOnInit(): void {
  }


  initialize_form(){
    this.condominios_list = [];
    this.petition_service.get_all('condominio').subscribe(
      (condominios: any[]) => {
        this.condominios_list = condominios;
      }, 
      (err) => {
        this.alert_service.show_no_info();
      }
    )
    this.activroute.params.subscribe( params => {
      if (params['id'] == 'nuevo') {
        this.estoy_edit = false;
        this.reset_form('','','https://p4.wallpaperbetter.com/wallpaper/241/58/541/apartment-condo-condominium-design-wallpaper-preview.jpg', +localStorage.getItem('id'), '', '');
      } else {
        this.estoy_edit = true;
        this.proyecto_id = params['id']
        this.petition_service.get_by_id('condominio_proyecto', this.proyecto_id).subscribe(
          (data: any) => {     
            this.reset_form(data.titulo,data.descripcion,data.img,data.usuario_id,data.condominio_id,data.estado);
          });
      }
    })
  }

  reset_form(t: any, d: any, i:any, u:any, c:any, e:any ){
    this.strImage = i;
    this.formData = this.formBuilder.group({
      titulo: [ t, Validators.required],
      descripcion: [ d, Validators.required],
      img: [ i ],
      usuario_id: [ u ],
      condominio_id: [ c, Validators.required ],
      estado: [ e ]
    })
  }
  get f(){
    return this.formData.controls;
  }
  
  
  //CARGAR IMAGEN
  seleccionImage(archivo: any) {

    if (!(archivo.target.files[0])) {
      return;
    }
    this.file = archivo.target.files[0]
    let reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onloadend = () => this.strImage = reader.result;
  }
  uploadImage() {
    this.alert_service.show_save()
    this.subiendo_imagen = true;
    this.upload_service.post(this.file, 'test/categoria')
      .then((res: any) => {
        this.alert_service.show_swall('Imagen subida', 'La imagen se subió con éxito', "success")
        this.formData.get('img').setValue(res.secure_url);
        this.subiendo_imagen = false;
        this.file = null;
      })
      .catch((resp) => {
        console.log(resp)
        this.alert_service.show_error()
        this.subiendo_imagen = false;
      });
  }

  guardar(){
  
    this.alert_service.show_save();
    if (!this.estoy_edit) {
      this.petition_service.post('condominio_proyecto',this.formData.value).subscribe(
        (data) => {
          this.initialize_form();
          this.alert_service.show_swall('Proyecto agregado', 'Los datos del proyecto se guardaron correctamente', 'success');
        }, 
        (err) => {
          this.alert_service.show_error();
        })
    } else {
      console.log(this.formData.value)
      this.petition_service.put( 'condominio_proyecto',this.formData.value, this.proyecto_id).subscribe(
        (data) => {
          this.alert_service.show_swall('Proyecto editado', 'Los datos del proyecto se guardaron correctamente', 'success');  
          this.initialize_form();
        }, 
        (err) => {
          this.alert_service.show_error()
        })
    }
  }
}
