import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/services/alert.service';
import { DataService } from 'src/app/services/data.service';
import { PetitionService } from 'src/app/services/petition.service';
import { UploadFileService } from 'src/app/services/upload-file.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html'
})
export class UserComponent implements OnInit {
  form: any;
  public noticia_list: any[] = []
  public closeResult: string;
  public idCondo: any;
  public idCasa: any;
  file: any;
  strImage: any;
  Form: FormGroup;
  constructor(private petition_service: PetitionService,
    private alert_service: AlertService,
    private data_service: DataService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private upload_service: UploadFileService,
    private datePipe: DatePipe
    ){
      
    }
    reportarEmergencia(){
      var date = new Date();
      let datenow = this.datePipe.transform(date,"yyyy-MM-dd hh:mm:ss")
      let data = {
        "condominio_vivienda_id": this.idCasa,
        "fechahora": datenow
      }
      console.log(data)
      this.data_service.NewEmergencia(data)
      .subscribe((res)=>{
        this.alert_service.show_swall('Emergencia reportada', 'La emergencia a llegado a seguridad', "success")
        console.log(res)
      }, (error) => {
        console.log(error)
        this.alert_service.show_swall('Un error ha ocurrido', 'Refresque la pagina y vuelva a intentar', "error")
      })
    }
    
  ngOnInit(): void {
    this.idCasa = (JSON.parse(localStorage.getItem('mivivienda'))).id;
      this.idCondo = (JSON.parse(localStorage.getItem('micondo'))).id
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    
  }

}
