import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html'
})
export class SideBarComponent implements OnInit {
  public user: any;
  constructor(private router: Router) { 
    this.user = JSON.parse(localStorage.getItem('currentUser'))
  }

  ngOnInit(): void {
    console.log(this.user.usuarios_tipos_id)
  }


  logout(){
    console.log("si funciona logout")
    sessionStorage.clear();
    localStorage.removeItem('admin');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('id');
    localStorage.removeItem('mivivienda');
    localStorage.removeItem('micondo');

  }
}
