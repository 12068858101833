<div class="row">
    <!-- Column -->
    <div class="col-lg-4 col-xlg-3 col-md-5">
        <div class="card">
            <div class="card-body text-center" >
                <div class="m-t-30"> <img src="{{user_logged.img}}" class="img-circle" width="150">
                    <h4 class="card-title m-t-10">{{ user_logged.nombre }} {{ user_logged.apellido}}</h4>
                </div>
            </div>
            <div>
                <hr> </div>
            <div class="card-body"> 
                <small class="text-muted">Email  </small>
                <h5>{{ user_logged.email }}</h5> 
                <small class="text-muted p-t-30 db">Celular</small>
                <h5> {{ user_logged.cel }}</h5> 
                <small class="text-muted p-t-30 db">Teléfono</small>
                <h5> {{ user_logged.tel }}</h5> 
                <button>
                    Editar info
                </button>
            </div>
        </div>
    </div>
    <!-- Column -->
    <div class="col-lg-8 col-xlg-9 col-md-7">
        <div class="card">
            <div class="card-body" *ngIf="casa_encontrada">
                <ul class="product-review">
                    <li>
                            <span>
                            <h3 class="card-title">Número de Vivienda</h3>
                            <h5 class="card-subtitle">{{ mi_casa.numero }}</h5> 
                        </span>
                        <hr>
                    </li>
                    <li>
                        <span *ngIf="mi_condo">
                            <h3 class="card-title">Condominio</h3>
                            <h5 class="card-subtitle"> {{ mi_condo.nombre }}</h5> 
                            <h5 class="card-subtitle"> {{ mi_condo.direccion }}</h5> 
                        </span>
                        <div id="gmaps" class="gmaps" style="height: 170px; width: 100%"></div>
                    </li>
                </ul>
            </div>
            <div class="card-body text-center" *ngIf="!casa_encontrada">
                <h3 class="card-title">Asignar Casa</h3>
                <br *ngFor="let i of [].constructor(7)">
                <button data-toggle="modal" data-target="#myModal" class="btn btn-lg btn-primary"> Agregar Casa</button>    
                <br *ngFor="let i of [].constructor(7)">
            </div>
        </div> 
        
        
    </div>
</div>
<div class="row" *ngIf="casa_encontrada">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-8">
                        <h3 class="text-muted">Mis Vehículos</h3></div>
                    <div class="col-md-4 align-self-center text-right">
                        <button type="button" class="btn btn-success" data-toggle="modal" data-target="#modalagregar" > <i class="ti-car"></i> Nuevo Vehículo</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="row">
    <div class="card bg-info text-white">
        
    </div>
    <div class="col-md-4" *ngFor="let c of vehiculos_list">

        <div class="card">
            <div class="card-body">
                <div class="d-flex">
                    <div class="stats">
                        <h1 >{{ c.placas }}</h1>
                        <h6 >{{ c.marca }} - {{ c.color }}</h6>
                        <button (click)="eliminar_car(c.id)" class="btn btn-rounded btn-outline-danger m-t-10 font-14">Eliminar</button>
                    </div>
                    <div class="stats-icon text-right ml-auto"><i class="ti-car display-5 op-3 text-dark"></i></div>
                </div>
            </div>
        </div>
    </div>
</div>








<div id="myModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="display: none;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="mySmallModalLabel">Asignar Vivienda</h4>
                <button type="button"  class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="alert alert-warning" role="alert">
                <a href="#" class="alert-link">¡Cosas inesperadas sucederán si no lees esto!</a>
            </div>
            <div class="modal-body">
                <strong>Esta acción no se puede deshacer.</strong>
                <br>
                <p>Está por asignar su vivienda, seleccione el condominio y luego el número de vivienda, tenga en cuenta que 
                    una vez asignada, no puede cambiar de vivienda, si existe algún inconveniente comuníquese con el administrador
                    de la página.
                </p>
               <form [formGroup]="formData">
                <div class="form-group">
                    <label for="condominio">Condominio</label>
                    <select name="condominio" (change)="get_condominio($event)" class="form-control" id="condominio">
                        <option selected disabled> Seleccionar</option>
                        <option *ngFor="let c of condominio_list" value="{{ c.id }}">{{ c.nombre }}</option>
                    </select>   
                </div>
                <div class="form-group">
                    <label for="vivienda">No. Vivienda</label>
                    <select name="vivienda" formControlName="viviendas_id" class="form-control" id="vivienda">
                        <option selected disabled> Seleccionar</option>
                        <option *ngFor="let c of viviendas_list" value="{{ c.id }}">{{ c.numero }}</option>
                    </select>   
                </div>
               </form>

            </div>
            
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary text-uppercase btn-rounded"
                    data-dismiss="modal">Cerrar</button>
                <button type="submit" [disabled] ="!formData.valid" (click)="asignar()"
                    class="btn btn-primary text-uppercase btn-rounded">Asignar</button>
            </div>
        </div>
    </div>
    <!-- /.modal-dialog -->
</div>


<div id="modalagregar" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="display: none;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="mySmallModalLabel">Nuevo Vehículo</h4>
                <button type="button"  class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            
            <div class="modal-body" *ngIf="formDataCar">
               <form [formGroup]="formDataCar">
                <div class="form-group">
                    <label for="condominio">Color</label>
                    <input type="text" class="form-control" formControlName="color">   
                </div>
                <div class="form-group">
                    <label for="condominio">Marca</label>
                    <input type="text" class="form-control" formControlName="marca">   
                </div>
                <div class="form-group">
                    <label for="condominio">Placa</label>
                    <input type="text" class="form-control" formControlName="placas">   
                </div>
                
               </form>

            </div>
            
            <div class="modal-footer" *ngIf="formDataCar">
                <button type="button" class="btn btn-secondary text-uppercase btn-rounded"
                    data-dismiss="modal">Cerrar</button>
                <button type="submit" [disabled] ="!formDataCar.valid" (click)="guardar_car()"
                    class="btn btn-primary text-uppercase btn-rounded">Guardar</button>
            </div>
        </div>
    </div>
    <!-- /.modal-dialog -->
</div>