import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-seguridad',
  templateUrl: './seguridad.component.html'
})
export class SeguridadComponent implements OnInit {
  public idUser: any;
  constructor(private data_service: DataService,) {
    this.idUser = (JSON.parse(localStorage.getItem('currentUser'))).id
    this.data_service.CondominioSeguridad(this.idUser)
    .subscribe((res) => {
      console.log(res)
      localStorage.setItem('Seguridad_data',JSON.stringify(res));
    }, (error) => {
      console.log(error)
    })
   }

  ngOnInit(): void {
    this.idUser = (JSON.parse(localStorage.getItem('currentUser'))).id
    this.data_service.CondominioSeguridad(this.idUser)
    .subscribe((res) => {
      console.log(res)
      localStorage.setItem('Seguridad_data',JSON.stringify(res));
    }, (error) => {
      console.log(error)
    })
  }

}

