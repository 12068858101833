import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { AlertService } from 'src/app/services/alert.service';
import { PetitionService } from 'src/app/services/petition.service';
import { UploadFileService } from 'src/app/services/upload-file.service';

//GOOGLE
declare var google:any;

@Component({
  selector: 'app-nueva-membresia',
  templateUrl: './nueva-membresia.component.html'
})
export class FormularioMembresiaComponent implements OnInit {
  /**
   * EDIICION DE CONDOMINIO
   * 
   */
  private estoy_edit: boolean = false;
  private condominio_id: number = 0;
  /**
   * FORM DATA
   */
  public formData:any;

  /**
   * FILE IMG
   * 
   */
  private strImage: any;
  private file: any;
  private subiendo_imagen: boolean = false

  /**
   * 
   * GOOGLE MAPS
  */
  private map: any;
  public data = {
    lat: 0,
    long: 0,
  }

  constructor(
    private formBuilder: FormBuilder,
    private activroute: ActivatedRoute,
    private petition_service: PetitionService,
    private alert_service: AlertService,
    private upload_service: UploadFileService) {
      this.initialize_data();
     }

  ngOnInit(): void {
    setTimeout(() => {
      this.obtenerPosicion();      
    }, 1000);
  }

  initialize_data(){
    
  }
  reset_form(n: string, d: string, lo: string, lat: string, c: string, img: string, est: number, user: number){

    this.formData = this.formBuilder.group({
      nombre: [n, Validators.required],
      direccion: [d, Validators.required],
      long: [lo, Validators.required],
      lat:  [lat, Validators.required],
      cantidad_viviendas: [c, Validators.required],
      imagen: [ img ],
      estado: [ est ],
      usuario_id: [ user ],
      id_membresia: [ 0 ]
    })
  }

  get f(){
    return this.formData.controls;
  }


  public obtenerPosicion() {
    this.getPosition()
    .then((res) => {
      this.loadMap(res.lat, res.lng)
    })
  }
  getPosition(): Promise<any>
  {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => {
          resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
        },
        err => {
          reject(err);
        });
    });
  }


  public loadMap(lat:any, lon:any) {
    this.data.lat = lat.toString();
    this.data.long = lon.toString();

    let mapEle: HTMLElement = document.getElementById('gmaps');

    var myLatLng = new google.maps.LatLng(parseFloat(lat), parseFloat(lon));
    this.map = new google.maps.Map(mapEle, {
      center: myLatLng,
      zoom: 17
    });
  
  // Create the initial InfoWindow.
  let infoWindow = new google.maps.InfoWindow({
    content: "Click en el mapa para obtener la Latitud/Longitud!",
    position: myLatLng,
  });
  infoWindow.open(this.map);

  // Configure the click listener.
  this.map.addListener("click", (mapsMouseEvent) => {
    // Close the current InfoWindow.
    infoWindow.close();

    // Create a new InfoWindow.
    infoWindow = new google.maps.InfoWindow({
      position: mapsMouseEvent.latLng,
    });

    this.formData.get('long').setValue(mapsMouseEvent.latLng.toJSON().lng)
    this.formData.get('lat').setValue(mapsMouseEvent.latLng.toJSON().lat)
    this.data.lat = mapsMouseEvent.latLng.toJSON().lat
    this.data.long = mapsMouseEvent.latLng.toJSON().lng
    
    infoWindow.setContent(
      JSON.stringify(mapsMouseEvent.latLng.toJSON(), null, 2)
    );
    infoWindow.open(this.map);
  });
    
  }


  
  //CARGAR IMAGEN
  seleccionImage(archivo: any) {

    if (!(archivo.target.files[0])) {
      return;
    }
    this.file = archivo.target.files[0]
    let reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onloadend = () => this.strImage = reader.result;
  }
  uploadImage() {
    this.alert_service.show_save()
    this.subiendo_imagen = true;
    this.upload_service.post(this.file, 'test/categoria')
      .then((res: any) => {
        this.alert_service.show_swall('Imagen subida', 'La imagen se subió con éxito', "success")
        this.formData.get('imagen').setValue(res.secure_url);
        this.subiendo_imagen = false;
        this.file = null;
      })
      .catch((resp) => {
        console.log(resp)
        this.alert_service.show_error()
        this.subiendo_imagen = false;
      });
  }


  guardar(){
  
    this.alert_service.show_save();
    if (!this.estoy_edit) {
      this.petition_service.post('condominio', this.formData.value).subscribe(
        (data) => {
          this.initialize_data();
          this.alert_service.show_swall('Condominio agregado', 'Los datos del condominio se guardaron correctamente', 'success');
        }, 
        (err) => {
          this.alert_service.show_error();
        })
    } else {
      console.log(this.formData.value)
      this.petition_service.put('condominio',this.formData.value, this.condominio_id).subscribe(
        (data) => {
          this.alert_service.show_swall('Condominio editado', 'Los datos del condominio se guardaron correctamente', 'success');  
          this.initialize_data();
        }, 
        (err) => {
          this.alert_service.show_error()
        })
    }
  }

  
}
/*
function initMap(){
  navigator.geolocation.getCurrentPosition(success, error);
}

function success(pos) {
  var crd = pos.coords;
  let latitud = crd.latitude
  let longitud = crd.longitude   
  document.getElementById("latitud").innerHTML = latitud 
  document.getElementById("longitud").innerHTML = longitud 
  Map = new google.maps.Map(document.getElementById("map"), {
      center: {lat: latitud, lng: longitud},
      zoom: 14
  });
};
*/