import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { PetitionService } from 'src/app/services/petition.service';
import { UploadFileService } from 'src/app/services/upload-file.service';

declare var $: any;

@Component({
  selector: 'app-admin-usuarios',
  templateUrl: './admin-usuarios.component.html'
})
export class AdminUsuariosComponent implements OnInit {
  //VARIABLES
  usuarios_list: any[] = [];
  ver_admins: boolean = false;
  ver_seguridad: boolean = false;
  usuario_id: number = 0;
  usuario_name: string = '';
  /**
   * FORM DATA
   */
  public formDataDelete: any;

  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private alert_service: AlertService,
    private petition_service: PetitionService,
    private upload_service: UploadFileService) {
    this.get_usuarios();
  }

  ngOnInit(): void {
    this.formDataDelete = this.formBuilder.group({
      nombreConfirm: ['', Validators.required]
    });
  }


  get_usuarios() {
    if (this.router.url.includes('administradores')) {
      this.ver_admins = true;
    }
    if (this.router.url.includes('segurida')) {
      console.log("usuario seguridad")
      this.ver_seguridad = true;
    }
    this.usuarios_list = [];
    this.petition_service.get_all('usuarios').subscribe(
      (data: any[]) => {
        if (this.ver_admins) {
          data.some(user => {
              if (user.usuarios_tipos_id == 2) {
                this.usuarios_list.push(user);
              }
          });
        } else if (this.ver_seguridad) {
          data.some(user => {
              if (user.usuarios_tipos_id == 4) {
                this.usuarios_list.push(user);
              }
          });
        } else {
          data.some(user => {
            if (user.usuarios_tipos_id == 3) {
              this.usuarios_list.push(user);
            }
          });
        }
        setTimeout(() => {
          $('#tabla-usuarios').DataTable();
        }, 1500);
      })
      console.log(this.usuarios_list)
  }


  data_to_delete(id: number, titulo: string) {
    this.usuario_id = id;
    this.usuario_name = titulo;
    this.formDataDelete.reset();
  }
  delete(){
    this.petition_service.delete('usuarios', this.usuario_id).subscribe(
      (data: any) => {
        this.get_usuarios();
        this.alert_service.show_swall("Usuario Eliminado", "Se ha eliminado el usuario correctamente", "success")
        $('#modal-eliminar').modal('hide');
      }, (err) => {
        this.alert_service.show_error();
      }
    ) 
  }

}
