<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="d-flex">
                    <div class="align-self-center">
                        <h4 class="card-title m-t-10">Reservaciones Actuales</h4>
                    </div>
                </div>
                <br>
                <div class="table-responsive m-t-40">
                    <table id="tabla-reservas" class="table table-bordered table-striped">
                        <thead>
                            <tr>

                                <th>Área Común</th>
                                <th>F. Reserva</th>
                                <th>H. Reserva</th>
                                <th>Usuario Reserva</th>
                                <th>Condominio</th>
                                <th>Precio</th>
                                <th>Estado</th>
                                <th>Opciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let c of reservas_list">
                                <td>
                                    <a><img src="{{ c.img }}" alt="user" height="40" width="40" class="img-circle"> {{ c.area }}</a>
                                </td>
                                <td>{{ c.fecha_ini | date: 'shortDate' }}</td>
                                <td>{{ c.hora_ini }}</td>
                                <td>{{ c.user }}</td>
                                <td>{{ c.condominio }}</td>
                                <td>Q {{ c.costo }}</td>
                                <td class="text-center">
                                    <span *ngIf="c.estado == 2" class="label label-danger">Rechazado</span>
                                    <span *ngIf="c.estado == 0" class="label label-warning">Pendiente</span>
                                    <span *ngIf="c.estado == 1" class="label label-primary">Aprobado</span>

                                </td>
                                <td> 
                                    <div class="btn-group">
                                        <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="ti-settings"></i>
                                        </button>
                                        <div class="dropdown-menu"  >
                                            <button class="dropdown-item" *ngIf="c.estado == 2" (click)="update_reserva(1, c.id)" >Aceptar</button>
                                            <button class="dropdown-item" *ngIf="c.estado == 1" (click)="update_reserva(2, c.id)" >Rechazar</button>
                                            
                                        </div>
                                    </div>
                                </td>
                            </tr>
                           
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

