import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class SeguridadHomeComponent implements OnInit {

  constructor() {
   }

  ngOnInit(): void {
    console.log("prueba beta")
  }

}
