<div class="card">
    <div class="card-body" *ngIf="formData">
        <h4 class="card-title">Formulario de Condominio</h4>
        <form [formGroup]="formData" class="form-material m-t-40">
            <div class="row">
                <div class="col-lg-5">
                    <div class="row el-element-overlay" style="margin-bottom: 0px !important;">
                        <div class="col-md-2">
                        </div>
                        <div class="col-md-8">
                            <div class="card" style="margin-bottom: 0px !important;">
                                <div class="el-card-item" style="margin-bottom: 0px !important;">
                                    <div *ngIf="estoy_edit == 0" class="el-card-avatar el-overlay-1"
                                        style="margin-bottom: 0px !important;">
                                        <img *ngIf="!file"
                                            src="https://p4.wallpaperbetter.com/wallpaper/241/58/541/apartment-condo-condominium-design-wallpaper-preview.jpg"
                                            alt="area" />
                                        <img *ngIf="file" src="{{strImage}}" alt="area" />
                                    </div>
                                    <div *ngIf="estoy_edit == 1" class="el-card-avatar el-overlay-1"
                                        style="margin-bottom: 0px !important;">
                                        <img src="{{strImage}}" alt="area" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                        </div>
                    </div>
                    <div class="form-group text-center">
                        <label>Foto del área (opcional)</label>
                        <br>
                        <div class="fileupload btn btn-danger btn-rounded waves-effect waves-light"><span><i
                            class="fa fa-camera"></i> Seleccionar Imagen</span>
                            <input type="file" accept="image/*"  (change)="seleccionImage($event)"  class="upload"> 
                        </div>

                    </div>
                </div>
                <div class="col-lg-7">
                    <div class="form-group">
                        <div id="gmaps" class="gmaps" style="height: 350px; width: 100%"></div>
                    </div>
                    <div class="form-group">
                        <label for="direccion">Dirección Condominio</label>
                        <input class="form-control" formControlName="direccion">
                        <div *ngIf="f.direccion.invalid && (f.direccion.dirty || f.direccion.touched)">
                            <div *ngIf="f.direccion.errors.required" style="color:red">Dato requerido</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Nombre del Condominio</label>
                        <input type="text" formControlName="nombre" class="form-control form-control-line">
                        <div *ngIf="f.nombre.invalid && (f.nombre.dirty || f.nombre.touched)">
                            <div *ngIf="f.nombre.errors.required" style="color:red">Dato requerido</div>
                        </div>
        
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-6">
                                <label>Cantidad de viviendas</label>
                                <input type="number" min="1" step="1" formControlName="cantidad_viviendas" class="form-control form-control-line">
                                <div *ngIf="f.cantidad_viviendas.invalid && (f.cantidad_viviendas.dirty || f.cantidad_viviendas.touched)">
                                    <div *ngIf="f.cantidad_viviendas.errors.required" style="color:red">Dato requerido</div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label>Estado</label>
                                <select class="form-control" formControlName="estado">
                                    <option value="1">Activado</option>
                                    <option value="0">Desactivado</option>
                                </select>
                                <div *ngIf="f.estado.invalid && (f.estado.dirty || f.estado.touched)">
                                    <div *ngIf="f.estado.errors.required" style="color:red">Dato requerido</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
            <div class="form-group text-center">
                <div class="btn btn-group">
                    <button [routerLink]="['/admin/condominios']" class="btn btn-secondary waves-effect waves-light"
                        type="button"><span class="btn-label"><i class="ti-close"></i></span>Cancelar</button>
                        <button (click)="uploadImage()" [disabled]='!file' class="btn btn-danger waves-effect waves-light"
                        type="button"><span class="btn-label"><i class="ti-save-alt"></i></span>Guardar Foto</button>
                    
                        <button [disabled]="false" (click)="guardar()"
                        class="btn btn-primary waves-effect waves-light" [disabled]="formData.invalid || subiendo_imagen" type="button"><span
                            class="btn-label"><i class="ti-check"></i></span>Guardar Condominio</button>
                </div>
            </div>
        </form>
    </div>
</div>