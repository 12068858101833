<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="d-flex">
                    <div class="align-self-center">
                        <h4 class="card-title m-t-10">Visitas Actuales</h4>
                    </div>
                </div>
                <br>
                <div class="table-responsive m-t-40">
                    <table id="table-area-comun" class="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>casa</th>
                                <th>Fecha Hora</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let c of Emergencias">
                                <td>{{ c.condominio_vivienda_id }}</td>
                                <td> 
                                    {{c.fechahora}}
                                </td>
                            </tr>
                           
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>