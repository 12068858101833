import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/services/alert.service';
import { DataService } from 'src/app/services/data.service';
import { PetitionService } from 'src/app/services/petition.service';
import { UploadFileService } from 'src/app/services/upload-file.service';
import {DatePipe} from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-user-detalles',
  templateUrl: './detalles.component.html',
})
export class UserAreaComunDetallesComponent implements OnInit {
  //VARIABLES
  form: any;
  public noticia_list: any[] = []
  public closeResult: string;
  public idCondo: any;
  file: any;
  strImage: any;
  Form: FormGroup;
  constructor(private petition_service: PetitionService,
    private alert_service: AlertService,
    private data_service: DataService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private upload_service: UploadFileService,
    private datePipe: DatePipe
    ){

    }

  ngOnInit(){

  }  
}