import { Injectable } from '@angular/core';

//SWAL
declare var swal: any;
//JQUERY
declare var $: any;

@Injectable({
  providedIn: 'root'
})


export class AlertService {

  constructor() { }

  // funcion que muestra las alertas
  show_swall(title: string, text: string, type: string) {
    swal(
      {
        title: title,
        text: text,
        type: type,
        confirmButtonColor: "primary",
        confirmButtonText: "Aceptar",
        closeOnConfirm: true
      });
  }
  show_save() {
    swal(
      {
        title: 'Guardando',
        text: 'Por favor, espere un minuto.',
        type: 'warning'
      });
  }
  
  show_no_info(){
    this.show_swall('Información no disponible', 'No se ha encontrado información para mostrar', 'warning')
  }
  show_error() {
    this.show_swall('Error', 'Ha ocurrido un error, inténtelo de nuevo más tarde.', 'error')
  }
}
