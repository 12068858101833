import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { PetitionService } from 'src/app/services/petition.service';
import { UploadFileService } from 'src/app/services/upload-file.service';

declare var $: any;

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html'
})
export class SigninVisitaComponent implements OnInit {
  form: any = false;
  file: any;
  strImage: any;


  constructor(
    private router: Router, 
    private alert_service: AlertService,
    private formBuilder: FormBuilder,
    private petition_service: PetitionService,
    private upload_service: UploadFileService) { }

  ngOnInit(): void {
    this.reset_form('https://res.cloudinary.com/dksstmpfu/image/upload/v1610211147/test/categoria/sshhivfnkjoqjo13cenf.jpg')
  }

  reset_form(img: string){
  this.form = this.formBuilder.group({
        nombre: ['', Validators.required],
        apellido: ['', Validators.required],
        usuarios_tipos_id: [ 5 ],
        fecha_nacimiento: ['', Validators.required],
        dpi: ['', Validators.required],
        email: ['', Validators.required],
        pass: ['', Validators.required],
        img: [ img],
        cel: ['', Validators.required],
        tel: ['', Validators.required],
      })
  }

  get f(){
    return this.form.controls;
  }

  crear_cuenta(){
    this.alert_service.show_save()
    var encontrado: boolean = false;
    this.petition_service.get_all('usuarios').subscribe(
      (data: any[]) => {
        data.some(user => {
          if(String(user.email) == String(this.form.value.email)) {
            encontrado = true
          } 
        });
      }
    )

    setTimeout(() => {
      if (encontrado) {
        this.alert_service.show_swall('Error', 'El correo ya fue registrado', 'error')
      } else {
        this.alert_service.show_swall('Registro exitoso', 'Sus datos se han guardado correctamente, puede acceder desde el login', 'success')
        
        this.petition_service.post('usuarios',this.form.value).subscribe(
          (data: any) => {
            this.reset_form('https://res.cloudinary.com/dksstmpfu/image/upload/v1610211147/test/categoria/sshhivfnkjoqjo13cenf.jpg')
            this.alert_service.show_swall('Registro exitoso', 'Sus datos se han guardado correctamente, puede acceder desde el login', 'success')
          }, 
          (err: any) => {
            this.alert_service.show_error();
          })
      }
    }, 2000);
    
    
  }

  seleccionImage(archivo: any) {

    if (!(archivo.target.files[0])) {
      return;
    }
    this.file = archivo.target.files[0]
    let reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onloadend = () => this.strImage = reader.result;
  }
  uploadImage() {
    this.upload_service.post(this.file, 'test/categoria')
      .then((res: any) => {
        this.form.get('img').setValue(res.secure_url);
        $('#modal-photo').modal('hide');
        this.alert_service.show_swall('Imagen subida', 'La imagen se subió con éxito', "success")
      })
      .catch((resp) => {
        this.alert_service.show_error();
      });
  }

}
