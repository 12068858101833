<div class="row" *ngIf="users_list">
    <div class="col-md-4" *ngFor="let user of users_list" >
        <div class="card">
            <div class="card-body little-profile text-center">
                <div class="pro-img m-t-20"><img src="{{ user.img }}" alt="user"></div>
                <h3 class="m-b-0">{{ user.nombre }} {{ user.apellido }}</h3>
            </div>
            <div class="text-center bg-light">
                <div class="row">
                    <div class="col-6  p-20 b-r">
                        <h5 class="m-b-0 font-medium">{{ user.email }}</h5><small>Email</small></div>
                    <div class="col-6  p-20">
                        <h5 class="m-b-0 font-medium">{{ user.tel }}</h5><small>Celular</small></div>
                </div>
            </div>
            <div class="card-body text-center">
                <div class="btn-group">
                    <button type="button" class="m-t-10 m-b-20 waves-effect waves-dark btn btn-success btn-md btn-rounded dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Puesto
                    </button>
                    <div class="dropdown-menu" x-placement="bottom-start" style="position: absolute; transform: translate3d(0px, 36px, 0px); top: 0px; left: 0px; will-change: transform;">
                        <button type="button" class="dropdown-item" *ngFor="let p of puestos_list" (click)="agregar(user.id, p.id, user.nombre)" >Agregar como {{ p.nombre }}</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>