import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/services/alert.service';
import { PetitionService } from 'src/app/services/petition.service';

declare var $: any;
@Component({
  selector: 'app-admin-directivas',
  templateUrl: './admin-directivas.component.html'
})
export class AdminDirectivasComponent implements OnInit {

  /**
   * VARIABLES
   */
  public directivas_list: any[] = [];

  constructor(private petition_service: PetitionService,
    private alert_service: AlertService) {
    this.get_directivas()
   }

  ngOnInit(): void {
  }


  get_directivas(){
    this.directivas_list = [];

    this.petition_service.get_all('condominio_directiva').subscribe(
      (directivas: any[]) => {
        for (let i = 0; i < directivas.length; i++) {
          const dir = directivas[i];
          this.petition_service.get_all('condominio').subscribe(
            (condominios: any[]) => {
              
              for (let j = 0; j < condominios.length; j++) {
                const cond = condominios[j];
                
                if (cond.id == dir.id_condominio ) {
                  
                  let encontrado = false;
                  this.directivas_list.some(
                    (dir) => {
                      if (dir.id == cond.id) {
                        encontrado = true;
                      }
                    }
                  )
                  if (!encontrado) {
                    let data = {
                      id: dir.id_condominio,
                      img: cond.imagen,
                      condominio: cond.nombre,
                      direccion: cond.direccion
                    }
                    this.directivas_list.push(data);
                  }
                  

                  break;
                }  
              }
              
            },
            (err) => {

            } 
          )

        }

        setTimeout(() => {
          $('#tabla-directiva').DataTable();
        }, 3000);


      },
      (err: any) => {
        this.alert_service.show_no_info();
      }
    )
  }
}
