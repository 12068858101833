<section id="wrapper">
    <div class="login-register"  style="background-image:url(https://p4.wallpaperbetter.com/wallpaper/745/584/835/apartment-architecture-building-condo-wallpaper-preview.jpg);">
        <div class="login-box card">
            <div class="card-body" *ngIf="form">

                <form [formGroup]="form" class="form-horizontal form-material" id="loginform" action="index.html">
                    <h3 class="box-title m-b-20">Datos de Visitante</h3>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-6">
                                <input formControlName="nombre" class="form-control" type="text" required="" placeholder="Nombres">
                                <div *ngIf="f.nombre.invalid && (f.nombre.dirty || f.nombre.touched)">
                                    <div *ngIf="f.nombre.errors.required" style="color:red">Dato requerido</div> 
                                </div>
                            </div>
                            <div class="col-md-6">
                                <input formControlName="apellido" class="form-control" type="text" required="" placeholder="Apellidos">
                                <div *ngIf="f.apellido.invalid && (f.apellido.dirty || f.apellido.touched)">
                                    <div *ngIf="f.apellido.errors.required" style="color:red">Dato requerido</div> 
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="form-group ">
                        <div class="row">
                            <div class="col-md-6">
                                <input class="form-control" formControlName="email" type="text" required="" placeholder="Email">
                                <div *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)">
                                    <div *ngIf="f.email.errors.required" style="color:red">Dato requerido</div> 
                                </div>
                            </div>
                            <div class="col-md-6">
                                <input class="form-control" formControlName="pass" type="password" required="" placeholder="Contraseña">
                                <div *ngIf="f.pass.invalid && (f.pass.dirty || f.pass.touched)">
                                    <div *ngIf="f.pass.errors.required" style="color:red">Dato requerido</div> 
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="form-group ">
                        <div class="row">
                            <div class="col-md-6">
                                <input class="form-control" formControlName="cel" type="number" required="" placeholder="Celular">
                                <div *ngIf="f.cel.invalid && (f.cel.dirty || f.cel.touched)">
                                    <div *ngIf="f.cel.errors.required" style="color:red">Dato requerido</div> 
                                </div>
                            </div>
                            <div class="col-md-6">
                                <input class="form-control" formControlName="tel" type="number" required="" placeholder="Teléfono">
                                <div *ngIf="f.tel.invalid && (f.tel.dirty || f.tel.touched)">
                                    <div *ngIf="f.tel.errors.required" style="color:red">Dato requerido</div> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-6">
                                <label for="">F. nacimiento</label>
                                <input class="form-control" formControlName="fecha_nacimiento" type="date">
                                <div *ngIf="f.fecha_nacimiento.invalid && (f.fecha_nacimiento.dirty || f.fecha_nacimiento.touched)">
                                    <div *ngIf="f.fecha_nacimiento.errors.required" style="color:red">Dato requerido</div> 
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label for="">DPI</label>
                                <input class="form-control" formControlName="dpi" min="1" 
                                type="number">
                                <div *ngIf="f.dpi.invalid && (f.dpi.dirty || f.dpi.touched)">
                                    <div *ngIf="f.dpi.errors.required" style="color:red">Dato requerido</div> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group text-center">
                        <div class="col-xs-12">
                            <button class="btn btn-danger btn-rounded waves-effect waves-light" data-toggle="modal"
                                data-target="#modal-photo">
                                <span><i class="fa fa-camera"></i> Sube tu fotografía (opcional)</span>
                            </button>
                        </div>
                    </div>
                    <div class="form-group text-center p-b-20">
                        <div class="col-xs-12">
                            <button [disabled]="form.invalid" (click)="crear_cuenta()"  class="btn btn-info btn-lg btn-block btn-rounded text-uppercase waves-effect waves-light" type="submit">Crear Cuenta</button>
                        </div>
                    </div>
                    <div class="form-group m-b-0">
                        <div class="col-sm-12 text-center">
                            ¿Ya tienes una cuenta? <a [routerLink]="['/login']" routerLinkActive="router-link-active"  class="text-info m-l-5"><b>Iniciar Sesión</b></a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>

<div id="modal-photo" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
    style="display: none;" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Foto de perfil</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <form action="">
                    <div class="col-lg-12">
                        <div class="row el-element-overlay">
                            <div class="col-md-2">
                            </div>
                            <div class="col-md-8">
                                <div class="card" style="margin-bottom: 0px !important;">
                                    <div class="el-card-item" style="margin-bottom: 0px !important;">
                                        <div class="el-card-avatar el-overlay-1" style="margin-bottom: 0px !important;">
                                            <img *ngIf="!file"
                                                src="https://res.cloudinary.com/dksstmpfu/image/upload/v1610211147/test/categoria/sshhivfnkjoqjo13cenf.jpg"
                                                alt="area" />
                                            <img *ngIf="file" src="{{strImage}}" alt="Usuario" />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                            </div>
                        </div>

                    </div>
                    <br>
                    <div class="form-group text-center">
                        <div class="fileupload btn btn-danger btn-rounded waves-effect waves-light"><span><i
                                    class="fa fa-camera"></i> Seleccionar Imagen</span>
                            <input type="file" accept="image/*" (change)="seleccionImage($event)" class="upload">
                        </div>
                    </div>
                </form>

            </div>
            <div class="modal-footer">
                
                <button data-dismiss="modal" class="btn btn-secondary waves-effect waves-light" type="button"><span
                        class="btn-label"><i class="ti-close"></i></span>Cerrar</button>
                <button [disabled]="!file" (click)="uploadImage()" class="btn btn-primary waves-effect waves-light" type="button"><span
                        class="btn-label"><i class="ti-save-alt"></i></span>Guardar Foto</button>
            </div>
        </div>
    </div>
</div>