<div class="card">
    <div class="card-body" *ngIf="formData">
        <h4 class="card-title">Formulario de Noticia</h4>
        <form [formGroup]="formData" class="form-material m-t-40">
            <div class="row">
                <div class="col-lg-5">
                    <div class="row el-element-overlay" style="margin-bottom: 0px !important;">
                        <div class="col-md-2">
                        </div>
                        <div class="col-md-8">
                            <div class="card" style="margin-bottom: 0px !important;">
                                <div class="el-card-item" style="margin-bottom: 0px !important;">
                                    <div class="el-card-avatar el-overlay-1"
                                        style="margin-bottom: 0px !important;">
                                        <img src="{{strImage}}" alt="area" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                        </div>
                    </div>
                    <div class="form-group text-center">
                        <label>Foto de la noticia (opcional)</label>
                        <br>
                        <div class="fileupload btn btn-danger btn-rounded waves-effect waves-light"><span><i
                            class="fa fa-camera"></i> Seleccionar Imagen</span>
                            <input type="file" accept="image/*"  (change)="seleccionImage($event)"  class="upload"> 
                        </div>

                    </div>
                </div>
                <div class="col-lg-7">
                    <div class="form-group">
                        <label for="direccion">Título</label>
                        <input class="form-control" formControlName="titulo">
                        <div *ngIf="f.titulo.invalid && (f.titulo.dirty || f.titulo.touched)">
                            <div *ngIf="f.titulo.errors.required" style="color:red">Dato requerido</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Descripción</label>
                        <textarea name="" id="" formControlName="descripcion" cols="5" class="form-control" rows="5"></textarea>
                        <div *ngIf="f.descripcion.invalid && (f.descripcion.dirty || f.descripcion.touched)">
                            <div *ngIf="f.descripcion.errors.required" style="color:red">Dato requerido</div>
                        </div>
        
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-6">
                                <label>Condominio</label>
                                
                                <select class="form-control" formControlName="condominio_id">
                                    <option *ngFor="let c of condominios_list" value="{{c.id}}"> {{c.nombre}}  </option>
                                </select>

                                <div *ngIf="f.condominio_id.invalid && (f.condominio_id.dirty || f.condominio_id.touched)">
                                    <div *ngIf="f.condominio_id.errors.required" style="color:red">Dato requerido</div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label>Estado</label>
                                <select class="form-control" formControlName="estado">
                                    <option value="1">Activado</option>
                                    <option value="0">Desactivado</option>
                                </select>
                                <div *ngIf="f.estado.invalid && (f.estado.dirty || f.estado.touched)">
                                    <div *ngIf="f.estado.errors.required" style="color:red">Dato requerido</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
            <div class="form-group text-center">
                <div class="btn btn-group">
                    <button [routerLink]="['/admin/noticias']" class="btn btn-secondary waves-effect waves-light"
                        type="button"><span class="btn-label"><i class="ti-close"></i></span>Cancelar</button>
                        <button (click)="uploadImage()" [disabled]='!file' class="btn btn-danger waves-effect waves-light"
                        type="button"><span class="btn-label"><i class="ti-save-alt"></i></span>Guardar Foto</button>
                    
                        <button [disabled]="false" (click)="guardar()"
                        class="btn btn-primary waves-effect waves-light" [disabled]="formData.invalid || subiendo_imagen" type="button"><span
                            class="btn-label"><i class="ti-check"></i></span>Guardar Noticia</button>
                </div>
            </div>
        </form>
    </div>
</div>