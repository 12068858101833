import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { PetitionService } from 'src/app/services/petition.service';
import { UploadFileService } from 'src/app/services/upload-file.service';

@Component({
  selector: 'app-formulario-noticia',
  templateUrl: './formulario-noticia.component.html'
})
export class FormularioNoticiaComponent implements OnInit {
  /**
   * VARIABLES
   */
  public condominios_list: any[] = [];
  /**
   * EDIICION DE CONDOMINIO
   * 
   */
  private estoy_edit: boolean = false;
  private noticia_id: number = 0;
  /**
   * FORM DATA
   */
  public formData:any;

  /**
   * FILE IMG
   * 
   */
  private strImage: any;
  private file: any;
  private subiendo_imagen: boolean = false


  constructor(private formBuilder: FormBuilder,
    private activroute: ActivatedRoute,
    private petition_service: PetitionService,
    private alert_service: AlertService,
    private upload_service: UploadFileService) {
      this.initialize_data();
     }

  ngOnInit(): void {
  }


  initialize_data(){

    //SE TRAEN TODOS LOS REGISTROS DE viviendas_usuario
    this.petition_service.get_all('condominio').subscribe(
      (data) => {
        this.condominios_list = data;
      }
    )

    this.activroute.params.subscribe( params => {
      if (params['id'] == 'nueva') {
        this.estoy_edit = false;
        this.reset_form('','', 'https://p4.wallpaperbetter.com/wallpaper/241/58/541/apartment-condo-condominium-design-wallpaper-preview.jpg', '','0',1, +localStorage.getItem('id'));
      } else {
        this.estoy_edit = true;
        this.noticia_id = params['id']
        this.petition_service.get_by_id('condominio_noticias', this.noticia_id).subscribe(
          (data: any) => {
            this.reset_form(data.titulo, data.descripcion, data.img, data.viviendas_usuario_id, data.condominio_id, data.estado, data.usuarios_id);
          });
      }
    })
  }
  reset_form(n: string, d: string, img: string, v: any, c: any, est: any, user: number){
    this.strImage = img
    this.formData = this.formBuilder.group({
      titulo: [n, Validators.required],
      descripcion: [d, Validators.required],
      img: [ img ],
      viviendas_usuario_id: ['0'],
      condominio_id: [c, Validators.required],
      estado: [ est, Validators.required ],
      usuarios_id: [ user ],
      fechahora: [ (new Date().toISOString().split("T"))[0] ]
    })
  }

  get f(){
    return this.formData.controls;
  }
  
  
  //CARGAR IMAGEN
  seleccionImage(archivo: any) {

    if (!(archivo.target.files[0])) {
      return;
    }
    this.file = archivo.target.files[0]
    let reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onloadend = () => this.strImage = reader.result;
  }
  uploadImage() {
    this.alert_service.show_save()
    this.subiendo_imagen = true;
    this.upload_service.post(this.file, 'test/categoria')
      .then((res: any) => {
        this.alert_service.show_swall('Imagen subida', 'La imagen se subió con éxito', "success")
        this.formData.get('img').setValue(res.secure_url);
        this.subiendo_imagen = false;
        this.file = null;
      })
      .catch((resp) => {
        this.alert_service.show_error()
        this.subiendo_imagen = false;
      });
  }


  guardar(){
    this.alert_service.show_save();
    if (!this.estoy_edit) {
      this.petition_service.post('condominio_noticias',this.formData.value).subscribe(
        (data) => {
          this.initialize_data();
          this.alert_service.show_swall('Noticia agregada', 'Los datos de la noticia se guardaron correctamente', 'success');
        }, 
        (err) => {
          this.alert_service.show_error();
        })
    } else {
      this.petition_service.put('condominio_noticias', this.formData.value, this.noticia_id).subscribe(
        (data) => {
          this.alert_service.show_swall('Noticia editada', 'Los datos de la noticia se guardaron correctamente', 'success');  
          this.initialize_data();
        }, 
        (err) => {
          this.alert_service.show_error()
        })
    }
  }

}
