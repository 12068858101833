import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/services/alert.service';
import { DataService } from 'src/app/services/data.service';
import { PetitionService } from 'src/app/services/petition.service';
import { UploadFileService } from 'src/app/services/upload-file.service';
import {DatePipe} from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-user-ObjetosPerdidos',
  templateUrl: './ObjetosPerdidos.component.html',
})
export class ObjetosPerdidosComponent implements OnInit {
  //VARIABLES
  //Comprender mejor como funciona el apartado de propuestas de proyectos
  form: any;
  public noticia_list: any[] = []
  public closeResult: string;
  public idCondo: any;
  ObjetosPerdidos: any;
  file: any;
  strImage: any;
  Form: FormGroup;
  constructor(private petition_service: PetitionService,
    private alert_service: AlertService,
    private data_service: DataService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private upload_service: UploadFileService,
    private datePipe: DatePipe
    ){
      this.idCondo = (JSON.parse(localStorage.getItem('micondo'))).id
      this.getObjetosPerdidos(this.idCondo);
      this.Form = this.fb.group({
        Titulo: [ '', Validators.compose([ Validators.required ])  ],
        Descripcion: [ '', Validators.compose([ Validators.required ])  ],
        img: [ '', Validators.compose([ Validators.required ])  ],
        nocontacto: [ '', Validators.compose([ Validators.required ])  ],
        recompensa: [ '', Validators.compose([ Validators.required ])  ]
      })
    }
    GenerarObjetoPerdido(){
      var date = new Date();
      let datenow = this.datePipe.transform(date,"yyyy-MM-dd hh:mm:ss")
      let idCondo = (JSON.parse(localStorage.getItem('micondo'))).id;
      let usuario_id = (JSON.parse(localStorage.getItem('micondo'))).usuario_id;
      let viviendas_usuario_id = (JSON.parse(localStorage.getItem('mivivienda'))).id;
      let titulo = this.Form.controls["Titulo"].value;
      let descripcion = this.Form.controls["Descripcion"].value;
      let recompensa = this.Form.controls["recompensa"].value;
      let nocontacto = this.Form.controls["nocontacto"].value;
      let formulario = {
        "titulo": titulo,
          "descripcion": descripcion,
          "img": this.Imagen,
          "condominio_viviendas_id": viviendas_usuario_id,
          "condominio_id": idCondo,
          "estado": "1",
          "fecha": datenow,
          "usuario_id": usuario_id,
		    "recompensa": recompensa,
		    "whatsapp": nocontacto
      }
      console.log(formulario)
      this.data_service.ObjetosPerdidosAdd(formulario)
      .subscribe((res)=>{
        this.alert_service.show_swall('Objeto perdido generado', 'A la espera de revisión del admin', "success")
        console.log(res)
      }, (error) => {
        this.alert_service.show_swall('Error', 'Por favor intentelo más tarde', "error")
        console.log(error)
      })
    }
  getObjetosPerdidos(id){
    this.data_service.ObjetosPerdidosGetAll(id)
    .subscribe((res)=>{

      this.ObjetosPerdidos = res
    }, (error) => {
      console.log(error)
    })
  }
  openModal(content1) {
    this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
    private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
  seleccionImage(archivo: any) {

    if (!(archivo.target.files[0])) {
      return;
    }
    this.file = archivo.target.files[0]
    let reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onloadend = () => this.strImage = reader.result;
    this.uploadImage();
  }
  Imagen: any;
  uploadImage() {
    this.upload_service.post(this.file, 'test/categoria')
      .then((res: any) => {
        this.Imagen = res.url;
        this.alert_service.show_swall('Imagen subida', 'La imagen se subió con éxito', "success")
      })
      .catch((resp) => {
        this.alert_service.show_error();
      });
  }
  ngOnInit(){

  }  
}