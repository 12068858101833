import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { USER_ROUTES } from './user.routes';
import { UserNoticiaComponent } from './user-noticia/user-noticia.component';
import { UserHomeComponent } from './user-home/user-home.component';
import { CondominiosComponent } from './user-condominios/condominios.component';
import { MembresiasComponent } from './user-membresias/membresias.component';
import { PerfilComponent } from './user-perfil/perfil.component';
import { TipsComponent } from './user-tips/tips.component';
import { tutorialesComponent } from './user-tutoriales/tutoriales.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UserAreaComunComponent } from './user-areacomun/areacomun.component';
import { UserAreaComunDetallesComponent } from './user-areacomun/detalles-areacomun/detalles.component';
import { ObjetosPerdidosComponent } from './user-objetosPerdidos/ObjetosPerdidos.component';
import { UserProyectosComponent } from './user-proyectos/proyectos.component';
import { UserEmergenciasComponent } from './user-emergencias/emergencias.component';
import { UserVisitasComponent } from './user-visitas/visitas.component';

@NgModule({
    declarations: [
    UserNoticiaComponent,
    UserHomeComponent,
    CondominiosComponent,
    MembresiasComponent,
    PerfilComponent,
    TipsComponent,
    tutorialesComponent,
    UserAreaComunComponent,
    UserAreaComunDetallesComponent,
    ObjetosPerdidosComponent,
    UserProyectosComponent,
    UserEmergenciasComponent,
    UserVisitasComponent,
    UserProyectosComponent
  ],
    exports: [
    ],
    imports: [
      FormsModule, 
      ReactiveFormsModule,
      CommonModule,
      RouterModule,
      NgbModule,
	    FormsModule,
	    ReactiveFormsModule,
	    CommonModule,
      USER_ROUTES,
      
    ], 
    providers: [
      FormBuilder,
      DatePipe
    ]
  })
  export class UserModule { }