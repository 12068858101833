import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  //VARIABLES
  loginForm: any;
  error: boolean = false   // sirve para mostrar el texto en la vista en caso de que haya error (ngif)
  mensaje: string = ""    //sirve para mostrar el error de autenticacion
  constructor(private formBuilder: FormBuilder,
    private authService: AuthService,
    private alert_service: AlertService,
//    private userService: UserService,
    private router: Router) {
      this.inicializar()
     }

  ngOnInit(): void {
    
  }

  inicializar(){
// Inicializa el formulario
  this.loginForm = this.formBuilder.group({
    email: ['', Validators.required],
    password: ['', Validators.required]
  });
  }
  get f() { // Retorna los controles del formulario
    // que contiene la informacion para validar 
    return this.loginForm.controls;
  }

  login_with_email() {

    this.authService.login(this.f.email.value, this.f.password.value).pipe(first()).subscribe(
      
      (data: any[]) => {
        this.alert_service.show_swall('Logueado', 'Se ha logueado con exito', "success")
        if (data[0].usuarios_tipos_id == 3) {

          this.router.navigate(['/usuario/home']);
          //VISTA ADMIN
        } else if (data[0].usuarios_tipos_id == 2) {
          this.router.navigate(['/admin/home']);
        } else if (data[0].usuarios_tipos_id == 1) {
          this.router.navigate(['/admin/home']);
        }else if (data[0].usuarios_tipos_id == 4) {
          this.router.navigate(['/seguridad/home']);
        } 
      }, 
      (err) => {
        this.alert_service.show_swall('Error', 'Contraseña o correo incorrecto', "error")
        console.log(err)
      }
    
    );

  }


}
