<div class="row">
    <div class="col-lg-4 col-xlg-3 col-md-5" *ngFor="let c of areaComun">
        <div class="card" >
            <div class="card-body text-center" >
                <div class="m-t-30"> <img src="{{c.img}}" width="250" height="200">
                    <h4 class="card-title m-t-10">{{ c.titulo }}</h4>
                </div>
            </div>
            <div>
                <hr> 
            </div>
            <div class="card-body"> 
                    <small class="text-muted p-t-30 db">descripcion</small>
                    <h5> {{ c.descripcion }}</h5> 
                    <footer>
                        <button (click)="openModal(reservacion); getAreaComunPrecio(c)">Reservar</button>
                    </footer>
            </div>
        </div>
    </div>
</div>

<ng-template #reservacion let-modal>
    <div class="modal-header">
        Reservar área comun
    </div>
    <div class="modal-body">
        <h5>Precio de renta: Q{{AreaComunPrecio}} por cada {{AreaComunTiempo}} minutos</h5>
        <form [formGroup]="Form">
            <div class="form-group">
                <label>Fecha inicio</label>
                <input formControlName="fechaIni" type="date" class="form-control">
                <label>Fecha fin</label>
                <input formControlName="fechaFin" type="date" class="form-control">
                <label>Hora inicio</label> 
                <input formControlName="horaIni" type="time" class="form-control">
                <label>Hora fin</label>
                <input formControlName="horaFin" type="time" class="form-control">
                </div>
        </form>
    </div>
    <div class="modal-footer">
        <button (click)="reservarAreaComun()">Reservar área común</button>
    </div>
</ng-template>