<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="d-flex">
                    <div class="align-self-center">
                        <h4 class="card-title m-t-10">Casa Actuales</h4>
                    </div>
                    <div class="ml-auto">
                        <div class="input-group">
                            <button type="button" class="btn btn-sm btn-warning btn-create" data-toggle="modal"
                                data-target="#modal_agregar">
                                Nueva Casa
                            </button>

                        </div>
                    </div>
                </div>
                <br>
                <div class="table-responsive m-t-40">
                    <table id="table-vivienda" class="table table-bordered table-striped">
                        <thead>
                            <tr>

                                <th class="text-center">Número</th>
                                <th class="text-center">Descripción</th>
                                <th class="text-center">Condominio</th>
                                <th class="text-center">Dirección</th>
                                <th class="text-center">Opciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let c of casas_list" class="text-center">
                                <td>{{ c.numero }}</td>
                                <td>{{ c.descripcion }}</td>
                                <td>{{ c.condominio }}</td>
                                <td>{{ c.direccion }}</td>
                                <td>
                                    <div class="btn btn-group">
                                        <button (click)="data_to_delete(c.id, c.numero)" data-toggle="modal"
                                            data-target="#modal-eliminar" class="btn btn-danger"><i
                                                class="fa fa-trash-o"></i></button>
                                        <button type="button" data-toggle="modal"
                                        data-target="#modal_agregar" (click)="get_data_to_edit(c)"
                                            routerLinkActive="router-link-active" class="btn btn-secondary"> <i
                                                class="fa fa-edit"> </i></button>
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>



<div id="modal-eliminar" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
    aria-hidden="true" style="display: none;">
    <form class="modal-dialog" [formGroup]="formDataDelete">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="mySmallModalLabel">Eliminar casa</h4>
                <button type="button" (click)="casa_id = 0; casa_num = '';" class="close" data-dismiss="modal"
                    aria-hidden="true">×</button>
            </div>
            <div class="alert alert-warning" role="alert">
                <a href="#" class="alert-link">¡Cosas inesperadas sucederán si no lees esto!</a>
            </div>
            <div class="modal-body">
                <strong>Esta acción no se puede deshacer.</strong>
                <br>
                <p>Esto eliminará permanentemente la vivienda y todo lo relacionado
                    con ella dejará de ser accesible.</p>
                <p>Escriba "<strong *ngIf="casa_num">{{casa_num}}</strong>" para confirmar.</p>
                <div class="form-group">
                    <input type="text" class="form-control" id="recipient-name" formControlName="nombreConfirm"
                        name="nombreConfirm">
                    <div
                        *ngIf="formDataDelete.controls.nombreConfirm.invalid && (formDataDelete.controls.nombreConfirm.dirty || formDataDelete.controls.nombreConfirm.touched)">
                        <div class="alert alert-danger" role="alert"
                            *ngIf="formDataDelete.controls.nombreConfirm.errors.required">
                            El título es requerido
                        </div>
                    </div>
                    <div
                        *ngIf="!formDataDelete.controls.nombreConfirm.invalid && formDataDelete.controls.nombreConfirm.value != casa_num">
                        <div class="alert alert-danger" role="alert">
                            Los nombres no coinciden
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" (click)="casa_id = 0; casa_num = '';"
                    class="btn btn-secondary text-uppercase btn-rounded" data-dismiss="modal">Cerrar</button>
                <button type="submit" (click)="delete()"
                    [disabled]="formDataDelete.controls.nombreConfirm.value != casa_num"
                    class="btn btn-danger text-uppercase btn-rounded">Eliminar</button>
            </div>
        </div>
    </form>
</div>


<!-- ============================================================== -->
<!--                  MODAL RESPONSIVE AGREGAR                      -->
<!-- ============================================================== -->
<div id="modal_agregar" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"
    style="display: none;">
    <div *ngIf="formData">
        <form class="modal-dialog modal-lg" [formGroup]="formData">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="modal_agregarLabel1">Formulario de Vivienda</h4>
                    <button type="button" class="close" (click)="reset_form('','','')" data-dismiss="modal"
                        aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label for="vivienda_name" class="control-label">Número:</label>
                        <input type="number" min="0" formControlName="numero" class="form-control" id="vivienda_name1"
                            maxlength="10"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                        <div *ngIf="f.numero.invalid && (f.numero.dirty || f.numero.touched)">
                            <div *ngIf="f.numero.errors.required" style="color:red">Dato requerido</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="vivienda_descripcion" class="control-label">Descripción:</label>
                        <textarea cols="3" rows="3" class="form-control" formControlName="descripcion" maxlength="150"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            onkeypress="return (event.charCode != 34 && event.charCode != 39) "></textarea>
                        <div *ngIf="f.descripcion.invalid && (f.descripcion.dirty || f.descripcion.touched)">
                            <div *ngIf="f.descripcion.errors.required" style="color:red">Dato requerido
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="vivienda_sec" class="control-label">Condominio:</label>
                        <select formControlName="condominio_id" class="form-control" id="vivienda_sec">
                            <option selected disabled>Select</option>
                            <option *ngFor="let op of condominios_list" value="{{op.id}}">{{op.nombre}}
                            </option>
                        </select>
                        <div *ngIf="f.condominio_id.invalid && (f.condominio_id.dirty || f.condominio_id.touched)">
                            <div *ngIf="f.condominio_id.errors.required" style="color:red">Dato requerido
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="btn btn-group ">
                        <button (click)="reset_form('','','')"
                            class="btn btn-outline-secondary waves-effect waves-light" type="button"
                            data-dismiss="modal"><span class="btn-label"><i
                                    class="ti-close"></i></span>Cancelar</button>
                        <button (click)="save()" [disabled]="!formData.valid"
                            class="btn btn-warning waves-effect waves-light" type="button"><span class="btn-label"><i
                                    class="ti-save"></i></span>Guardar</button>
                    </div>

                </div>
            </div>
        </form>
    </div>
</div>
<!-- ============================================================== -->
<!--                  MODAL RESPONSIVE AGREGAR                      -->
<!-- ============================================================== -->