import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { PetitionService } from 'src/app/services/petition.service';

declare var $: any;

@Component({
  selector: 'app-admin-casas',
  templateUrl: './admin-casas.component.html'
})
export class AdminCasasComponent implements OnInit {
  //VARIABLES
  casas_list: any[] = [];
  condominios_list: any[] = [];
  casa_num: string = '';
  /**
   * FORM DATA
   */
  /**
   * EDICION
   */
  private estoy_edit: boolean = false;
  casa_id: number = 0;

  public formDataDelete: any;
  public formData: any;

  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private alert_service: AlertService,
    private petition_service:PetitionService) { 
      this.get_casas();
    }

  ngOnInit(): void {
    
  }

  reset_form(n: any, d: any, c: any){
    this.formData = this.formBuilder.group({
      numero: [ n, Validators.required],
      descripcion: [ d, Validators.required],
      condominio_id: [ c, Validators.required]
    })
    this.formDataDelete = this.formBuilder.group({
      nombreConfirm: ['', Validators.required]
    });
  }

  get f(){
    return this.formData.controls;
  }

  get_casas(){
    $('#modal_agregar').modal('hide');
    this.casas_list = [];
    this.estoy_edit = false;
    this.casa_id = 0;
    this.petition_service.get_all('condominio_viviendas').subscribe(
      (viviendas: any[]) => {

        viviendas.some(viv => {
          this.petition_service.get_all('condominio').subscribe(
            (condominios: any[]) => {
              this.condominios_list = condominios;
              condominios.some(condo => {
                if (condo.id == viv.condominio_id) {
                  
                  let data = {
                    'id': viv.id,
                    'numero' : viv.numero,
                    'descripcion': viv.descripcion,
                    'condominio' : condo.nombre,
                    'direccion': condo.direccion,
                    'condominio_id': viv.condominio_id
                  }
                  this.casas_list.push(data);
                }
              });
            });

            
        });
        setTimeout(() => {
          $('#table-vivienda').DataTable();
        }, 2500);              
      }
    )
    this.reset_form('','','');
  }


  data_to_delete(id: number, titulo: string) {
    this.casa_id = id;
    this.casa_num = titulo;
    this.formDataDelete.reset();
  }
  delete(){
    this.petition_service.delete('condominio_viviendas',this.casa_id).subscribe(
      (data: any) => {
        this.get_casas();
        this.alert_service.show_swall("Vivienda Eliminada", "Se ha eliminado la vivienda correctamente", "success")
        $('#modal-eliminar').modal('hide');
      }, (err) => {
        this.alert_service.show_error();
      }
    ) 
  }

  save(){
    this.alert_service.show_save();
    if (!this.estoy_edit) {
      this.petition_service.post('condominio_viviendas', this.formData.value).subscribe(
        (data) => {
          this.alert_service.show_swall('Vivienda Guardada', 'Los datos de vivienda se guardaron correctamente', 'success');
          this.get_casas();
        },
        (err) => {
          this.alert_service.show_error()
        }
      )
    } else {
      this.petition_service.put('condominio_viviendas',this.formData.value, this.casa_id).subscribe(
        (data) => {
          this.alert_service.show_swall('Vivienda Editada', 'Los datos de vivienda se editaron correctamente', 'success');
          this.get_casas();
        },
        (err) => {
          this.alert_service.show_error();
        }
      )
    }
    
  }


  get_data_to_edit(vivienda: any){
    this.casa_id = vivienda.id;
    this.estoy_edit = true;
    this.reset_form(vivienda.numero, vivienda.descripcion, vivienda.condominio_id);
    
  }
}
