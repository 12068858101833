<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="d-flex">
                    <div class="align-self-center">
                        <h4 *ngIf="ver_admins" class="card-title m-t-10">Administradores Actuales</h4>
                        <h4 *ngIf="!ver_admins" class="card-title m-t-10">Usuarios Actuales</h4>
                    </div>
                    <div class="ml-auto">
                        <div class="input-group">
                            <a *ngIf="ver_admins"  type="button"  class="btn btn-sm btn-warning btn-create" [routerLink]="['/admin/administradores/nuevo']" routerLinkActive="router-link-active" >
                                Nuevo Administrador
                            </a>
                            <a *ngIf="!ver_admins && !ver_seguridad"  type="button"  class="btn btn-sm btn-warning btn-create" [routerLink]="['/admin/usuarios/nuevo']" routerLinkActive="router-link-active" >
                                Nuevo Usuario
                            </a>
                            <a *ngIf="ver_seguridad"  type="button"  class="btn btn-sm btn-warning btn-create" [routerLink]="['/admin/segurida/nuevo']" routerLinkActive="router-link-active" >
                                Nuevo usuario seguridad
                            </a>
                        </div>
                    </div>
                </div>
                <br>
                <div class="table-responsive m-t-40">
                    <table id="tabla-usuarios" class="table table-bordered table-striped">
                        <thead>
                            <tr>

                                <th>Nombre Completo</th>
                                <th>F. Nacimineto</th>
                                <th>DPI</th>
                                <th>Email</th>
                                <th>Celular</th>
                                <th>Teléfono</th>
                                <th>Rol</th>
                                <!--<th>Opciones</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let c of usuarios_list">
                                <td>
                                    <a href="javascript:void(0)"><img src="{{ c.img }}" alt="user" height="40" width="40" class="img-circle"> {{ c.nombre }} {{ c.apellido }}</a>
                                </td>
                                <td>{{ c.fecha_nacimiento | date: 'shortDate' }}</td>
                                <td>{{ c.dpi }}</td>
                                <td>{{ c.email }}</td>
                                <td>{{ c.cel }}</td>
                                <td>{{ c.tel }}</td>
                                <td class="text-center">
                                    <span *ngIf="c.usuarios_tipos_id == 3" class="label label-primary">Cliente</span>
                                    <span *ngIf="c.usuarios_tipos_id == 2" class="label label-primary">Admin</span>
                                    <span *ngIf="c.usuarios_tipos_id == 4" class="label label-primary">Seguridad</span>

                                </td>
                                <!--
                                <td> 
                                    <div class="btn btn-group">
                                        <button (click)="data_to_delete(c.id, c.nombre)" data-toggle="modal" data-target="#modal-eliminar" class="btn btn-danger"><i class="fa fa-trash-o"></i></button>
                                    </div>
                                </td>
                                -->

                            </tr>
                           
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>



<div id="modal-eliminar" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="display: none;">
    <form class="modal-dialog" [formGroup]="formDataDelete">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="mySmallModalLabel">Eliminar usuario</h4>
                <button type="button" (click)="usuario_id = 0; usuario_name = '';" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="alert alert-warning" role="alert">
                <a href="#" class="alert-link">¡Cosas inesperadas sucederán si no lees esto!</a>
            </div>
            <div class="modal-body">
                <strong>Esta acción no se puede deshacer.</strong>
                <br>
                <p>Esto eliminará permanentemente el usuario y todo lo relacionado
                    con el dejará de ser accesible.</p>
                <p>Escriba "<strong *ngIf="usuario_name">{{usuario_name}}</strong>" para confirmar.</p>
                <div class="form-group">
                    <input type="text" class="form-control" id="recipient-name" formControlName="nombreConfirm"
                        name="nombreConfirm">
                    <div *ngIf="formDataDelete.controls.nombreConfirm.invalid && (formDataDelete.controls.nombreConfirm.dirty || formDataDelete.controls.nombreConfirm.touched)">
                        <div class="alert alert-danger" role="alert" *ngIf="formDataDelete.controls.nombreConfirm.errors.required">
                            El título es requerido
                        </div>
                    </div>
                    <div *ngIf="!formDataDelete.controls.nombreConfirm.invalid && formDataDelete.controls.nombreConfirm.value != usuario_name">
                        <div class="alert alert-danger" role="alert">
                            Los nombres no coinciden
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="modal-footer">
                <button type="button" (click)="usuario_id = 0; usuario_name = '';" class="btn btn-secondary text-uppercase btn-rounded"
                    data-dismiss="modal">Cerrar</button>
                <button type="submit" (click)="delete()" [disabled]="formDataDelete.controls.nombreConfirm.value != usuario_name"
                    class="btn btn-danger text-uppercase btn-rounded">Eliminar</button>
            </div>
        </div>
    </form>
</div>