
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { VentasHomeComponent } from './home/home.component';
import { FormularioMembresiaComponent } from './ventas-membresias/formulario-membresias/nueva-membresia.component';
import { VentasMembresiasComponent } from './ventas-membresias/membresias.component';
import { VENTAS_ROUTES } from './ventas.routes';



@NgModule({
    declarations: [
    VentasHomeComponent,
    FormularioMembresiaComponent,
    VentasMembresiasComponent
  ],
    exports: [
    ],
    imports: [
      FormsModule, 
      ReactiveFormsModule,
      CommonModule,
      RouterModule,
      VENTAS_ROUTES,
      
    ], 
    providers: [
      FormBuilder
    ]
  })
  export class VentasModule { }