import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { PetitionService } from 'src/app/services/petition.service';
import { UploadFileService } from 'src/app/services/upload-file.service';


declare var $: any;
declare var google:any;

@Component({
  selector: 'app-seguridad-emergencias',
  templateUrl: './emergencias.component.html'
})
export class SeguridadEmergenciasComponent implements OnInit {
  Form: FormGroup;
  public user_logged: any;
  public condominios_data: any;
  public api_error: any;
  public membresias_data: any;
  public membresiaID: any;
  FiletoUpload: any;
	file: File;
  strImage: any;
  closeResult: string;
  imgurl: any;
  public idCondo: any;
  constructor(
    private data_service: DataService,
    private petition_service: PetitionService,
    private alert_service: AlertService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private uploadFileService: UploadFileService
    ) {
      this.user_logged = JSON.parse(localStorage.getItem('currentUser'));
      this.data_service.CondominioSeguridad(this.user_logged.id)
    .subscribe((res) => {
      console.log(res)
      localStorage.setItem('Seguridad_data',JSON.stringify(res));
    }, (error) => {
      console.log(error)
    })
      this.idCondo = JSON.parse(localStorage.getItem('Seguridad_data'));
      this.getEmergencias();
   }
   Emergencias: any;
   getEmergencias(){
     this.data_service.ListadoEmergencias(this.idCondo.condominio_id)
     .subscribe((res)=>{
      console.log(res)
      this.Emergencias = res;
    }, (error) => {
      console.log(error)
    })
   }
   ngOnInit(){
    this.data_service.CondominioSeguridad(this.user_logged.id)
    .subscribe((res) => {
      console.log(res)
      localStorage.setItem('Seguridad_data',JSON.stringify(res));
    }, (error) => {
      console.log(error)
    })
}  
}