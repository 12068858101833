<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="d-flex">
                    <div class="align-self-center">
                        <h4 class="card-title m-t-10">Noticias Actuales</h4>
                    </div>
                </div>
                <div class="table-responsive m-t-40">
                    <table id="tabla-noticias" class="table table-bordered table-striped">
                        <thead>
                            <tr>

                                <th>Título</th>
                                <th class="text-center">Condominio</th>
                                <th class="text-center">Descripcion</th>
                                <th class="text-center">Estado</th>
                                <th class="text-center">Opciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let c of noticias_list" class="text-center">
                                <td>
                                    <a [routerLink]="['/admin/noticias', c.id]" routerLinkActive="router-link-active" ><img src="{{ c.img }}" alt="user" height="50" width="50" class="img-circle"> {{ c.titulo }}</a>
                                </td> 
                                <td>{{ c.condominio }}</td>
                                <td>{{c.descripcion}}</td>
                                <td class="text-center">
                                    <span *ngIf="c.estado == 0" class="label label-primary">Estado 0</span>
                                    <span *ngIf="c.estado == 1" class="label label-danger">Por validar</span>
                                    <span *ngIf="c.estado == 2" class="label label-primary">Validada</span>
                                    <span *ngIf="c.estado == 3" class="label label-primary">Oculta</span>
                                </td>
                                <td>
                                    <div class="btn btn-group">
                                        <div *ngIf="c.estado == 1">
                                            <button data-toggle="modal" class="btn btn-danger" (click)="UpdateEstado(c.id, 2)">Validar</button>
                                            <button data-toggle="modal" class="btn btn-secondary" (click)="UpdateEstado(c.id, 3)">Ocultar</button>
                                        </div>
                                        <div *ngIf="c.estado == 2">
                                            <button data-toggle="modal" class="btn btn-secondary" (click)="UpdateEstado(c.id, 3)">Ocultar</button>
                                        </div>
                                        <div *ngIf="c.estado == 3">
                                            <button data-toggle="modal" class="btn btn-danger" (click)="UpdateEstado(c.id, 2)">Validar</button>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>



<div id="modal-eliminar" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="display: none;">
    <form class="modal-dialog" [formGroup]="formDataDelete">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="mySmallModalLabel">Eliminar Condominio</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="alert alert-warning" role="alert">
                <a href="#" class="alert-link">¡Cosas inesperadas sucederán si no lees esto!</a>
            </div>
            <div class="modal-body">
                <strong>Esta acción no se puede deshacer.</strong>
                <br>
                <p>Esto eliminará permanentemente el condominio y todo lo relacionado
                    con el dejará de ser accesible.</p>
                <p>Escriba "<strong *ngIf="noticia_titulo">{{noticia_titulo}}</strong>" para confirmar.</p>
                <div class="form-group">
                    <input type="text" class="form-control" id="recipient-name" formControlName="nombreConfirm"
                        name="nombreConfirm">
                    <div *ngIf="formDataDelete.controls.nombreConfirm.invalid && (formDataDelete.controls.nombreConfirm.dirty || formDataDelete.controls.nombreConfirm.touched)">
                        <div class="alert alert-danger" role="alert" *ngIf="formDataDelete.controls.nombreConfirm.errors.required">
                            El título es requerido
                        </div>
                    </div>
                    <div *ngIf="!formDataDelete.controls.nombreConfirm.invalid && formDataDelete.controls.nombreConfirm.value != noticia_titulo">
                        <div class="alert alert-danger" role="alert">
                            Los nombres no coinciden
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="modal-footer">
                <button type="button"  class="btn btn-secondary text-uppercase btn-rounded"
                    data-dismiss="modal">Cerrar</button>
                <button type="submit" (click)="delete()" [disabled]="formDataDelete.controls.nombreConfirm.value != noticia_titulo"
                    class="btn btn-danger text-uppercase btn-rounded">Eliminar</button>
            </div>
        </div>
    </form>
</div>