import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/services/alert.service';
import { PetitionService } from 'src/app/services/petition.service';

declare var $: any;

@Component({
  selector: 'app-admin-reservas',
  templateUrl: './admin-reservas.component.html'
})
export class AdminReservasComponent implements OnInit {

  //VARIABLES
  reservas_list: any[] = [];

  constructor(
    private petition_service: PetitionService,
    private alert_service: AlertService) {
      this.get_reservas();
     }

  ngOnInit(): void {
  }

  get_reservas(){
    this.reservas_list = [];
    this.petition_service.get_all('condominio_area_comun_reserva').subscribe(
      (data: any[]) => {

        for (let i = 0; i < data.length; i++) {
          const reserva = data[i];
          console.log(reserva)
          this.petition_service.get_by_id('condominio', reserva.condominio_id).subscribe(
            (condominio: any) => {

              this.petition_service.get_by_id('usuarios', reserva.usuario_id).subscribe(
                (user: any) =>{

                  this.petition_service.get_by_id('condominio_area_comun', reserva.area_comun_id)
                  .subscribe(
                    (area: any) => {
                      reserva['condominio'] = condominio.nombre
                      reserva['user'] = user.nombre  +  ' ' + user.apellido
                      reserva['area'] = area.titulo
                      reserva['img'] = area.img
                      
                      this.reservas_list.push(reserva);
    
                    })

                });

            },
            (err) =>{
            });
        }
        setTimeout(() => {
          $('#tabla-reservas').DataTable();
        }, 3000);
      },
      (err) => {
        this.alert_service.show_no_info();
      }
    )
  }

  update_reserva(estado: number, id_reserva: number){
    this.petition_service.put('condominio_area_comun_reserva', {"estado": estado}, id_reserva).subscribe(
      (data: any) => {
        this.alert_service.show_swall('Reserva actualizada','Se ha cambiado el estado de la reserva','success');
        this.get_reservas();
      },
      (err: any) => {
        this.alert_service.show_error();  
      }
    )
  }

}
