<div class="row">
    <div class="col-lg-4 col-xlg-3 col-md-5" *ngFor="let c of tutoriales_data">
        <div class="card" >
            <div class="card-body text-center" >
                <div class="m-t-30"> <img src="{{c.imagen}}" class="img-circle" width="150" height="150">
                    <h4 class="card-title m-t-10">{{ c.titulo }}</h4>
                </div>
            </div>
            <div>
                <hr> 
            </div>
            <div class="card-body"> 
                    <small class="text-muted p-t-30 db">descripcion</small>
                    <h5> {{ c.descripcion }}</h5> 
            </div>
        </div>
    </div>
</div>