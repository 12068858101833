import { RouterModule, Routes } from "@angular/router";
import { ObjetosPerdidosComponent } from "../user/user-objetosPerdidos/ObjetosPerdidos.component";
import { TipsComponent } from "../user/user-tips/tips.component";
import { tutorialesComponent } from "../user/user-tutoriales/tutoriales.component";
import { AdminAreaComunComponent } from "./admin-area-comun/admin-area-comun.component";
import { FormularioAreaComponent } from "./admin-area-comun/formulario-area/formulario-area.component";
import { PrecioAreaComponent } from "./admin-areacomun-precios/acprecio.component";
import { AdminCasasComponent } from "./admin-casas/admin-casas.component";
import { AdminCondominiosComponent } from "./admin-condominios/admin-condominios.component";
import { FormularioCondominiosComponent } from "./admin-condominios/formulario-condominios/formulario-condominios.component";
import { AdminDirectivaMiembrosComponent } from "./admin-directivas/admin-directiva-miembros/admin-directiva-miembros.component";
import { AdminDirectivasComponent } from "./admin-directivas/admin-directivas.component";
import { AdminListaUsuariosComponent } from "./admin-lista-usuarios/admin-lista-usuarios.component";
import { AdminMembresiasComponent } from "./admin-membresias/membresias.component";
import { AdminNoticiaComponent } from "./admin-noticia/admin-noticia.component";
import { FormularioNoticiaComponent } from "./admin-noticia/formulario-noticia/formulario-noticia.component";
import { AdminObjetosPerdidosComponent } from "./admin-ObjetosPerdidos/objetosperdidos.component";
import { AdminProyectosComponent } from "./admin-proyectos/admin-proyectos.component";
import { FormularioProyectosComponent } from "./admin-proyectos/formulario-proyectos/formulario-proyectos.component";
import { AdminReservasComponent } from "./admin-reservas/admin-reservas.component";
import { AdminTipsComponent } from "./admin-tips/tips.component";
import { AdmintutorialesComponent } from "./admin-tutoriales/tutoriales.component";
import { AdminUsuariosComponent } from "./admin-usuarios/admin-usuarios.component";
import { FormularioUsuarioComponent } from "./admin-usuarios/formulario-usuario/formulario-usuario.component";
import { HomeComponent } from "./home/home.component";



const pagesRoutes: Routes = [
    /*ADMINISTRADORES Y USUARIOS */
    { path: 'administradores', component: AdminUsuariosComponent },
    { path: 'administradores/:id', component: FormularioUsuarioComponent },
    { path: 'usuarios', component: AdminUsuariosComponent },
    { path: 'segurida', component: AdminUsuariosComponent },
    { path: 'segurida/:id', component: FormularioUsuarioComponent },
    { path: 'usuarios/:id', component: FormularioUsuarioComponent },
    /* CONDOMINIOS */
    { path: 'condominios', component: AdminCondominiosComponent },
    { path: 'condominios/:id', component: FormularioCondominiosComponent },
    /* VIVIENDAS */
    { path: 'casas', component: AdminCasasComponent },
    // AREAS COMUNES
    { path: 'areas-comunes', component: AdminAreaComunComponent },
    { path: 'areas-comunes/:id', component: FormularioAreaComponent },
    { path: 'areas-comunes-precios', component: PrecioAreaComponent },
    //RESERVACIONES
    { path: 'reservaciones', component: AdminReservasComponent },
    //PROYECTOS    
    { path: 'proyectos', component: AdminProyectosComponent },
    { path: 'proyectos/:id', component: FormularioProyectosComponent },
    //DIRECTIVAS
    { path: 'directivas', component: AdminDirectivasComponent },
    { path: 'directivas/miembros/:id', component: AdminDirectivaMiembrosComponent },
    { path: 'directivas/miembros/agregar/:id', component: AdminListaUsuariosComponent },
    
    //NOTICIAS
    { path: 'noticias', component: AdminNoticiaComponent },
    { path: 'noticias/:id', component: FormularioNoticiaComponent },

    //TIPS
    { path: 'tips', component: AdminTipsComponent },


    //TUTORIALES
    { path: 'tutoriales', component: AdmintutorialesComponent },

    //TUTORIALES
    { path: 'membresias', component: AdminMembresiasComponent },


    { path: 'Objetos-Perdidos', component: AdminObjetosPerdidosComponent },

    { path: 'home', component: HomeComponent },
    { path: '', redirectTo: 'login', pathMatch: 'full' }


];


export const ADMIN_ROUTES = RouterModule.forChild(pagesRoutes);
