import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { DataService } from 'src/app/services/data.service';
import { PetitionService } from 'src/app/services/petition.service';
import { UploadFileService } from 'src/app/services/upload-file.service';

@Component({
  selector: 'app-formulario-usuario',
  templateUrl: './formulario-usuario.component.html'
})
export class FormularioUsuarioComponent implements OnInit {
  //VARIABLES
  private url_navigate: string = '/admin/usuarios'  
  /**
   * FORM DATA
   */
  public formData: any;
  public formDataSA: any;
  private ver_admins: boolean = false;
  private ver_seguridad: boolean = false;
  private SelecCondo: boolean = false;
  /**
   * IMAGEN 
   */
  private file: any;
  private strImage: any;
  private subiendo_imagen: boolean = false;


  public condominio_list: any[] = [];


  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private alert_service: AlertService,
    private petition_Service: PetitionService,
    private data_service: DataService,
    private upload_service: UploadFileService) {
        this.inicializar_form();
        this.petition_Service.get_all('condominio').subscribe(
          (data: any[]) => {
            this.condominio_list = data;
          })
     }

  ngOnInit(): void {
    
  }


  inicializar_form() {
    let tipo:any = '';
    if (this.router.url.includes('administradores')) {
      this.ver_admins = true;
      this.url_navigate = '/admin/administradores'
      tipo = 2 ;
    }
    if (this.router.url.includes('segurida')) {
      console.log("usuarios de seguridad")
      this.SelecCondo = true;
      this.ver_seguridad = true;
      this.url_navigate = '/admin/segurida'
      tipo = 4 ;
    }

    this.formData = this.formBuilder.group({
      nombre: ['', Validators.required],
      apellido: ['', Validators.required],
      usuarios_tipos_id: [tipo, Validators.required],
      fecha_nacimiento: ['', Validators.required],
      dpi: ['', Validators.required],
      email: ['', Validators.required],
      pass: ['', Validators.required],
      img: ['https://res.cloudinary.com/dksstmpfu/image/upload/v1610211147/test/categoria/sshhivfnkjoqjo13cenf.jpg'],
      cel: ['', Validators.required],
      tel: ['', Validators.required],
      condominio_id: [''],
    })
    this.formDataSA = this.formBuilder.group({
      nombre: ['', Validators.required],
      apellido: ['', Validators.required],
      usuarios_tipos_id: [tipo, Validators.required],
      fecha_nacimiento: ['', Validators.required],
      dpi: ['', Validators.required],
      email: ['', Validators.required],
      pass: ['', Validators.required],
      img: ['https://res.cloudinary.com/dksstmpfu/image/upload/v1610211147/test/categoria/sshhivfnkjoqjo13cenf.jpg'],
      cel: ['', Validators.required],
      tel: ['', Validators.required],
      condominio_id: ['', Validators.required],
    })
  }

  get f(){
    return this.formData.controls;
  }

  //CARGAR IMAGEN
  seleccionImage(archivo: any) {

    if (!(archivo.target.files[0])) {
      return;
    }
    this.file = archivo.target.files[0]
    let reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onloadend = () => this.strImage = reader.result;
  }
  uploadImage() {
    this.alert_service.show_save()
    this.subiendo_imagen = true;
    this.upload_service.post(this.file, 'test/categoria')
      .then((res: any) => {
        this.alert_service.show_swall('Imagen subida', 'La imagen se subió con éxito', "success")
        this.formData.get('img').setValue(res.secure_url);
        this.subiendo_imagen = false;
        this.file = null;
      })
      .catch((resp) => {
        console.log(resp)
        this.alert_service.show_error()
        this.subiendo_imagen = false;
      });
  }

  guardar(){
    this.alert_service.show_save()
    this.petition_Service.post('usuarios', this.formData.value).subscribe(
      (data) => {
        if (this.ver_admins) {
          this.alert_service.show_swall('Administrador creado', 'Los datos del administrador se guardaron con éxito', 'success')          
        } else  if (this.ver_seguridad) {
          this.alert_service.show_swall('Seguridad creado', 'Los datos de seguridad se guardaron con éxito', 'success')          
        }else {
          this.alert_service.show_swall('Usuario creado', 'Los datos del usuario se guardaron con éxito', 'success')            
        }
        this.inicializar_form();
      },
      (err) => {
        this.alert_service.show_error();
      }
    )
  }
  Usuarios: any;
  UsuariosGrado2(){
    let nombre = this.formData.controls["nombre"].value;
    let apellido = this.formData.controls["apellido"].value;
    let fecha_nacimiento = this.formData.controls["fecha_nacimiento"].value;
    let dpi = this.formData.controls["dpi"].value;
    let email = this.formData.controls["email"].value;
    let pass = this.formData.controls["pass"].value;
    let img = this.formData.controls["img"].value;
    let cel = this.formData.controls["cel"].value;
    let tel = this.formData.controls["tel"].value;
    let condominio_id = this.formData.controls["condominio_id"].value;
    let estado = 1;
    let data = {
      nombre: nombre,
      apellido: apellido,
      fecha_nacimiento: fecha_nacimiento,
      usuarios_tipos_id: 4,
      dpi: dpi,
      email: email,
      pass: pass,
      img: img,
      cel: cel,
      tel: tel,
      condominio_id: condominio_id,
      "estado": estado,
    }
    
    this.alert_service.show_save()
    this.data_service.AgregarSeguridad(data)
    .subscribe(
      (data) => {
        if (this.ver_admins) {
          this.alert_service.show_swall('Administrador creado', 'Los datos del administrador se guardaron con éxito', 'success')          
        } else  if (this.ver_seguridad) {
          this.alert_service.show_swall('Seguridad creado', 'Los datos de seguridad se guardaron con éxito', 'success')          
        }else {
          this.alert_service.show_swall('Usuario creado', 'Los datos del usuario se guardaron con éxito', 'success')            
        }
        this.inicializar_form();
      },
      (err) => {
        console.log(err)
        this.alert_service.show_error();
      }
    )
  }

  goToPage(){
    this.router.navigate([this.url_navigate])
  }
}
