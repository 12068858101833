<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="d-flex">
                    <div class="align-self-center">
                        <h4 class="card-title m-t-10">Áreas comunes Actuales</h4>
                    </div>
                    <div class="ml-auto">
                        <div class="input-group">
                            <a type="button"  class="btn btn-sm btn-warning btn-create" [routerLink]="['/admin/areas-comunes/nuevo']" routerLinkActive="router-link-active" >
                                Nueva Área
                            </a>
                        </div>
                    </div>
                </div>
                <br>
                <div class="table-responsive m-t-40">
                    <table id="table-area-comun" class="table table-bordered table-striped">
                        <thead>
                            <tr>

                                <th>Título</th>
                                <th>Descripción</th>
                                <th>Condominio</th>
                                <th>Dirección</th>
                                <th>Estado</th>
                                <th>Opciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let c of areas_list">
                                <td>
                                    <a href="javascript:void(0)"><img src="{{ c.img }}" alt="user" height="40" width="40" class="img-circle"> {{ c.titulo }}</a>
                                </td>
                                <td>{{ c.descripcion }}</td>
                                <td>{{ c.condominio }}</td>
                                <td>{{ c.direccion }}</td>
                                <td class="text-center">
                                    <span *ngIf="c.estado != 1" class="label label-danger">Desactivada</span>
                                    <span *ngIf="c.estado == 1" class="label label-primary">Activada</span>
                                </td>
                                <td> 
                                    <div class="btn btn-group">
                                        <button (click)="updateEstado(2, c.id)" class="btn btn-danger" *ngIf="c.estado == 1">
                                            Desactivar
                                        </button>
                                        <button (click)="updateEstado(1, c.id)" class="btn btn-secondary" *ngIf="c.estado != 1">
                                            Activar
                                        </button>
                                    </div>
                                </td>
                            </tr>
                           
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>



<div id="modal-eliminar" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="display: none;">
    <form class="modal-dialog" [formGroup]="formDataDelete">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="mySmallModalLabel">Eliminar Área común</h4>
                <button type="button" (click)="area_id = 0; area_name = '';" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="alert alert-warning" role="alert">
                <a href="#" class="alert-link">¡Cosas inesperadas sucederán si no lees esto!</a>
            </div>
            <div class="modal-body">
                <strong>Esta acción no se puede deshacer.</strong>
                <br>
                <p>Esto eliminará permanentemente el área común y todo lo relacionado
                    con ella dejará de ser accesible.</p>
                <p>Escriba "<strong *ngIf="area_name">{{area_name}}</strong>" para confirmar.</p>
                <div class="form-group">
                    <input type="text" class="form-control" id="recipient-name" formControlName="nombreConfirm"
                        name="nombreConfirm">
                    <div *ngIf="formDataDelete.controls.nombreConfirm.invalid && (formDataDelete.controls.nombreConfirm.dirty || formDataDelete.controls.nombreConfirm.touched)">
                        <div class="alert alert-danger" role="alert" *ngIf="formDataDelete.controls.nombreConfirm.errors.required">
                            Dato requerido
                        </div>
                    </div>
                    <div *ngIf="!formDataDelete.controls.nombreConfirm.invalid && formDataDelete.controls.nombreConfirm.value != area_name">
                        <div class="alert alert-danger" role="alert">
                            Los datos no coinciden
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="modal-footer">
                <button type="button" (click)="area_id = 0; area_name = '';" class="btn btn-secondary text-uppercase btn-rounded"
                    data-dismiss="modal">Cerrar</button>
                <button type="submit" (click)="delete()" [disabled]="formDataDelete.controls.nombreConfirm.value != area_name"
                    class="btn btn-danger text-uppercase btn-rounded">Eliminar</button>
            </div>
        </div>
    </form>
</div>