import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { PetitionService } from 'src/app/services/petition.service';

@Component({
  selector: 'app-admin-directiva-miembros',
  templateUrl: './admin-directiva-miembros.component.html'
})
export class AdminDirectivaMiembrosComponent implements OnInit {
  /**
   * VARIABLES 
   */
  public usuarios_list: any[] = [];
  public puestos_list: any[] = [];
  public cond_name: string = ''
  public condominio_id: number = 0;

  constructor(private activRoute: ActivatedRoute,
    private alert_service: AlertService,
    private petition_service: PetitionService) {
      this.get_data();
     }

  ngOnInit(): void {
  }

  get_data(){
    console.log("entre aqui")
    this.usuarios_list = [];
    this.activRoute.params.subscribe(param => {
      this.condominio_id = +param['id'];
      //SE TRAEN LAS DIRECTIVAS
      this.petition_service.get_all('condominio_directiva').subscribe(
        
        (directivas: any[]) => {

            for (let i = 0; i < directivas.length; i++) {
              const dir = directivas[i];
              //SE BUSCAN LOS USUARIOS PERTENCIENTES A LA
              //DIRECTIVA DEL CONDOMINIO QUE VIENE EN param['id']
              if (+dir.id_condominio == this.condominio_id) {
                this.cond_name = dir.nombre_condominio;
                this.usuarios_list.push(dir);
              }
            }

            if (this.usuarios_list.length == 0) {
              this.alert_service.show_no_info();
            }
        });
    });

    this.petition_service.get_all('condominio_puesto').subscribe(
      (data: any[]) => {
        this.puestos_list = data;
      
      }
    )
  }

  update_directiva(directiva_id: number, puesto_id: number, nombre: string, puesto: string){
    console.log(directiva_id)
    console.log(puesto_id);
   this.petition_service.put('condominio_directiva', { 'id': directiva_id, 'puesto': puesto_id  }, directiva_id).subscribe(
      (data: any) => {
        this.alert_service.show_swall('Puesto actualizado', "El usuario '" + nombre + "' ahora es '" + puesto + "'", 'success');
        this.get_data();
      },
      (err: any) =>{
        this.alert_service.show_error();
      }
    )
  }
  eliminar(directiva_id: number, nombre: string){
    this.petition_service.delete('condominio_directiva', directiva_id).subscribe(
      (data: any) => {
        this.alert_service.show_swall('Puesto Eliminado', "El usuario '" + nombre + "' ya no forma parte de la directiva", 'success');
        this.get_data();
      },
      (err: any) => {
        this.alert_service.show_error();
      }
    )
  }
}
