<style>
    .custom-badge {
    position: relative;
    top: -7px;
    right: -13px;
    font-size: .7em;
  }
  .container-nav {
    max-width: 1140px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  
</style>
<header class="topbar" *ngIf="user_logged">
    
    <nav  class="navbar top-navbar navbar-light text-light py-3   navbar-expand-md">
        <div class="container-nav topheader">
            <div class="d-flex justify-content-center">
                <div >
                    <img src="assets/images/logo.png" width="auto" height="50px" alt="">
                </div>
            </div>
        </div>
    </nav >
    <div class="bottom sticky-top shadow-sm" style="background-color: #242a33;">
        <nav class="container-nav" >
            <ul  class="nav nav-bottom nav-fill nav-list">
                
                <li  class="nav-item" *ngIf="userID == 3">
                    <a class="nav-link text-light active" [routerLink]="['/usuario/home']" routerLinkActive="router-link-active" ><i class="ti-home"></i><span class="d-none d-sm-inline-block d-md-inline-block">Home</span></a>
                </li>
                <li   class="nav-item" *ngIf="userID == 3">
                    <a class="nav-link text-light active" [routerLink]="['/usuario/condominios']" routerLinkActive="router-link-active" ><i class="ti-calendar"></i> <span class="d-none d-sm-inline-block d-md-inline-block">Condominios</span> </a>                
                </li>
                <li class="nav-item dropdown" *ngIf="userID == 3">
                    <a class="nav-link text-light dropdown-toggle" href="#" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="ti ti-user"></i> <span class="d-none d-sm-inline-block d-md-inline-block"> Mi condominio </span>
                    </a>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <ul class="dropdown-user">
                            <li><a [routerLink]="['/usuario/noticias']" routerLinkActive="router-link-active">Noticias</a></li>
                        <li><a [routerLink]="['/usuario/areacomun']" routerLinkActive="router-link-active">Areas Comun </a></li>
                        <li><a [routerLink]="['/usuario/objetosperdidos']" routerLinkActive="router-link-active">Objetos Perdidos</a></li>
                        <li><a [routerLink]="['/usuario/proyectos']" routerLinkActive="router-link-active">Proyectos</a></li>
                        </ul>
                      </div>
                </li>
                
                <li class="nav-item dropdown" *ngIf="userID == 3">
                    <a class="nav-link text-light dropdown-toggle" href="#" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="ti ti-user"></i> <span class="d-none d-sm-inline-block d-md-inline-block"> Visitas </span>
                    </a>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <ul class="dropdown-user">
                            <li>
                                <a [routerLink]="['/usuario/visitas']" routerLinkActive="router-link-active" >Nueva Visita </a>                
                            </li>
                        </ul>
                      </div>
                </li>
                <li class="nav-item dropdown" *ngIf="userID == 1">
                    <a class="nav-link text-light dropdown-toggle" href="#" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="ti ti-user"></i> <span class="d-none d-sm-inline-block d-md-inline-block"> Usuarios </span>
                    </a>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <ul class="dropdown-user">
                            <li><a [routerLink]="['/admin/administradores']" routerLinkActive="router-link-active"> Administradores </a></li>
                        <li><a [routerLink]="['/admin/usuarios']" routerLinkActive="router-link-active">Clientes </a></li>
                        <li><a [routerLink]="['/admin/segurida']" routerLinkActive="router-link-active">Seguridad </a></li>
                        </ul>
                      </div>
                </li>
                <li class="nav-item dropdown" *ngIf="userID == 2">
                    <a class="nav-link text-light dropdown-toggle" href="#" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="ti ti-user"></i> <span class="d-none d-sm-inline-block d-md-inline-block"> Usuarios </span>
                    </a>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <ul class="dropdown-user">
                        <li><a [routerLink]="['/admin/usuarios']" routerLinkActive="router-link-active">Clientes </a></li>
                        <li><a [routerLink]="['/admin/segurida']" routerLinkActive="router-link-active">Seguridad </a></li>
                        </ul>
                      </div>
                </li>
                <li class="nav-item dropdown" *ngIf="userID == 1">
                    <a class="nav-link text-light dropdown-toggle" href="#" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="ti ti-user"></i> <span class="d-none d-sm-inline-block d-md-inline-block"> Condominios </span>
                    </a>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <ul class="dropdown-user">
                            <li><a [routerLink]="['/admin/condominios']" routerLinkActive="router-link-active">Ver Condominios </a></li>
                            <li><a [routerLink]="['/admin/condominios/nuevo']" routerLinkActive="router-link-active">Nuevo Condominio </a></li>
                            <li><a [routerLink]="['/admin/casas']" routerLinkActive="router-link-active">Ver Viviendas </a></li>
                        </ul>
                      </div>
                </li>
                <li class="nav-item dropdown" *ngIf="userID !== 3">
                    <a class="nav-link text-light dropdown-toggle" href="#" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="ti ti-user"></i> <span class="d-none d-sm-inline-block d-md-inline-block"> Membresias </span>
                    </a>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <ul class="dropdown-user">
                            <li *ngIf="userID !== 1"><a [routerLink]="['/admin/membresias']" routerLinkActive="router-link-active">Ver membresias </a></li>
                            <li *ngIf="userID == 1"><a [routerLink]="['/ventas/membresias']" routerLinkActive="router-link-active">Membresias </a></li>
                        </ul>
                      </div>
                </li>
                <li class="nav-item dropdown" *ngIf="userID !== 3 && userID !== 4">
                    <a class="nav-link text-light dropdown-toggle" href="#" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="ti ti-user"></i> <span class="d-none d-sm-inline-block d-md-inline-block"> Areas comun </span>
                    </a>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <ul class="dropdown-user">
                            <li><a [routerLink]="['/admin/areas-comunes']" routerLinkActive="router-link-active" >Ver Áreas</a></li>
                        <li><a [routerLink]="['/admin/areas-comunes/nuevo']" routerLinkActive="router-link-active" >Nueva Área</a></li>
                        <li><a [routerLink]="['/admin/areas-comunes-precios']" routerLinkActive="router-link-active" >Nueva Área Precio</a></li>
                        <li><a [routerLink]="['/admin/reservaciones']" routerLinkActive="router-link-active" >Ver Reservas</a></li>
                        </ul>
                      </div>
                </li>
                <li class="nav-item dropdown" *ngIf="userID == 2">
                    <a class="nav-link text-light dropdown-toggle" href="#" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="ti ti-user"></i> <span class="d-none d-sm-inline-block d-md-inline-block"> Proyectos </span>
                    </a>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <ul class="dropdown-user">
                            <li><a [routerLink]="['/admin/proyectos']" routerLinkActive="router-link-active" >Ver Proyectos</a></li>
                        <li><a [routerLink]="['/admin/proyectos/nuevo']" routerLinkActive="router-link-active" >Nuevo Proyecto</a></li>
                        </ul>
                      </div>
                </li>
                <li class="nav-item dropdown" *ngIf="userID == 2">
                    <a class="nav-link text-light dropdown-toggle" href="#" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="ti ti-user"></i> <span class="d-none d-sm-inline-block d-md-inline-block"> Noticias </span>
                    </a>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <ul class="dropdown-user">
                            <li><a [routerLink]="['/admin/Objetos-Perdidos']" routerLinkActive="router-link-active" >validar Objetos Perdidos</a></li>
                            <li><a [routerLink]="['/admin/noticias']" routerLinkActive="router-link-active" >validar Noticias</a></li>
                        </ul>
                      </div>
                </li>
                <li class="nav-item dropdown" *ngIf="userID == 2">
                    <a class="nav-link text-light dropdown-toggle" href="#" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="ti ti-user"></i> <span class="d-none d-sm-inline-block d-md-inline-block"> Ayuda </span>
                    </a>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <ul class="dropdown-user">
                            <li><a [routerLink]="['/admin/tips']" routerLinkActive="router-link-active" >Ver Tips</a></li>
                            <li><a [routerLink]="['/admin/tutoriales']" routerLinkActive="router-link-active" >ver Tutoriales</a></li>
                        </ul>
                      </div>
                </li>
                <li class="nav-item dropdown" *ngIf="userID == 3">
                    <a class="nav-link text-light dropdown-toggle" href="#" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="ti ti-user"></i> <span class="d-none d-sm-inline-block d-md-inline-block"> Ayuda </span>
                    </a>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <ul class="dropdown-user">
                            <li>
                                <a [routerLink]="['/usuario/tips']" routerLinkActive="router-link-active" >Tips </a>                
                            </li>
                            <li>
                                <a [routerLink]="['/usuario/tutoriales']" routerLinkActive="router-link-active" >Tutoriales</a>                
                            </li>
                        </ul>
                      </div>
                </li>
                <li class="nav-item dropdown" *ngIf="userID == 4">
                    <a class="nav-link text-light dropdown-toggle" href="#" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="ti ti-user"></i> <span class="d-none d-sm-inline-block d-md-inline-block"> Seguridad </span>
                    </a>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <ul class="dropdown-user">
                            <li><a [routerLink]="['/seguridad/emergencias']" routerLinkActive="router-link-active" >Emergencias</a></li>
                        <li><a [routerLink]="['/seguridad/visitas']" routerLinkActive="router-link-active" >Visitas</a></li>
                        </ul>
                      </div>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link text-light dropdown-toggle" href="#" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="ti ti-user"></i> <span class="d-none d-sm-inline-block d-md-inline-block"> Perfil</span>
                    </a>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <ul class="dropdown-user">
                            <li>
                                <div class="dw-user-box" *ngIf="user_logged != null">
                                    <div class="u-img"><img src="{{ user_logged.img ==''||user_logged.img ==null?'assets/images/default-user.jpg':user_logged.img}} " alt="user"></div>
                                    <div class="u-text">
                                        <h4>{{ user_logged.nombre }} {{ user_logged.apellido }}</h4>
                                        <p class="text-muted"> {{ user_logged.email }} </p></div>
                                </div>
                            </li>
                            <li role="separator" class="divider"></li>
                            <li *ngIf="userID == 3"><a [routerLink]="['/usuario/mi-perfil']" routerLinkActive="router-link-active" ><i class="ti-user"></i> Mi Perfil</a></li>
                            <!--<li><a [routerLink]="['/buyapp']" routerLinkActive="router-link-active" ><i class="ti-wallet"></i> Comprar App</a></li>-->
                            <li role="separator" class="divider"></li>
                            <li><a (click)="logout()" [routerLink]="['/login']" routerLinkActive="router-link-active"  ><i class="fa fa-power-off"></i> Logout</a></li>
                        </ul>
                      </div>
                </li>
            </ul>
        </nav>
    </div>
</header>