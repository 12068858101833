import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { PetitionService } from 'src/app/services/petition.service';
import { UploadFileService } from 'src/app/services/upload-file.service';


declare var $: any;
declare var google:any;

@Component({
  selector: 'app-user-condominio',
  templateUrl: './condominios.component.html'
})
export class CondominiosComponent implements OnInit {
  Form: FormGroup;
  public user_logged: any;
  public condominios_data: any;
  public api_error: any;
  public membresias_data: any;
  public membresiaID: any;
  FiletoUpload: any;
	file: File;
  strImage: any;
  closeResult: string;
  imgurl: any;
  constructor(
    private data_service: DataService,
    private petition_service: PetitionService,
    private alert_service: AlertService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private uploadFileService: UploadFileService
    ) {
      this.user_logged = JSON.parse(localStorage.getItem('currentUser'));
      this.GetMembresias();
      this.GetCondominios();
      this.Form = this.fb.group({
        Nombre: [ '', Validators.compose([ Validators.required ])  ],
        Descripcion: [ '', Validators.compose([ Validators.required ])  ],
        Dirección: [ '', Validators.compose([ Validators.required ])  ],
        Cantidad_viviendas: [ '', Validators.compose([ Validators.required ])  ],
        img: [ '', Validators.compose([ Validators.required ])  ],
    })
   }
   GetCondominios(){
     this.data_service.CondominiosGetAll()
     .subscribe((res)=>{
       console.log(res)
      this.condominios_data = res;
     }, (error) => {
       this.api_error = error;
       console.log(this.api_error)
     })
   }
   open1(content1) {
    this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
  GetMembresias(){
    this.data_service.MembresiasGetAll()
    .subscribe( (res) => {
      console.log(res)
      this.membresias_data = res;
    }, (error) => {
      
      this.api_error = error
      console.log(this.api_error)
    })
  }
  seleccionImage( archivo: File ) {
		if ( !archivo ) {
		  this.file = null;
		  return;
		}
		if ( archivo.type.indexOf('image') < 0 ) {
		  
		  console.error("no es imagen")
		  this.file = null;
		  return;
		}
		this.file = archivo;
		let reader = new FileReader();
		console.log(reader)
		let urlImagenTemp = reader.readAsDataURL( archivo );
		console.log(reader.result)
		console.log(urlImagenTemp)
		reader.onloadend = () => this.strImage = reader.result;
    this.uploadImage();
	  }
	Imagen: any
	  uploadImage() {
		console.log("IMAGE UPLOAD")

		this.uploadFileService.subirArchivo( this.file, 'ZFIT/Habitos' )
		.then((res) => {
      this.imgurl = res;
		  console.log(this.imgurl.url )
		})
		.catch((resp) => {
		  console.log( resp );
		});
	  }
	selectedFile = null
	imagenSubir: File;
	onFileSelected(event: File	){
		if(!event){
			this.imagenSubir = null
			return;
		}
		this.imagenSubir = event;
	}
  SaveMembresiaID(id){
    this.membresiaID = id;
    console.log(id)
  }
  GuardarCondominio(){
    
  }
    ngOnInit(){
      
    }
}