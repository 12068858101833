import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private router: Router) { }

  //verifica si hay usuario logueado al cambiar de ruta
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      if (localStorage.getItem('currentUser')) {
          // Si hay un usuario logueado retorna true
          return true;
      }
      //this.router.navigate(['/login']);
      return false;
  }


  

}