<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body" *ngIf="formData">
                <h4 class="card-title">Formulario de Usuario</h4>
                <form [formGroup]="formData" class="form-material m-t-40">

                    <div class="row">
                        <div class="col-lg-3">

                        </div>
                        <div class="col-lg-6">
                            <div class="row el-element-overlay" style="margin-bottom: 0px !important;">
                                <div class="col-md-3">
                                </div>
                                <div class="col-md-6">
                                    <div class="card" style="margin-bottom: 0px !important;">
                                        <div class="el-card-item" style="margin-bottom: 0px !important;">
                                            <div class="el-card-avatar el-overlay-1"
                                                style="margin-bottom: 0px !important;">
                                                <img *ngIf="!file"
                                                    src="https://res.cloudinary.com/dksstmpfu/image/upload/v1610211147/test/categoria/sshhivfnkjoqjo13cenf.jpg"
                                                    alt="area" />
                                                <img *ngIf="file" src="{{strImage}}" alt="area" />
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                </div>
                            </div>
                            <div class="form-group text-center">
                                <label>Foto de perfil (opcional)</label>
                                <br>
                                <div class="fileupload btn btn-danger btn-rounded waves-effect waves-light"><span><i
                                            class="fa fa-camera"></i> Seleccionar Imagen</span>
                                    <input type="file" accept="image/*" (change)="seleccionImage($event)"
                                        class="upload">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">

                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-6">
                                <label>Nombres</label>
                                <input type="text" formControlName="nombre" class="form-control form-control-line" > 
                                <div *ngIf="f.nombre.invalid && (f.nombre.dirty || f.nombre.touched)">
                                    <div *ngIf="f.nombre.errors.required" style="color:red">Dato requerido</div>
                                </div>
                            </div>
                            <div class="col-6">
                                <label>Apellidos</label>
                                <input type="text" formControlName="apellido" class="form-control form-control-line" > 
                                <div *ngIf="f.apellido.invalid && (f.apellido.dirty || f.apellido.touched)">
                                    <div *ngIf="f.apellido.errors.required" style="color:red">Dato requerido</div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-6">
                                <label>Fecha de Nacimiento</label>
                                <input type="date" formControlName="fecha_nacimiento" class="form-control form-control-line" > 
                                <div *ngIf="f.fecha_nacimiento.invalid && (f.fecha_nacimiento.dirty || f.fecha_nacimiento.touched)">
                                    <div *ngIf="f.fecha_nacimiento.errors.required" style="color:red">Dato requerido</div>
                                </div>
                            </div>
                            <div class="col-6">
                                <label>DPI</label>
                                <input type="number"
                                maxlength="13"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                formControlName="dpi" class="form-control form-control-line" > 
                                <div *ngIf="f.dpi.invalid && (f.dpi.dirty || f.dpi.touched)">
                                    <div *ngIf="f.dpi.errors.required" style="color:red">Dato requerido</div>
                                </div>
                            </div>
                        </div>    
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-6">
                                <label>Email</label>
                                <input type="email" formControlName="email" class="form-control form-control-line" > 
                                <div *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)">
                                    <div *ngIf="f.email.errors.required" style="color:red">Dato requerido</div>
                                </div>
                            </div>
                            <div class="col-6">
                                <label>Contraseña</label>
                                <input type="password" formControlName="pass" class="form-control form-control-line" > 
                                <div *ngIf="f.pass.invalid && (f.pass.dirty || f.pass.touched)">
                                    <div *ngIf="f.pass.errors.required" style="color:red">Dato requerido</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-6">
                                <label>Teléfono de celular</label>
                                <input type="number"
                                maxlength="8"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                formControlName="cel" class="form-control form-control-line" > 
                                <div *ngIf="f.cel.invalid && (f.cel.dirty || f.cel.touched)">
                                    <div *ngIf="f.cel.errors.required" style="color:red">Dato requerido</div>
                                </div>
                            </div>
                            <div class="col-6">
                                <label>Teléfono de casa</label>
                                <input type="number"
                                maxlength="8"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                formControlName="tel" class="form-control form-control-line" > 
                                <div *ngIf="f.tel.invalid && (f.tel.dirty || f.tel.touched)">
                                    <div *ngIf="f.tel.errors.required" style="color:red">Dato requerido</div>
                                </div>
                            </div>
                        </div>   
                    </div>
                   
                    <div *ngIf="!ver_admins" class="form-group">
                        <label>Tipo de Usuario</label>
                        <select formControlName="usuarios_tipos_id" class="form-control">
                            <option value="1">Ventas</option>
                            <option value="2">Admin</option>
                            <option value="3">Cliente</option>
                            <option value="4">Seguridad</option>
                        </select>
                    </div>
                    <div *ngIf="SelecCondo" class="form-group">
                        <label>Condominio</label>
                        <select class="form-control" formControlName="condominio_id">
                            <option *ngFor="let item of condominio_list" value="{{item.id}}">{{ item.nombre }} - {{ item.direccion}}</option>
                        </select>
                    </div>
                    <div class="form-group text-center">
                        <div class="btn btn-group">
                            <button (click)="goToPage()" class="btn btn-secondary waves-effect waves-light"
                                type="button"><span class="btn-label"><i class="ti-close"></i></span>Cancelar</button>
                                <button [disabled]="!file" (click)="uploadImage()"
                                class="btn btn-danger waves-effect waves-light" type="button"><span
                                    class="btn-label"><i class="ti-save-alt"></i></span>Guardar Foto</button>
                            <div *ngIf="SelecCondo">
                                <button  (click)="UsuariosGrado2()"
                                class="btn btn-primary waves-effect waves-light" [disabled]="formData.invalid || subiendo_imagen" type="button"><span
                                    class="btn-label"><i class="ti-check"></i></span>Guardar Usuario</button>
                            </div>
                            <div *ngIf="!SelecCondo">
                                <button  (click)="guardar()"
                                class="btn btn-primary waves-effect waves-light" [disabled]="formData.invalid || subiendo_imagen" type="button"><span
                                    class="btn-label"><i class="ti-check"></i></span>Generar Usuario</button>
                            </div>
                                
                        </div>
                    </div>
                 </form>
            </div>
        </div>
    </div>
</div>