
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
//import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ADMIN_ROUTES } from './admin.routes';
import { HomeComponent } from './home/home.component';
import { AdminCondominiosComponent } from './admin-condominios/admin-condominios.component';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormularioCondominiosComponent } from './admin-condominios/formulario-condominios/formulario-condominios.component';
import { AdminUsuariosComponent } from './admin-usuarios/admin-usuarios.component';
import { FormularioUsuarioComponent } from './admin-usuarios/formulario-usuario/formulario-usuario.component';
import { AdminCasasComponent } from './admin-casas/admin-casas.component';
import { AdminAreaComunComponent } from './admin-area-comun/admin-area-comun.component';
import { FormularioAreaComponent } from './admin-area-comun/formulario-area/formulario-area.component';
import { AdminReservasComponent } from './admin-reservas/admin-reservas.component';
import { AdminDirectivasComponent } from './admin-directivas/admin-directivas.component';
import { AdminProyectosComponent } from './admin-proyectos/admin-proyectos.component';
import { FormularioProyectosComponent } from './admin-proyectos/formulario-proyectos/formulario-proyectos.component';
import { AdminNoticiaComponent } from './admin-noticia/admin-noticia.component';
import { FormularioNoticiaComponent } from './admin-noticia/formulario-noticia/formulario-noticia.component';
import { AdminListaUsuariosComponent } from './admin-lista-usuarios/admin-lista-usuarios.component';
import { AdminDirectivaMiembrosComponent } from './admin-directivas/admin-directiva-miembros/admin-directiva-miembros.component';
import { tutorialesComponent } from '../user/user-tutoriales/tutoriales.component';
import { TipsComponent } from '../user/user-tips/tips.component';
import { AdminTipsComponent } from './admin-tips/tips.component';
import { AdmintutorialesComponent } from './admin-tutoriales/tutoriales.component';
import { AdminMembresiasComponent } from './admin-membresias/membresias.component';
import { ObjetosPerdidosComponent } from '../user/user-objetosPerdidos/ObjetosPerdidos.component';
import { AdminObjetosPerdidosComponent } from './admin-ObjetosPerdidos/objetosperdidos.component';
import { PrecioAreaComponent } from './admin-areacomun-precios/acprecio.component';

@NgModule({
    declarations: [
    HomeComponent,
    AdminCondominiosComponent,
    FormularioCondominiosComponent,
    AdminUsuariosComponent,
    FormularioUsuarioComponent,
    AdminCasasComponent,
    AdminAreaComunComponent,
    FormularioAreaComponent,
    AdminReservasComponent,
    AdminDirectivasComponent,
    AdminProyectosComponent,
    FormularioProyectosComponent,
    AdminNoticiaComponent,
    FormularioNoticiaComponent,
    AdminDirectivaMiembrosComponent,
    AdminListaUsuariosComponent,
    AdmintutorialesComponent,
    AdminTipsComponent,
    AdminMembresiasComponent,
    AdminObjetosPerdidosComponent,
    PrecioAreaComponent
  ],
    exports: [
    ],
    imports: [
      FormsModule, 
      ReactiveFormsModule,
      CommonModule,
      RouterModule,
      ADMIN_ROUTES,
      
    ], 
    providers: [
      FormBuilder
    ]
  })
  export class AdminModule { }
  