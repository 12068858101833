import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/services/alert.service';
import { DataService } from 'src/app/services/data.service';
import { PetitionService } from 'src/app/services/petition.service';

declare var $: any;
declare var google:any;

@Component({
  selector: 'app-user-condominio',
  templateUrl: './membresias.component.html'
})
export class AdminMembresiasComponent implements OnInit {
    
  public user_logged: any;
  public membresias_data: any;
  public api_error: any;
  public closeResult: string;
  constructor(
    private data_service: DataService,
    private petition_service: PetitionService,
    private alert_service: AlertService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal
    ) {
      this.user_logged = JSON.parse(localStorage.getItem('currentUser'));
      
      this.GetMembresias();
      
   }
   GetMembresias(){
     this.data_service.MembresiasGetAll()
     .subscribe( (res) => {
       console.log(res)
       this.membresias_data = res;
     }, (error) => {
       
       this.api_error = error
       console.log(this.api_error)
     })
   }
   openModal(content1) {
    this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
    private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
    ngOnInit(){
      
    }
}