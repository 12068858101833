import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/services/alert.service';
import { DataService } from 'src/app/services/data.service';
import { PetitionService } from 'src/app/services/petition.service';
import { UploadFileService } from 'src/app/services/upload-file.service';

declare var $: any;
declare var google:any;

@Component({
  selector: 'app-user-condominio',
  templateUrl: './tutoriales.component.html'
})
export class AdmintutorialesComponent implements OnInit {
  public user_logged: any;
  public tutoriales_data: any;
  public api_error: any;
  public closeResult: string;
  Form: FormGroup;
  file: any;
  strImage: any;
  public tutoriales_list: any;
  constructor(
    private data_service: DataService,
    private petition_service: PetitionService,
    private alert_service: AlertService,
    private modalService: NgbModal,
    private upload_service: UploadFileService,
    private fb: FormBuilder) {
      this.user_logged = JSON.parse(localStorage.getItem('currentUser'));
      this.Form = this.fb.group({
        titulo: [ '', Validators.compose([ Validators.required ])  ],
        descripcion: [ '', Validators.compose([ Validators.required ])  ],
        link_video: [ '', Validators.compose([ Validators.required ])  ],
        estado: [1],
        usuario: [this.user_logged.id]
      });
        this.GetTutoriales();
      
   }
   GetTutoriales(){
     this.data_service.TutorialesGetAll()
     .subscribe( (res) => {
       console.log(res)
       this.tutoriales_data = res;
     }, (error) => {
       
       this.api_error = error
       console.log(this.api_error)
     })
   }
   openModal(content1) {
    this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
    private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
  seleccionImage(archivo: any) {

    if (!(archivo.target.files[0])) {
      return;
    }
    this.file = archivo.target.files[0]
    let reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onloadend = () => this.strImage = reader.result;
    this.uploadImage();
  }
  nuevoTutorial(){
    console.log(this.Form.value)
    this.data_service.AddTutoriales(this.Form.value)
    .subscribe( (res) => {
      this.alert_service.show_swall('tutorial generado', 'Ha generado un tutorial con exito', "success")
      console.log(res)
    }, (error) => {
      this.alert_service.show_swall('Error', 'Por favor intentelo más tarde', "error")
      this.api_error = error
      console.log(this.api_error)
    })
  }
  Imagen: any;
  uploadImage() {
    this.upload_service.post(this.file, 'test/categoria')
      .then((res: any) => {
        this.Imagen = res.url;
        this.alert_service.show_swall('Imagen subida', 'La imagen se subió con éxito', "success")
      })
      .catch((resp) => {
        this.alert_service.show_error();
      });
  }
  update_proyecto(estado: number, id_reserva: number){
    this.petition_service.put('condominio_tutoriales', {"estado": estado}, id_reserva).subscribe(
      (data: any) => {
        this.alert_service.show_swall('Tutorial actualizada','Se ha cambiado el estado del tutorial','success');
        this.GetTutoriales();
      },
      (err: any) => {
        this.alert_service.show_error();  
      }
    )
  }
  get_proyectos() {
    this.tutoriales_list = [];

    this.petition_service.get_all('condominio_tutoriales').subscribe(
      (proyectos: any[]) => {
        
        this.petition_service.get_all('condominio').subscribe(
          (condominios: any[]) => {


            this.petition_service.get_all('usuarios').subscribe(
              (users: any[]) =>{


                let cont = 0
                
                
                for (let i = 0; i < proyectos.length; i++) {
                  cont = i + 1
                  const pro = proyectos[i];
                  for (let j = 0; j < condominios.length; j++) {
                    const cond = condominios[j];
                    
                    for (let k = 0; k < users.length; k++) {
                      const usr = users[k];
                      
                      if (pro.condominio_id == cond.id && pro.usuario_id == usr.id) {
                        
                        let data = {
                          id: pro.id,
                          titulo: pro.titulo,
                          descripcion: pro.descripcion,
                          img: pro.img,
                          usuario_id: pro.usuario_id,
                          condominio_id: pro.condominio_id,
                          estado: pro.estado,
                          usuario_nombre: usr.nombre + ' ' + usr.apellido,
                          condominio: cond.nombre 
                        }
                        this.tutoriales_list.push(data);

                        break;
                      }
                    }
                  }
                }
              },
              (err) =>{
                this.alert_service.show_no_info();
              })
          }, 
          (err) => {
            this.alert_service.show_no_info();
          })

        setTimeout(() => {
          $('#tabla-tutoriales').DataTable();
        }, 3000);       
      }, 
      (err) => {
        this.alert_service.show_no_info();
      })
  }
    ngOnInit(){

    }
}