<div>
    <div class="row">
        <button (click)="open1(content1)">Create</button>
    </div>
    <div class="row">
        <hr>
    </div>
    <div class="row">
        <!-- Column -->
        <div class="col-lg-4 col-xlg-3 col-md-5" *ngFor="let c of condominios_data">
            <div class="card" >
                <div class="card-body text-center" >
                    <div class="m-t-30"> <img src="{{c.imagen}}" class="img-circle" width="150" height="150">
                        <h4 class="card-title m-t-10">{{ c.nombre }}</h4>
                    </div>
                </div>
                <div>
                    <hr> </div>
                <div class="card-body"> 
                    <small class="text-muted">direccion  </small>
                    <h5>{{ c.direccion }}</h5> 
                    <small class="text-muted p-t-30 db">descripcion</small>
                    <h5> {{ c.descripcion }}</h5> 
                </div>
            </div>
        </div>
</div>

<ng-template #content1 let-modal>
    <div class="modal-header">
        Crear condominio
    </div>
    <div class="modal-body">
        <form [formGroup]="Form">
            <div class="form-group">
                <label>Nombre</label>
                <div class="input-group">
                    <input formControlName="Nombre" type="text" class="form-control">
                </div>
                <label>Descripcion</label>
                <div class="input-group">
                    <input formControlName="Descripcion" type="text" class="form-control">
                </div>
                <label>Dirección</label>
                <div class="input-group">
                    <input formControlName="Dirección" type="text" class="form-control">
                </div>
                <label>Cantidad viviendas</label>
                <div class="input-group">
                    <input formControlName="Cantidad_viviendas" type="number" class="form-control">
                </div>
                <label>Membresia</label>
                <select class="custom-select" id="inputGroupSelect01" (change)="SaveMembresiaID($event.target.value)">
                    <option selected>Choose...</option>
                    <option *ngFor="let Pr of membresias_data" [value]="Pr.id">{{Pr.titulo}}</option>
                </select>
                <div class="col-md-12">
                    <h4>Seleccione imagen</h4>
                    <input formControlName="img" type="file" (change)="seleccionImage($event.target.files[0])" class="form-control" placeholder="Ingrese el enlace" aria-label="Password" aria-describedby="basic-addon1" value="prueba">
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <div class="row">
            <button>Guardar condominio</button>
        </div>
    </div>
</ng-template>