<div class="fix-header card-no-border fix-sidebar">
    
    <div id="main-wrapper">

        <br>
        <div class="page-wrapper2">
            <!-- ============================================================== -->
            <!-- Container fluid  -->
            <!-- ============================================================== -->
            
            <div class="container-fluid">
                <div>
                    <button style="margin-top: 0; margin-left: 90%; margin-right: 0;" (click)="reportarEmergencia()">Emergencia</button>
                </div>
                <div class="container">
                    <router-outlet></router-outlet>
                </div>
                
            </div>
           
            <!-- ============================================================== -->
            <!-- footer -->
            <!-- ============================================================== -->
            <footer class="footer"> © 2020 FoxyLabs</footer>
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->
    </div>

</div>

