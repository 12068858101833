import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './shared/login/login.component';
import { SigninComponent } from './shared/signin/signin.component';
import { NopagefoundComponent } from './shared/nopagefound/nopagefound.component';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AuthGuardService } from './services/auth.guard.service';
import { AuthService } from './services/auth.service';
import { UploadFileService } from './services/upload-file.service';
import { AlertService } from './services/alert.service';
import { PageComponent } from './page/page.component';
import { PageModule } from './page/page.module';
import { PetitionService } from './services/petition.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SigninVisitaComponent } from './shared/signin-visita/signin.component';
import { SideBarComponent } from './shared/side-bar/side-bar.component';
import { NavBarComponent } from './shared/nav-bar/nav-bar.component';
import { AdminComponent } from './page/admin/admin.component';
import { UserComponent } from './page/user/user.component';
import { VentasComponent } from './page/ventas/ventas.component';
import { SeguridadComponent } from './page/seguridad/seguridad.component';
import { NoPreloading, RouterModule } from '@angular/router';
import { routes } from './app-routing.module';
import { NgFallimgModule } from 'ng-fallimg';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SigninComponent,
    SigninVisitaComponent,
    NopagefoundComponent,
    PageComponent,
    SideBarComponent,
    NavBarComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule, 
    ReactiveFormsModule,
    PageModule,
    NgbModule,
    RouterModule.forRoot(routes, {preloadingStrategy: NoPreloading, useHash: true }),
    NgMultiSelectDropDownModule.forRoot(),
    NgFallimgModule.forRoot({
      default: '../../assets/images/gallery/guest.png',
      })
  ],
  providers: [
    AuthService,
    AuthGuardService,
    UploadFileService,
    PetitionService,
    AlertService,
    FormBuilder],
  bootstrap: [AppComponent]
})
export class AppModule { }
