import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SEGURIDAD_ROUTES } from './seguridad.routes';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SeguridadHomeComponent } from './home/home.component';
import { SeguridadEmergenciasComponent } from './seguridad-emergencias/emergencias.component';
import { SeguridadVisitasComponent } from './seguridad-visitas/visitas.component';


@NgModule({
    declarations: [
      SeguridadHomeComponent,
      SeguridadEmergenciasComponent,
      SeguridadVisitasComponent
  ],
    exports: [
    ],
    imports: [
      FormsModule, 
      ReactiveFormsModule,
      CommonModule,
      RouterModule,
      NgbModule,
	    FormsModule,
	    ReactiveFormsModule,
	    CommonModule,
      SEGURIDAD_ROUTES,
      
    ], 
    providers: [
      FormBuilder,
      DatePipe
    ]
  })
  export class SeguridadModule { }