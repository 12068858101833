import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html'
})
export class NavBarComponent implements OnInit {
  public user_logged: any;
  public userID: any;   
  constructor(private router: Router) { 
    this.user_logged = JSON.parse(localStorage.getItem('currentUser'))
    this.userID = this.user_logged.usuarios_tipos_id;
  }
  logout(){
    console.log("si funciona logout")
    sessionStorage.clear();
    localStorage.removeItem('admin');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('id');
    localStorage.removeItem('mivivienda');
    localStorage.removeItem('micondo');

  }
  ngOnInit(): void {
  }

}
