import { RouterModule, Routes } from "@angular/router";
import { UserAreaComunComponent } from "./user-areacomun/areacomun.component";
import { UserAreaComunDetallesComponent } from "./user-areacomun/detalles-areacomun/detalles.component";
import { CondominiosComponent } from "./user-condominios/condominios.component";
import { UserEmergenciasComponent } from "./user-emergencias/emergencias.component";
import { UserHomeComponent } from "./user-home/user-home.component";
import { MembresiasComponent } from "./user-membresias/membresias.component";
import { UserNoticiaComponent } from "./user-noticia/user-noticia.component";
import { ObjetosPerdidosComponent } from "./user-objetosPerdidos/ObjetosPerdidos.component";
import { PerfilComponent } from "./user-perfil/perfil.component";
import { UserProyectosComponent } from "./user-proyectos/proyectos.component";
import { TipsComponent } from "./user-tips/tips.component";
import { tutorialesComponent } from "./user-tutoriales/tutoriales.component";
import { UserVisitasComponent } from "./user-visitas/visitas.component";



const pagesRoutes: Routes = [

    { path: 'home', component: UserHomeComponent },
    { path: 'noticias', component: UserNoticiaComponent },
    { path: 'condominios', component: CondominiosComponent },
    { path: 'membresias', component: MembresiasComponent },
    { path: 'mi-perfil', component: PerfilComponent },
    { path: 'tips', component: TipsComponent },
    { path: 'tutoriales', component: tutorialesComponent },
    { path: 'areacomun', component: UserAreaComunComponent },
    { path: 'areacomun-detalles', component: UserAreaComunDetallesComponent},
    { path: 'objetosperdidos', component: ObjetosPerdidosComponent},
    { path: 'proyectos', component: UserProyectosComponent },
    { path: 'emergencia', component: UserEmergenciasComponent },
    { path: 'visitas', component: UserVisitasComponent },
    { path: '', redirectTo: 'login', pathMatch: 'full' }


];


export const USER_ROUTES = RouterModule.forChild(pagesRoutes);
