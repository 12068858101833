import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { DataService } from 'src/app/services/data.service';
import { PetitionService } from 'src/app/services/petition.service';

declare var $: any;
declare var google:any;

@Component({
  selector: 'app-user-condominio',
  templateUrl: './tips.component.html'
})
export class TipsComponent implements OnInit {
  public user_logged: any;
  public tips_data: any;
  public api_error: any;
  constructor(
    private data_service: DataService,
    private petition_service: PetitionService,
    private alert_service: AlertService,
    private formBuilder: FormBuilder) {
      this.user_logged = JSON.parse(localStorage.getItem('currentUser'));
      this.GetTips();
      
      
   }
   GetTips(){
     this.data_service.TipsGetAll()
     .subscribe((res) => {
       console.log(res)
      this.tips_data = res;
     }, (error) => {
       this.api_error = error;
       console.log(this.api_error)
     })
   }
    ngOnInit(){

    }
}