import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { PetitionService } from 'src/app/services/petition.service';

declare var $: any;
@Component({
  selector: 'app-admin-condominios',
  templateUrl: './admin-condominios.component.html'
})
export class AdminCondominiosComponent implements OnInit {
  //VARIABLES
  condominio_list: any[] = [];
  /**
   * VARIABLE PARA ELIMINAR
   */
  formDataDelete: any;
  condominio_id: number = 0;
  condominio_name: string = '';

  constructor(
    private petition_service: PetitionService, 
    private formBuilder: FormBuilder,
    private alert_service: AlertService) {

    
   }

  ngOnInit(): void {
    this.get_condominios();  
    this.formDataDelete = this.formBuilder.group({
      nombreConfirm: ['', Validators.required]
    });
  }

  get_condominios(){

    
    this.petition_service.get_all('condominio').subscribe(
      (data: any[]) => {
        this.condominio_list = data
        setTimeout(() => {
          $('#tabla-condominios').DataTable();
        }, 500);
      },
      (err) =>{
        this.alert_service.show_no_info();
      }
    )
  }

  data_to_delete(id: number, titulo: string) {
    this.condominio_id = id;
    this.condominio_name = titulo;
    this.formDataDelete.reset();
  }
  delete(){
    this.petition_service.delete('condominio', this.condominio_id).subscribe(
      (data: any) => {
        this.get_condominios();
        this.alert_service.show_swall("Condominio Eliminado", "Se ha eliminado el condominio correctamente", "success")
        $('#modal-eliminar').modal('hide');
      }, (err) => {
        this.alert_service.show_error();
      }
    ) 
  }
}
