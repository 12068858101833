import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PetitionService {

  private URI: string = 'https://alquimistapp-condominios.herokuapp.com/api'

  constructor(private http: HttpClient) {

  }
  //HTTP OPTIONS
  private httpOptions = {

    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  //HANDLE ERROR
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Un error ha ocurrido:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: `, error.error);
    }
    return throwError(
      error);
  };

  get_all(table: string) {
    return this.http.get<any>(`${this.URI}/${table}/get_all`).pipe(
      map(data => {
        return data;
      }),
      catchError((err) => {
        //Handle the error here
        return throwError(err)    //Rethrow it back to component
      }
    ));
  }

  get_by_id(table: string, id: number) {
    
    return this.http.get(`${this.URI}/${table}/get_${id}`).pipe(
      map(data => {
        return data;
      }),
      catchError((err) => {

        return throwError(err)    //Rethrow it back to component
      }
    ));
  }

  post(table: string, data: any) {
    return this.http.post(`${this.URI}/${table}/add`, data, this.httpOptions)
      .pipe(map(
        (data) => {
          return data;
        }, (err: any) => {
          catchError(this.handleError)
        }));
  }

  delete(table: string, delete_id: number) {
    //DELETE
    return this.http.delete(`${this.URI}/${table}/delete_${delete_id}`, this.httpOptions).pipe(map(data => {
      return data;
    }));
  }
  put(table: string, data: any, id: number) {
    return this.http.put(`${this.URI}/${table}/update_${id}`, data, this.httpOptions)
      .pipe(map(
        (data) => {
          return data;
        },
        (err: any) => {
          catchError(this.handleError)
        }));
  }
}
