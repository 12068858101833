
    <div class="text-center">
        <h2>Nueva visita</h2>
        <form [formGroup]="Form">
            <div class="form-group">
                <label>Usuario Visitante</label>
                <div>
                    <select class="custom-select" id="inputGroupSelect01" (change)="saveVisitantesID($event.target.value)">
                        <option selected>Choose...</option>
                        <option *ngFor="let Pr of visitantes" [value]="Pr.id">{{Pr.nombre}} {{Pr.apellido}}</option>
                    </select>
                </div>
                <label>Fecha visita</label>
                <div class="input-group">
                    <input formControlName="FechaV" type="date" class="form-control">
                </div>
                <label>Hora visita</label>
                <div class="input-group">
                    <input formControlName="HoraV" type="time" class="form-control">
                </div>
                <label>No placa</label>
                <div class="input-group">
                    <input formControlName="NoPlaca" type="text" class="form-control">
                </div>
                </div>
        </form>
        <button (click)="AgregarVisita()">Generar visita</button>
    </div>
    