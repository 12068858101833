import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { PetitionService } from 'src/app/services/petition.service';

declare var $: any;


@Component({
  selector: 'app-admin-proyectos',
  templateUrl: './admin-proyectos.component.html'
})
export class AdminProyectosComponent implements OnInit {

  //VARIABLES
  public proyectos_list: any[] = [];
  /**
   * ELIMINAR PROYECTO
   */
  proyecto_id: number = 0;
  proyecto_name: string = '';
  formDataDelete: any;

  
  constructor(
    private formBuilder: FormBuilder,
    private petition_service: PetitionService,
    private alert_service: AlertService) {
   }

  ngOnInit(): void {
    this.get_proyectos();
    this.formDataDelete = this.formBuilder.group({
      nombreConfirm: ['', Validators.required]
    });
  }

  get_proyectos() {
    this.proyectos_list = [];

    this.petition_service.get_all('condominio_proyecto').subscribe(
      (proyectos: any[]) => {
        
        this.petition_service.get_all('condominio').subscribe(
          (condominios: any[]) => {


            this.petition_service.get_all('usuarios').subscribe(
              (users: any[]) =>{


                let cont = 0
                
                
                for (let i = 0; i < proyectos.length; i++) {
                  cont = i + 1
                  const pro = proyectos[i];
                  for (let j = 0; j < condominios.length; j++) {
                    const cond = condominios[j];
                    
                    for (let k = 0; k < users.length; k++) {
                      const usr = users[k];
                      
                      if (pro.condominio_id == cond.id && pro.usuario_id == usr.id) {
                        
                        let data = {
                          id: pro.id,
                          titulo: pro.titulo,
                          descripcion: pro.descripcion,
                          img: pro.img,
                          usuario_id: pro.usuario_id,
                          condominio_id: pro.condominio_id,
                          estado: pro.estado,
                          usuario_nombre: usr.nombre + ' ' + usr.apellido,
                          condominio: cond.nombre 
                        }
                        this.proyectos_list.push(data);

                        break;
                      }
                    }
                  }
                }
              },
              (err) =>{
                this.alert_service.show_no_info();
              })
          }, 
          (err) => {
            this.alert_service.show_no_info();
          })

        setTimeout(() => {
          $('#tabla-proyecto').DataTable();
        }, 3000);       
      }, 
      (err) => {
        this.alert_service.show_no_info();
      })
  }
  update_proyecto(estado: number, id_reserva: number){
    this.petition_service.put('condominio_proyecto', {"estado": estado}, id_reserva).subscribe(
      (data: any) => {
        this.alert_service.show_swall('Proyecto actualizado','Se ha cambiado el estado del proyecto','success');
        this.get_proyectos();
      },
      (err: any) => {
        this.alert_service.show_error();  
      }
    )
  }
  data_to_delete(id: number, titulo: string) {
    this.proyecto_id = id;
    this.proyecto_name = titulo;
    this.formDataDelete.reset();
  }


  delete(){
    this.petition_service.delete('condominio_proyecto', this.proyecto_id).subscribe(
      (data: any) => {
        this.get_proyectos();
        this.alert_service.show_swall("Proyecto Eliminado", "Se ha eliminado el proyecto correctamente", "success")
        $('#modal-eliminar').modal('hide');
      }, (err) => {
        this.alert_service.show_error();
      }
    ) 
  }
}


