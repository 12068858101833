import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { PetitionService } from 'src/app/services/petition.service';

@Component({
  selector: 'app-admin-lista-usuarios',
  templateUrl: './admin-lista-usuarios.component.html'
})
export class AdminListaUsuariosComponent implements OnInit {
  //VARIABLES DE USUARIO
  public users_list: any[] = [];
  //VARIABLE DE CONDOMINIO
  public condominio_id: number = 0;
  //VARIABLES DE PUESTO
  public puestos_list: any[] = [];

  constructor(private petition_service: PetitionService,
    private alert_service: AlertService,
    private activRoute: ActivatedRoute) {
    this.get_data()
  }

  ngOnInit(): void {
    //SE GUARDA EL ID DEL CONDOMINIO PARA ASIGNAR USUARIOS 
    this.activRoute.params.subscribe(params => {
      this.condominio_id = params['id'];
    })
  }
  get_data() {
    //SE TRAEN LOS PUESTOS DE LA DIRECTIVA
    this.petition_service.get_all('condominio_puesto').subscribe(
      (puestos: any[]) => {
        if (puestos.length != 0) {
          //SE TRAE A LOS USUARIOS
          this.puestos_list = puestos;
        } else {
          this.alert_service.show_swall('No hay puestos disponibles', 'No se cuentan con puestos disponibles para asignar usuarios a la directiva', 'error');
        }
      },
      (err) => {
        this.alert_service.show_swall('No hay puestos disponibles', 'No se cuentan con puestos disponibles para asignar usuarios a la directiva', 'error');
      }
    )
    this.users_list = [];
    this.petition_service.get_all('usuarios').subscribe(
      (users: any[]) => {
        if (users.length != 0) {
          users.some(usr => {
            if (usr.usuarios_tipos_id != 1) {
              this.users_list.push(usr)
            }
          })
        } else {
          this.alert_service.show_no_info();
        }
      },
      (err: any) => {
        this.alert_service.show_no_info();
      }
    )
  }

  agregar(user_id: number, puesto_id: number, nombre: string){
    let encontrado = false;
    this.petition_service.get_all('condominio_directiva').subscribe(
      (directivas: any[]) => {
        directivas.some(dir => {
          if (dir.id_usuario == user_id && dir.id_condominio == this.condominio_id) {
            encontrado = true;
          }
        });

        if (!encontrado) {
          let data = {
            "usuario_id" : user_id,
            "puesto" : puesto_id,
            "condominio_id": this.condominio_id,
            "estado": 1,
            "usuario_id_ingreso": +localStorage.getItem('id')
          }
          this.petition_service.post('condominio_directiva', data).subscribe(
            (data: any) => {
              this.alert_service.show_swall('Usuario agregado', "Ahora el usuario '" + nombre +"' forma parte de la directiva del condominio", 'success')
            }
          )
        } else {
          this.alert_service.show_swall('Usuario existente', "El usuario '" + nombre +"' ya forma parte de la directiva del condominio", 'warning')
          
        }
        
      }
    )
  }

}
