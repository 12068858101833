import { Injectable, ɵConsole } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { BehaviorSubject } from "rxjs";
import { catchError } from 'rxjs/operators';
import { FormBuilder } from '@angular/forms';

Request
@Injectable({
  providedIn: 'root'
})

export class DataService {
  constructor(private _http: HttpClient) { }
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };
//----------------------------------------------------------------------
GETCondominio = "https://alquimistapp-condominios.herokuapp.com/api/condominio/get_"
GETALLCondominios = "https://alquimistapp-condominios.herokuapp.com/api/condominio/get_all"
CREATECondominios = "https://alquimistapp-condominios.herokuapp.com/api/condominio/add"
UPDATECondominios = "https://alquimistapp-condominios.herokuapp.com/api/condominio/update_"
public CondominiosGetAll(): Observable<any> {
    return this._http.get(this.GETALLCondominios, this.httpOptions)
}
public CondominiosGet(id): Observable<any> {
  return this._http.get(this.GETCondominio+id, this.httpOptions)
}
public CondominioCreate(): Observable<any>{
  let formulario = {

  }
  return this._http.post(this.CREATECondominios, formulario, this.httpOptions)
}
public CondominioUpdate(id): Observable<any>{
  let formulario = {

  }
  return this._http.put(this.UPDATECondominios+id, formulario, this.httpOptions)
}
//----------------------------------------------------------------------
GETMembresias = "https://alquimistapp-condominios.herokuapp.com/api/condominio_membresia/get_"
GETALLMembresias = "https://alquimistapp-condominios.herokuapp.com/api/condominio_membresia/get_all"
CREATEMembresias = "https://alquimistapp-condominios.herokuapp.com/api/condominio_membresia/add"
UPDATEMembresias = "https://alquimistapp-condominios.herokuapp.com/api/condominio_membresia/update_"
public MembresiasGetAll(): Observable<any> {
    return this._http.get(this.GETALLMembresias, this.httpOptions)
}
public MembresiasGet(id):Observable<any> {
  return this._http.get(this.GETMembresias+id, this.httpOptions)
}
public MembresiasCreate(): Observable<any>{
  let formulario = {

  }
  return this._http.post(this.CREATEMembresias, formulario, this.httpOptions)
}
public MembresiasUpdate(id): Observable<any>{
  let formulario = {

  }
  return this._http.put(this.UPDATEMembresias+id, formulario, this.httpOptions)
}
//----------------------------------------------------------------------
GETTips = "https://alquimistapp-condominios.herokuapp.com/api/condominio_tips/get_"
GETALLTips = "https://alquimistapp-condominios.herokuapp.com/api/condominio_tips/get_all"
CREATETips = "https://alquimistapp-condominios.herokuapp.com/api/condominio_tips/add"
UPDATETips = "https://alquimistapp-condominios.herokuapp.com/api/condominio_tips/update_"
public TipsGetAll(): Observable<any> {
    return this._http.get(this.GETALLTips, this.httpOptions)
}
public TipCreate(): Observable<any>{
  let formulario = {

  }
  return this._http.post(this.CREATETips, formulario, this.httpOptions)
}
public TipUpdate(id): Observable<any>{
  let formulario = {

  }
  return this._http.put(this.UPDATETips+id, formulario, this.httpOptions)
}
//----------------------------------------------------------------------
GETTutoriales = "https://alquimistapp-condominios.herokuapp.com/api/condominio_tutoriales/get_"
GETALLTutoriales = "https://alquimistapp-condominios.herokuapp.com/api/condominio_tutoriales/get_all"
CREATETutoriales = "https://alquimistapp-condominios.herokuapp.com/api/condominio_tutoriales/add"
UPDATETutoriales = "https://alquimistapp-condominios.herokuapp.com/api/condominio_tutoriales/update_"
public TutorialesGetAll(): Observable<any> {
    return this._http.get(this.GETALLTutoriales, this.httpOptions)
}
public TutorialesCreate(): Observable<any>{
  let formulario = {

  }
  return this._http.post(this.CREATETutoriales, formulario, this.httpOptions)
}
public TutorialesUpdate(id): Observable<any>{
  let formulario = {

  }
  return this._http.put(this.UPDATETutoriales+id, formulario, this.httpOptions)
}
//----------------------------------------------------------------------
UpdatePerfil = "https://alquimistappcondominios.herokuapp.com/api/usuarios/update_"

public UpdatePerfilInfo(id): Observable<any>{
  let formulario = {

  }
  return this._http.put(this.UpdatePerfil+id, formulario, this.httpOptions)
}

//----------------------------------------------------------------------
addNoticias = "https://alquimistapp-condominios.herokuapp.com/api/condominio_noticias/add"
getAllNoticias = "https://alquimistapp-condominios.herokuapp.com/api/condominio_noticias/get_all"
updateNoticias = "https://alquimistapp-condominios.herokuapp.com/api/condominio_noticias/update_"
public NoticiasGetAll(): Observable<any>{
  return this._http.get(this.getAllNoticias, this.httpOptions)
}
public CreateNoticias(data): Observable<any>{
  return this._http.post(this.addNoticias, data, this.httpOptions)
}
public UpdateNoticias(data, id): Observable<any>{
  return this._http.put(this.updateNoticias + id, data, this.httpOptions)
}



GetAreaComunByCon = "https://alquimistapp-condominios.herokuapp.com/api/condominio_area_comun/condominio_"
public AreaComunByCondominio(id): Observable<any>{
  return this._http.get(this.GetAreaComunByCon + id, this.httpOptions)
}


GetAllObjetosPerdidos = "https://alquimistapp-condominios.herokuapp.com/api/condominio_objetos_perdidos/condominio_"
public ObjetosPerdidosGetAll(id): Observable<any>{
  console.log(this.GetAllObjetosPerdidos+id)
  return this._http.get(this.GetAllObjetosPerdidos+id, this.httpOptions)
}

AddObjetosPerdidos = "https://alquimistapp-condominios.herokuapp.com/api/condominio_objetos_perdidos/add"
public ObjetosPerdidosAdd(data): Observable<any>{
  return this._http.post(this.AddObjetosPerdidos, data, this.httpOptions)
}

UpdateObjetosPerdidos = "https://alquimistapp-condominios.herokuapp.com/api/condominio_objetos_perdidos/update_"
public ObjetosPerdidosUpdate(data, id): Observable<any>{
  return this._http.put(this.UpdateObjetosPerdidos+id, data, this.httpOptions)
}

GetProyectos = "https://alquimistapp-condominios.herokuapp.com/api/condominio_proyecto/condominio_"
public ProyectosCondominiosGet(id): Observable<any>{
  return this._http.get(this.GetProyectos+id, this.httpOptions)
}
AddProyecto = "https://alquimistapp-condominios.herokuapp.com/api/condominio_proyecto_propuestas/add"
AddProyectoPropuesta = ""
public ProyectoAdd(data): Observable<any>{
  return this._http.post(this.AddProyecto, data, this.httpOptions)
}

Emergencia = "https://alquimistapp-condominios.herokuapp.com/api/condominio_emergencias/add"
public NewEmergencia(data): Observable<any>{
  return this._http.post(this.Emergencia, data, this.httpOptions)
}
NuevaVisita = "https://alquimistapp-condominios.herokuapp.com/api/condominio_usuario_visita/add"
public AddVisita(data): Observable<any>{
  return this._http.post(this.NuevaVisita, data, this.httpOptions)
}
nuevamembresia = "https://alquimistapp-condominios.herokuapp.com/api/condominio_membresia/add"
public AddMembresia(data): Observable<any>{
  return this._http.post(this.nuevamembresia, data, this.httpOptions)
}
ObtenerUsuarios = "https://alquimistapp-condominios.herokuapp.com/api/usuarios/tipo_"
public getUsuarios(tipo): Observable<any>{
  return this._http.get(this.ObtenerUsuarios+tipo, this.httpOptions)
}
UsuarioVisitas = "https://alquimistapp-condominios.herokuapp.com/api/condominio_usuario_visita/add"
VisitasControl = "https://alquimistapp-condominios.herokuapp.com/api/condominio_usuario_visita_control/add"
public Visita(data): Observable<any>{
  return this._http.post(this.UsuarioVisitas, data, this.httpOptions)
}
public ControlVisita(data): Observable<any>{
  return this._http.post(this.VisitasControl, data, this.httpOptions)
}
AreasPrecios = "https://alquimistapp-condominios.herokuapp.com/api/condominio_precios/get_all"
public getPreciosAreas(): Observable<any>{
  return this._http.get(this.AreasPrecios, this.httpOptions)
}
NuevoAreaPrecio = "https://alquimistapp-condominios.herokuapp.com/api/condominio_precios/add"
public addPrecioArea(data): Observable<any>{
  return this._http.post(this.NuevoAreaPrecio, data, this.httpOptions)
}
precioArea = "https://alquimistapp-condominios.herokuapp.com/api/condominio_precio_area_comun/add"
public PrecioAreaNuevo(data): Observable<any>{
  return this._http.post(this.precioArea, data, this.httpOptions)
}
AllAreasComunes = "https://alquimistapp-condominios.herokuapp.com/api/condominio_area_comun/get_all"
public getAreasComunes(): Observable<any>{
  return this._http.get(this.AllAreasComunes, this.httpOptions)
}
GetAreaComunPrecio = "https://alquimistapp-condominios.herokuapp.com/api/condominio_precio_area_comun/area_"
public getAreasComunesPrecios(id): Observable<any>{
  return this._http.get(this.GetAreaComunPrecio+id, this.httpOptions)
}
ReservarAreaComun = "https://alquimistapp-condominios.herokuapp.com/api/condominio_area_comun_reserva/add"
public ReservarArea(data): Observable<any>{
  return this._http.post(this.ReservarAreaComun, data, this.httpOptions)
}
VisitasListadoSeguridad = "https://alquimistapp-condominios.herokuapp.com/api/condominio_usuario_visita_control/condominio_vivienda_"
public SeguridadVisitas(id): Observable<any>{
  return this._http.get(this.VisitasListadoSeguridad+id, this.httpOptions)
}
EmergenciasSeguridad = "https://alquimistapp-condominios.herokuapp.com/api/condominio_emergencias/condominio_"
public ListadoEmergencias(id): Observable<any>{
  return this._http.get(this.EmergenciasSeguridad + id, this.httpOptions)
}
AddUsuarioSeguridad = "https://alquimistapp-condominios.herokuapp.com/api/usuarios/seguridad_add"
public AgregarSeguridad(data): Observable<any>{
  return this._http.post(this.AddUsuarioSeguridad, data, this.httpOptions)
}
SeguridadCondo = "https://alquimistapp-condominios.herokuapp.com/api/condominio_seguridad/add"
public AgregarSeguridadCondominio(data): Observable<any>{
  return this._http.post(this.SeguridadCondo, data, this.httpOptions)
}
GetCondominioSeguridad = "https://alquimistapp-condominios.herokuapp.com/api/condominio_seguridad/usuario_"
public CondominioSeguridad(id): Observable<any>{
  return this._http.get(this.GetCondominioSeguridad + id, this.httpOptions)
}
Tutorialesadd = "https://alquimistapp-condominios.herokuapp.com/api/condominio_tutoriales/add"
public AddTutoriales(data): Observable<any>{
  return this._http.post(this.Tutorialesadd, data, this.httpOptions)
}
TipsAdd = "https://alquimistapp-condominios.herokuapp.com/api/condominio_tips/add"
public AddTipss(data): Observable<any>{
  return this._http.post(this.TipsAdd, data, this.httpOptions)
}
AsignaciónMembresia = "https://alquimistapp-condominios.herokuapp.com/api/condominio_membresia_ventas/add"
public AddAsignaciónMembresia(data): Observable<any>{
  return this._http.post(this.AsignaciónMembresia, data, this.httpOptions)
}
GetUsuarios = "https://alquimistapp-condominios.herokuapp.com/api/usuarios/tipo_"
public GetAllusuarios(tipo): Observable<any>{
  return this._http.get(this.GetUsuarios+tipo, this.httpOptions)
}
GetMembresiasCondominio = "https://alquimistapp-condominios.herokuapp.com/api/condominio_membresia/get_all"
public GetAllcondominio_membresia(): Observable<any>{
  return this._http.get(this.GetMembresiasCondominio, this.httpOptions)
}
UpdateAreasEstado = "https://alquimistapp-condominios.herokuapp.com/api/condominio_area_comun/update_"
public AreasEstadoUpdate(data, id): Observable<any>{
  return this._http.put(this.UpdateAreasEstado + id, data, this.httpOptions)
}
UpdatePrecioAreasEstado = "https://alquimistapp-condominios.herokuapp.com/api/condominio_precio_area_comun/update_"
public AreasPrecioEstadoUpdate(data, id): Observable<any>{
  return this._http.put(this.UpdatePrecioAreasEstado + id, data, this.httpOptions)
}
} 